import {atom, atomFamily, DefaultValue, selector} from 'recoil';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';
import {ISelectValue} from '../../FormComponents/Select/SelectComponent';
import {UUID} from '../../../tw/types';
import Client from '../../../tw/client';
import {favoriteDocumentsSelector, filteredDocumentsSelector} from './utils';
import {Strings} from '../../../utils/strings';
import {Lists} from '../../../utils/lists';
import GA from '../../../tw/models/GA';
import {UnitSelector} from '../../../state/product';
import {Objects} from '../../../utils/objects';
import {fi} from '../../../utils/helpers';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import {searchCurrentPage, SortingRule} from './components/Results/utils';
import {refreshItem} from '../../../state/documents';
import {selectedItems} from '../helpers';

// This toggles the filter section. Only for mobile view!
export const toggleFilterAtom = atom<boolean>({
	key: 'toggleFilterAtom',
	default: false,
});

// Keeps state of the Filter Favorites button from resource finder
export const filterFavoritesAtom = atom<boolean>({
	key: 'filterFavoritesAtom',
	default: false,
});

export type SearchFilter = {
	query: string,
	contentTypes: ISelectValue[],
	assessments: string[],
	contentGroupTypes: UUID[],
	years: ISelectValue[],
	series: ISelectValue[],
	includeOnlyFavorites: boolean,
	sort: string,
	order: string
}

export const DefaultFilter: SearchFilter = {
	query: '',
	contentTypes: [],
	assessments: [],
	contentGroupTypes: [],
	years: [],
	series: [],
	sort: 'title',
	order: 'asc',
	includeOnlyFavorites: false,
};

// Filters used to go to search service for results
const filterAtom = atom<SearchFilter>({
	key: 'filterAtom',
	default: DefaultFilter,
});

type SearchResult = {}

type SearchResults = {
	filter: SearchFilter, // snapshot of filters used for search
	hits: SearchResult[], // all search results from the search service
	items: DocumentsWrapper, // search results after filtering by units and other filters
	showNoResults: boolean, // whether to show 'No results' or not
}

// Only exported for testing purposes. Do not use from here. Use searchResultsSelector
export const searchResultsAtom = atom<SearchResults>({
	key: 'searchResultsAtom',
	default: {
		filter: DefaultFilter,
		hits: [],
		items: new DocumentsWrapper(),
		showNoResults: false,
	},
});

// This atom contains the search results and the filters used for the search.
export const searchResultsSelector = selector<SearchResults>({
	key: 'searchResultsSelector',
	get: ({get}) => {
		const showFavoritesOnly = get(filterFavoritesAtom);
		const results = {...get(searchResultsAtom)};
		const refreshed = get(refreshItem);

		if (refreshed && Array.isArray(refreshed) && refreshed.length) {
			const tmp = DocumentsWrapper.from([...results.items]) as any;
			refreshed.forEach((doc) => {
				for (let i = 0; i < tmp.length; i++) {
					if (tmp[i].getId() === doc.getId()) {
						tmp[i] = doc;
						break;
					}
				}
			});
			results.items = tmp;
		}

		if (showFavoritesOnly) {
			results.items = results.items.filter(f => f.isFavorite());
		}

		return results;
	},
});

export const weHaveFiltersAtom = atom<boolean>({
	key: 'weHaveFiltersAtom',
	default: false,
});

export const contentGroupsTagRules = atom<any[]>({
	key: 'contentGroupsTagRules',
	default: []
});

export const resourceFinderFilterSelector = selector<SearchFilter>({
	key: 'resourceFinderFilterSelector',
	get: ({get}) => {
		return get(filterAtom);
	},
	set: ({set, get, reset}, newValue) => {
		let newFilters = DefaultFilter;

		if (newValue instanceof DefaultValue) {
			const assessments = get(filterAtom).assessments;
			const categories = get(filterAtom).contentGroupTypes;
			newFilters = {
				...DefaultFilter,
				assessments: assessments,
				contentGroupTypes: categories,
			};
			set(filterAtom, newFilters);
		} else {
			newFilters = newValue;
			set(filterAtom, newFilters);
		}

		set(selectedItems, []);

		const query = newFilters.query.replace(/[^a-zA-Z0-9 ]/g, '');
		if (newFilters.query && newFilters.sort === '') {
			newFilters = {...newFilters, sort: 'relevance'};
		} else if (newFilters.sort === '') {
			newFilters = {...newFilters, sort: 'title'};
		}

		let filteredDocuments = getRecoil(filteredDocumentsSelector);

		// filter content types, years and series for the selected subject items
		if (filteredDocuments.length > 0) {
			const filteredContentTypes = filteredDocuments.contentTypeIds();
			newFilters = {
				...newFilters,
				contentTypes: newFilters.contentTypes.filter(ct => filteredContentTypes.includes(ct.value)),
				years: newFilters.years.filter(ct => filteredDocuments.examYears().includes(ct.value)),
				series: newFilters.series.filter(ct => filteredDocuments.series().includes(ct.value)),
			};
		}

		let contentTypes: string[] = newFilters.contentTypes.map(c => c.value);
		if (contentTypes.length === 0 && newFilters.contentGroupTypes.length > 0) {
			contentTypes = [...newFilters.contentGroupTypes];
		}
		const years = newFilters.years.map(c => Number(c.value));
		const series: string[] = newFilters.series.map(c => c.value);

		const favorites = getRecoil(favoriteDocumentsSelector);
		const units = getRecoil(UnitSelector);

		// check if favorite filter is true and if so, check if we have any favorites otherwise reset filterFavoritesAtom
		const favoriteVal = get(filterFavoritesAtom);
		if (favoriteVal && !favorites.length) {
			set(filterFavoritesAtom, false);
		}
		Client.search(query, contentTypes, years, series, newFilters.assessments, [], [], newFilters.sort, newFilters.order).then(results => {
			const favoriteIds = favorites.map(f => f.getId());

			let hits = results;
			let filteredHits = [...hits];

			// do we include favorites or not ?
			if (newFilters.includeOnlyFavorites) {
				filteredHits = filteredHits.filter(r => favoriteIds.includes(r._id));
			}

			// if we have units selected in the subject selector, we need to filter the results by units also
			if (units) {
				filteredDocuments = filteredDocuments.byUnitList(units.map(u => u.value));
			}

			const list = new DocumentsWrapper();
			filteredHits.forEach(r => {
				const doc = filteredDocuments.find(d => d.getId() === r._id);
				if (!doc) {
					return;
				}
				let copy = doc;
				const matchContent = Objects.default(r.highlight).file_content;
				if (matchContent) {
					copy = copy.clone({
						...copy,
						matcher: {
							matched: Strings.default(matchContent[0]).replace(/\n/mg, ' ') + '...' +
								fi(matchContent.length > 1,
									`<br /><strong>+${matchContent.length - 1} more</strong> matches in document`,
									'',
								),
							matchesNumber: matchContent.length,
						},
					});
				}
				list.push(copy);
			});

			setRecoil(searchResultsAtom, {
				filter: newFilters,
				hits: hits,
				items: list,
				showNoResults: true,
			});
			setRecoil(searchCurrentPage, 0);

			GA.SearchEvent({
				event_label: Strings.default(query, 'null'),
				content_type: Lists.default<ISelectValue>(newFilters.contentTypes).map(ct => ct.label).join(', '),
				examyear: Lists.default<ISelectValue>(newFilters.years).map(y => y.label).join(', '),
				series: Lists.default<ISelectValue>(newFilters.series).map(s => Strings.capitalize(s.label)).join(', '),
				sortBy: newFilters.sort,
			});
		}).catch((e) => {
			console.error('Error querying search service: ', e);
		});
	},
});

