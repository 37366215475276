import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { WidgetContext } from '../widget';
import Pathway, { PathwayWidget } from './Pathway';

const Wrapper = styled.div`
		display: flex;
		margin-bottom: 0px !important;
    flex-wrap: wrap;
`
const PathwayFooter = styled.div<{ color: string, length: number }>`
		margin-top: 10px;
		margin-bottom: 32px;
		border-radius: 3px;
		width: calc(${({length})=> length} * 204px - 4px);
		height: 40px;
    background: ${({color})=> color};
		color: white;
		text-align: center;
		text-wrap: nowrap;
    z-index:100;
    padding: 8px;
    line-height: 20px;

		h3 {
			font-weight: 600;
		}
`
const pathways = [
	{name: 'Cambridge Early Years', age: '3+', color: '#8128e7', text: 'Pathway 1', page: '65c6e836-2d6a-4289-aa91-dd494ec05179'},
	{name: 'Cambridge Primary', age: '5+',color: '#5465f8', text: 'Pathway 2', page: ''},
	{name: 'Cambridge Lower Secondary', age: '11+',color: '#008a08', text: 'Pathway 3', page: ''},
	{name: 'Cambridge Upper Secondary', age: '14+',color: '#d74120', text: 'Pathway 4', page: ''},
	{name: 'Cambridge Advanced', age: '16+',color: '#7f091d', text: 'Pathway 1', page: ''},
]

const PathwayContainer = () => {
	const widget = useContext(WidgetContext);
	const pathways = widget.widget.steps as PathwayWidget[];
	const { title, color, link } = widget.widget;
	
	if (!widget || !pathways.length) return null;

	return (
		<>
			<Wrapper data-testid='pathway-container'>
				{pathways.map((pathway, index) => (
					<Pathway key={index} {...pathway}/>
				))}
			</Wrapper>
			<PathwayFooter color={color} length={pathways.length} data-testid="pathway-footer"><a href={link} target='_blank'><h3>{title}</h3></a></PathwayFooter>
		</>
	)
}

export default PathwayContainer;