import {CMSObject} from '../../../../tw/models/__CMSObject';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React, {useMemo, useState} from 'react';
import {ReactComponent as PreviewIcon} from '../../../../assets/images/card/actions/view-icon.svg';
import {disableButton} from './utils';
import {fi} from '../../../../utils/helpers';
import {Messages} from '../../../../utils/messages';
import GA from '../../../../tw/models/GA';
import styled from '@emotion/styled';
import {Types} from '../../../../tw/types';

const CustomModalElem = styled(Modal)`
    z-index: 7001;
`
const CustomImagePreview = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
`;

const PreviewButton = ({item, icon = <PreviewIcon/>}: { item: CMSObject, icon?: React.ReactNode }) => {
	const [imageUrl, setImageUrl] = useState('');
	const [open, setOpen] = useState(false);

	const disableAction = useMemo(() => {
		return disableButton(item);
	}, [item]);
	const onClick = () => {
		// GA event
		GA.ItemActionEvent('Preview', item.formGAParams());
		const type = item.getType();
		if (type === Types.DOCUMENT) {
			item.preview().then((url: string) => {
				window.open(url, '_blank');
			});
		}
		if (type === Types.IMAGE) {
			item.preview().then((url: string) => {
				setImageUrl(url);
				setOpen(true);
			}).catch();
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<span className='card-action' title={fi(disableAction, Messages.FeatureNotAvailable, 'Preview')}>
				<Button data-testid='preview-btn' color="primary" variant="text" size="large" onClick={onClick}
						disabled={disableAction}>
					{icon}
				</Button>
			</span>
			<CustomModalElem data-testid='preview-modal'
				open={open}
				onClose={handleClose}
			>
				<CustomImagePreview src={imageUrl} alt={item.displayLabel()}/>
			</CustomModalElem>
		</>
	);
};

export default PreviewButton;