import React, { Suspense, useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import styled from '@emotion/styled'
import GA from '../../../tw/models/GA'
import Client from '../../../tw/client'
import Loader from '../../Loader/Loader'
import Divider from '@mui/material/Divider'
import { useMediaQuery } from '@mui/material'
import { Lists } from '../../../utils/lists'
import { Strings } from '../../../utils/strings'
import { Messages } from '../../../utils/messages'
import TableFooter from './components/TableFooter'
import { Subject } from '../../../tw/models/Subject'
import { productTreeSelector } from '../../../state/product'
import { getRecoil, setRecoil } from '../../../state/recoilNexus'
import { defaultSubjectAtom, sessionAtom } from '../../../state/session'
import { useRecoilState, useRecoilValue } from 'recoil'
import { UserQualification, UserSession } from '../../../tw/models/Session'
import InputField from '../../FormComponents/Input/InputField'
import Tab from './components/Tab'
import BootstrapTooltip from './components/Tooltip'
import {
  AlertSeverity,
  snackbarStateAtom
} from '../../Snackbar/SnackbarComponent'
import { InputAdornment } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import SubjectListModal from './components/SubjectListModal'
import externalLinkIcon from '../../../assets/images/external-link.svg'

import {
  actionModalAtom,
  selectedTabAtom,
  paginationAtom
} from './state'

const Wrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 33px;
  }

  .MuiInputAdornment-root {
    margin-left: 0;
    display: flex;
    width: 48px;
    height: 39px;
    max-height: 39px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--color-grey);

    button {
      width: 100%;
      height: 100%;
    }
  }

  .MuiDivider-root {
    margin-left: 0;
  }

  section {
    border: 1px solid var(--color-grey);
    border-radius: 8px;
    margin-bottom: 25px;
  }

  .subject {
    margin: 18px;
    display: flex;
    align-items: center;
  }

  .details {
    padding-right: 21px;
    width: 75%;
  }

  .heading-wrapper {
    display: flex;
    align-items: center;
  }

  .heading-link {
    color: #233588;
    &:hover {
      color: #0064a8;
      cursor: pointer;
    }
  }

  .external-icon {
    margin-left: 9px;
    color: red;
    width: 18px;
    &:hover {
      color: #0064a8;
      cursor: pointer;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
    width: 25%;

    button {
      color: white;
      border: 1px solid #0076bd;
      border-radius: 4px;
      width: 149px;
      height: 36px;
      font-size: 16px;
    }

    .addSubject {
      background-color: #0076bd;
      cursor: pointer;

      &:hover {
        background-color: #0064a8;
      }
    }

    .removeSubjectEnabled {
      color: #0076bd;
      &:hover {
        cursor: pointer;
        background-color: #e6f3fa;
        color: #0076bd;
      }
    }

    .removeSubjectDisabled {
      color: #0076bd;
    }
  }

  @media (max-width: 600px) {
    .details {
      width: 70%;
    }

    .button {
      width: 30%;
      button {
        font-size: 14px;
      }
    }
  }
`

const SubjectsList = ({ qualification_group_mapping, all_assessments }) => {
  const session = useRecoilValue(sessionAtom)
  const productTree = useRecoilValue(productTreeSelector)
  const [pagination, setPagination] = useRecoilState(paginationAtom)
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabAtom)
  const [actionModal, setActionModal] = useRecoilState(actionModalAtom)
  const [filteredSubjects, setFilteredSubjects] = useState<any[]>([])
  const [paginatedSubjects, setPaginatedSubjects] = useState<any[]>([])
  const [userQualifications, setUserQualifications] = useState<any[]>([])
  const [selectedTabSubjects, setSelectedTabSubjects] = useState<any[]>([])

  const [search, setSearch] = useState<any>({
    query: '',
    ready: false
  })

  const isMobile = useMediaQuery('(max-width:600px)')

  const redirectToLink = (link) => {
    if (link) {
      window.open(link, '_blank')
    }
  }

  const currentMySubjects = JSON.parse(
    JSON.stringify(
      Lists.default<UserQualification>(session?.getQualifications())
    )
  )

  const mySubjects = currentMySubjects.map(
    (qualification) => qualification.subject
  )

  useEffect(() => {
    setSelectedTab('')
  }, [])

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(userQualifications[0]?.getId())
    }
  }, [userQualifications, selectedTab, setSelectedTab])

  useEffect(() => {
    let products: any[] = []
    if (all_assessments) {
      products = productTree || []
    } else {
      products =
        productTree?.filter((product) =>
          qualification_group_mapping.includes(product.getId())
        ) || []
    }

    setUserQualifications(products)

  }, [productTree, qualification_group_mapping])

  useEffect(() => {
    const handleAsyncOperation = async () => {
      const subjectId = actionModal.selectedItem?.getId()
      let qualification: any[] = []

      if (actionModal.selectionConfirmed) {
        if (actionModal.action === 'add') {
          const {
            selectedItem: {
              assessmentIDs: assessment,
              qualificationSizeIDs: qualification_size,
              disabled,
              description,
              title,
              qualificationMappingID: qualification_group_mapping,
              specificationGroup: specification_group
            }
          } = actionModal

          const subject = new Subject({
            id: subjectId,
            assessment,
            qualification_size,
            disabled,
            description,
            title,
            qualification_group_mapping,
            specification_group
          })

          qualification = [
            {
              qualification: qualification_group_mapping,
              subject: subjectId,
              assessments: assessment,
              units: subject.isModular()
                ? subject.getUnits().map((item) => item.getId())
                : []
            }
          ]

          currentMySubjects.forEach((q) => {
            qualification.push({
              qualification: q.qualification,
              subject: q.subject,
              assessments: q.assessments,
              units: q.units
            })
          })
        }

        if (actionModal.action === 'remove') {
          qualification = currentMySubjects.filter(
            (qualification) => qualification.subject !== subjectId
          )
        }

        try {
          const res = await Client.saveUserPreferences({
            job_title: session?.getJobTitle(),
            qualification: qualification,
            registrationinfo: session?.getRegistrationInfo(),
            preferences: {
              ...session?.getPreferences(),
              defaultSubject: Strings.default(getRecoil(defaultSubjectAtom))
            }
          })
          setRecoil(sessionAtom, new UserSession(res))
          setRecoil(snackbarStateAtom, {
            message: `${Messages.SubjectsUpdateSuccess}`,
            severity: AlertSeverity.Success
          })
        } catch (e) {
          setRecoil(snackbarStateAtom, {
            message: `${Messages.SubjectsUpdateError}`,
            severity: AlertSeverity.Error
          })
        } finally {
          GA.UpdatePreferencesEvent()
        }

        setActionModal({
          ...actionModal,
          isActive: false,
          action: null,
          selectedItem: null,
          selectionConfirmed: false
        })
      }
    }

    handleAsyncOperation()
  }, [
    actionModal.selectionConfirmed,
    currentMySubjects,
    actionModal,
    setActionModal,
    session
  ])

  const qualificationIds =
    userQualifications?.map((qualification) => {
      return { id: qualification.getId(), title: qualification.title }
    }) || []

  useEffect(() => {
    let subjects

    if (selectedTab) {
      subjects = userQualifications.find(
        (qualification) => qualification.getId() === selectedTab
      )?.subjects
    }

    if (!selectedTab) {
      subjects = userQualifications[0]?.subjects
    }

    if (subjects) {
      setSelectedTabSubjects(subjects)
    }

  }, [selectedTab, userQualifications])

  useEffect(() => {
    let subjects: any[] = []

    const fuse = new Fuse(selectedTabSubjects, {
      keys: ['title', 'description']
    })

    if (search.query.trim() && search.ready) {
      const approximateMatch = fuse.search(search.query)
      subjects = approximateMatch.map((item) => item.item)
    } else {
      subjects = selectedTabSubjects
    }

    setPagination({ ...pagination, currentPage: 0 })
    setFilteredSubjects(subjects)
  }, [selectedTabSubjects, search])

  useEffect(() => {
    const start = pagination.currentPage * pagination.itemsPerPage
    const end = start + pagination.itemsPerPage
    const subjects = filteredSubjects || []
    setPaginatedSubjects(subjects.slice(start, end))
  }, [filteredSubjects, pagination])

  const handleClick = (item: object, action: string) => {
    setActionModal({
      ...actionModal,
      isActive: true,
      action,
      selectedItem: item
    })
  }

  return (
    <Suspense fallback={<Loader />}>
      <Wrapper>
        <InputField
          placeholder='Search for a syllabus in this qualification...'
          id='search-input'
          value={search.query}
          field={{ name: 'search', label: '' }}
          onChange={(e) => setSearch({ ...search, query: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && search.query.trim()) {
              setSearch({ ...search, ready: true })
            }
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  if (search.query.trim()) {
                    setSearch({ ...search, ready: true })
                  }
                }}
                title={'Type a search term and click to search'}
                style={{ pointerEvents: 'auto' }}
              >
                <SearchIcon fontSize={'medium'} />
              </IconButton>
            </InputAdornment>
          }
        />
        {qualificationIds.length > 1 && <Tab tabs={qualificationIds} />}
        <section>
          {paginatedSubjects.map((item, index) => (
            <div key={index}>
              <div className='subject'>
                <div className='details'>
                  <div className='heading-wrapper'>
                    <BootstrapTooltip title={item.link ? 'Read more' : ''}>
                      <div>
                        <div
                          className={`${item.link ? 'heading-link' : 'heading'}
                          text-16 text-semiBold`}
                          onClick={() => redirectToLink(item.link)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </BootstrapTooltip>
                    {item.link && (
                      <BootstrapTooltip title={item.link ? 'Read more' : ''}>
                        <img
                          className='external-icon'
                          src={externalLinkIcon}
                          alt='external link'
                          onClick={() => redirectToLink(item.link)}
                        />
                      </BootstrapTooltip>
                    )}
                  </div>
                  <div className='subheading text-16 text-regular'>
                    {item.description}
                  </div>
                </div>
                <div className='button'>
                  {mySubjects.includes(item.getId()) ? (
                    <BootstrapTooltip
                      title={
                        mySubjects.length < 2
                          ? 'At least one subject must be retained'
                          : ''
                      }
                      placement='bottom-end'
                    >
                      <button
                        className={
                          mySubjects.length > 1
                            ? 'removeSubjectEnabled'
                            : 'removeSubjectDisabled'
                        }
                        disabled={mySubjects.length < 2}
                        onClick={() => handleClick(item, 'remove')}
                      >
                        {isMobile ? 'Remove' : 'Remove subject'}
                      </button>
                    </BootstrapTooltip>
                  ) : (
                    <button
                      className='addSubject'
                      onClick={() => handleClick(item, 'add')}
                    >
                      {isMobile ? 'Add' : 'Add subject'}
                    </button>
                  )}
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </section>
        <TableFooter itemsCount={filteredSubjects?.length || 0} />
        <SubjectListModal />
      </Wrapper>
    </Suspense>
  )
}

export default SubjectsList
