// Auth component performs the login process and restores the user session if any
import {useLog} from './utils/hooks';
import {sessionAtom, sessionSelector} from './state/session';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useEffect} from 'react';
import {
	ROUTE_LANDING,
	ROUTE_REGISTRATION,
	ROUTE_SERVER_ERROR,
	ROUTE_SIGNUP_TRIAL_ACCESS,
	ROUTE_TRIAL_SURVEY,
	ROUTE_UNAUTHORIZED,
} from './utils/constants';
import {Browser} from './utils/browser';
import Client from './tw/client';
import {Lists} from './utils/lists';
import {Strings} from './utils/strings';
import { featureFlagsSelector } from './state/state';

const Auth = () => {
	const REDIRECT_URL = 'redirectURL';
	const logger = useLog('Auth');
	useRecoilValue(featureFlagsSelector);
	const session = useRecoilValue(sessionAtom);
	const [restoredSession, setSession] = useRecoilState(sessionSelector);

	const formRedirectURL = (): string => {
		let redirectUrl = window.location.href;
		if (window.location.search) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, ...rest] = redirectUrl.split('/');
			redirectUrl = `/${Lists.default(rest).slice(2).join('/')}`;
		} else {
			redirectUrl = window.location.pathname;
		}
		return redirectUrl;
	};

	const handleSession = () => {
		logger.log('handleSession()', restoredSession);
		if (!restoredSession) {
			return;
		}

		if (restoredSession.error) {
			Client.deleteAuthToken();
			switch (restoredSession.error.toLowerCase()) {
				case 'unauthenticated':
					// Save current page to get back to it once we log in
					let redirectUrl = formRedirectURL();

					if (redirectUrl.includes(ROUTE_LANDING)) {
						redirectUrl = '/';
					}
					localStorage.setItem(REDIRECT_URL, redirectUrl);
					localStorage.setItem('login_redirect', Strings.default(restoredSession.redirectURL));
					Browser.navigate(ROUTE_LANDING);
					return;
				case 'trial_expired':
					Browser.navigate(ROUTE_TRIAL_SURVEY + Strings.default(restoredSession.redirectURL));
					return;
				case 'unauthorized':
					Browser.navigate(ROUTE_UNAUTHORIZED);
					return;
			}
		}
		setSession(restoredSession);

		// Go back to the page the user was on before logging in
		let redirectURL = Strings.default(localStorage.getItem(REDIRECT_URL));
		localStorage.removeItem(REDIRECT_URL);
		if (!restoredSession.redirectURL && redirectURL.includes('landing')) {
			redirectURL = '/';
		}
		Browser.navigate(Strings.default(redirectURL, '/') , {replace: true});
	};

	useEffect(() => {
		if (!session) {
			if (window.location.pathname.includes(ROUTE_SIGNUP_TRIAL_ACCESS)) {
				Browser.navigate(ROUTE_SIGNUP_TRIAL_ACCESS);
				return;
			}
			if (window.location.pathname.includes(ROUTE_TRIAL_SURVEY)) {
				const code = Browser.queryParam('code');
				Browser.navigate(`${ROUTE_TRIAL_SURVEY}?code=${code}`);
				return;
			}
			if (restoredSession) {
				let redirectURL = localStorage.getItem(REDIRECT_URL);
				if (!redirectURL) {
					redirectURL = formRedirectURL();
				}
				localStorage.setItem(REDIRECT_URL, redirectURL);
				handleSession();
			} else {
				Browser.navigate(ROUTE_SERVER_ERROR);
			}
		} else {
			if (!session.getQualifications().length) {
				// redirect to te registration screens
				Browser.navigate(ROUTE_REGISTRATION);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session, restoredSession]);

	return null;
};

export default Auth;