import {CMSObject} from './__CMSObject';
import {months, UUID} from '../types';
import {Lists} from '../../utils/lists';
import {Strings} from '../../utils/strings';
import {CTUnit} from './CTUnit';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {Numbers} from '../../utils/numbers';

export class ProductMapped extends CMSObject {
	public assessment: UUID[];
	public child_assessment: UUID[];
	public qualification: UUID[];
	public subject: UUID[];
	public specification_group: UUID[];
	public qualification_group: UUID[];
	public component: UUID[];
	public qualification_size: UUID[];
	public ct_unit: UUID[];
	public all_assessments: boolean;
	public all_child_assessments: boolean;

	public exam_year?: number | number[];
	public series?: string;
	public series_no?: number;
	public unit_glh?: number;
	public paper_number?: number;

	public get assessmentCodes(): string[] {
		const res: string[] = [];
		this.assessment.concat(this.qualification_size).forEach(assessmentId => {
			const assessment = getRecoil(references(assessmentId));
			if (assessment) {
				res.push(assessment.displayLabel());
			}
		});
		return res;
	}

	public get unitCodes(): string[] {
		const res: string[] = [];
		if (this.all_child_assessments) {
			return ['All'];
		}
		this.child_assessment.concat(this.component).concat(this.ct_unit).forEach(id => {
			const unit = getRecoil(references(id));
			if (unit) {
				res.push(unit.displayLabel());
			}
		});
		return res;
	}

	constructor(item: any = {}) {
		super(item);

		this.assessment = Lists.default(item.assessment);
		this.child_assessment = Lists.default(item.child_assessment);
		this.qualification = Lists.default(item.qualification);
		this.subject = Lists.default(item.subject);
		this.specification_group = Lists.default(item.specification_group);
		this.qualification_group = Lists.default(item.qualification_group);
		this.component = Lists.default(item.component);
		this.qualification_size = Lists.default(item.qualification_size);
		this.ct_unit = Lists.default(item.ct_unit);
		this.all_assessments = Boolean(item.all_assessments);
		this.all_child_assessments = Boolean(item.all_child_assessments);

		if (item.exam_year) {
			if (Array.isArray(item.exam_year)) {
				this.exam_year = Lists.default(item.exam_year);
			} else {
				this.exam_year = Number(item.exam_year);
			}
		}

		if (item.series) {
			this.series_no = months[item.series.toUpperCase()];
			this.series = Strings.default(item.series);
		}

		if (this.isTechnicals()) {
			this.resolveTechnicals();
		}

		if (item.paper_number) {
			this.paper_number = Numbers.default(item.paper_number);
		}
	}

	public matchesUnit(units: UUID[]): boolean {
		return this.all_assessments
			|| this.all_child_assessments
			|| this.child_assessment.filter(ca => units.includes(ca)).length > 0
			|| this.component.filter(c => units.includes(c)).length > 0
			|| this.ct_unit.filter(cu => units.includes(cu)).length > 0;
	}

	public isTechnicals() {
		return this.qualification_size.length > 0 && this.ct_unit.length > 0;
	}

	public resolveTechnicals() {
		const unit = this.ct_unit.map(unitId => getRecoil(references(unitId))).shift() as CTUnit;
		if (unit) {
			this.unit_glh = unit.glh;
		}
	}

	public getExtraLabel(): string | null {
		if (this.isTechnicals()) {
			return `${this.unit_glh} GLH`;
		}
		return super.getExtraLabel();
	}


}