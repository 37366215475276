import {Strings} from '../../../../utils/strings';
import {getRecoil} from '../../../../state/recoilNexus';
import {defaultSubjectAtom} from '../../../../state/session';
import {components} from 'react-select';
import {fi} from '../../../../utils/helpers';
import React from 'react';
import styled from '@emotion/styled';

const SubjectValElem = styled.span`
    height: 100%;
    webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
`;

const SingleSubjectValue = (optionProps) => {
	const [qualification, ...subject] = Strings.default(optionProps.children).split(':');
	const defaultSubject = getRecoil(defaultSubjectAtom);

	return (
		<components.SingleValue {...optionProps}>
			<SubjectValElem>{qualification}: <span
				className="subject">{subject.join(':')}</span>{fi(defaultSubject === optionProps.data.value, <span
				style={{fontSize: '14px', color: 'var(--color-blue)'}}> - default</span>)}</SubjectValElem>
		</components.SingleValue>
	);
};

export default SingleSubjectValue;