import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { useMediaQuery } from '@mui/material'
import { useRecoilState } from 'recoil'
import { actionModalAtom } from '../state'

const Wrapper = styled.div`
  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .modal-header {
    margin: 20px 16px 20px 16px;
    color: #212121;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  .modal-body {
    margin: 17px 16px 33px 16px;
    color: #212121;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    span {
      font-weight: 600;
    }
  }

  .divider {
    position: absolute;
    bottom: 76px;
    width: 100%;
    border: none;
    border-top: 1px solid var(--color-grey);
  }

  .bottom-allowance {
    height: 76px;
  }

  .action-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    button {
      margin-left: 16px;
      border: 1px solid #0076bd;
      border-radius: 4px;
      width: 78px;
      height: 44px;
      padding: 12px 16px 12px 16px;
      cursor: pointer;
    }
  }

  .cancel {
    color: #0076bd;
    border: 1px solid #0076bd;
    &:hover {
      background-color: #e6f3fa;
      color: #0076bd;
    }
  }

  .proceed {
    background-color: #0076bd;
    color: white;
    &:hover {
      background-color: #0064a8;
    }
  }

  @media (max-width: 600px) {
    .close-button {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    .modal-header {
      font-size: 16px;
    }

    .modal-body {
      font-size: 14px;
    }

    .divider {
      bottom: 63px;
    }

    .action-button {
      position: absolute;
      bottom: 16px;
      right: 16px;
      button {
        font-size: 14px;
        width: 60px;
        height: 38px;
      }
    }
  }
`

const SubjectListModal = () => {
  const isMobile = useMediaQuery('(max-width:450px)')

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? 320 : 444,
    minHeight: isMobile ? 156: 216,
    bgcolor: 'background.paper',
    border: '2px solid rgba(0, 0, 0, 0.3)'
  }

  const defaultModalState = {
    isActive: false,
    action: null,
    selectedItem: null,
    selectionConfirmed: false
  }

  const [actionModal, setActionModal] = useRecoilState(actionModalAtom)
  const handleClose = () => setActionModal(defaultModalState)
  const handleConfirm = (e) => {
    setActionModal({
      ...actionModal,
      isActive: false,
      selectionConfirmed: true
    })
  }

  const getModalLabel = (string = '') => {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
  }

  return (
    <div>
      <Modal
        open={actionModal.isActive}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        BackdropProps={{
          onClick: (e) => e.stopPropagation()
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Wrapper>
            <div className='close-button'>
              <CloseIcon fontSize={'small'} onClick={handleClose} />
            </div>
            <div className='confirmation-modal'>
              <div className='modal-header'>
                {getModalLabel(actionModal.action)} a subject
              </div>
              <div className='modal-body'>
                <p>
                  Are you sure you want to {actionModal.action} "
                  <span>{actionModal.selectedItem?.title}</span>" ?
                </p>
              </div>
              <div className='bottom-allowance'></div>
              <hr className='divider' />
              <div className='action-button'>
                <Button className='cancel' onClick={handleClose}>
                  Cancel
                </Button>
                <Button className='proceed' onClick={handleConfirm}>
                  {getModalLabel(actionModal.action)}
                </Button>
              </div>
            </div>
          </Wrapper>
        </Box>
      </Modal>
    </div>
  )
}

export default SubjectListModal
