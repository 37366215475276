import React, {useEffect, useMemo} from 'react';
import {atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import styled from '@emotion/styled';
import {sessionAtom} from '../state/session';
import CompleteProfile from '../components/Registration/CompleteProfile';
import {fi, resetAtomFamily} from '../utils/helpers';
import {RegistrationMessages} from '../components/Registration/messages';
import ChangeOrgModal, {organizationChangeAtom, selectedOrgAtom} from '../components/ModalDialogs/ChangeOrgModal';
import Preferences from '../components/Preferences/Preferences';
import {setRecoil} from '../state/recoilNexus';
import {Browser} from '../utils/browser';
import {device} from '../utils/constants';
import RegistrationStepper from '../components/Registration/RegistrationStepper';

const RegistrationWrapperElem = styled.div`
    height: 100%;
    background-color: var(--color-background);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const RegistrationContentElem = styled.div`
    background-color: var(--color-white);
    width: 670px;
    border: 1px solid var(--color-grey);
    padding-top: 24px;
    box-shadow: 0 0 0 1px var(--color-box-shadow);
    border-radius: 6px;
    @media ${device.mobile} {
        width: 100%;
    }
`;

export const registrationStepAtom = atom<number>({
	key: 'registrationStepAtom',
	default: 0,
});

export const registrationDataAtom = atomFamily<any, any>({
	key: 'registrationDataAtom',
	default: null,
});

export const showIntroAtom = atom<boolean>({
	key: 'showIntroAtom',
	default: true,
});
const Registration = () => {
	const userSession = useRecoilValue(sessionAtom);
	const [showIntro, setShowIntro] = useRecoilState(showIntroAtom);
	const setShowOrgModal = useSetRecoilState(organizationChangeAtom);
	const setLastSelectedOrg = useSetRecoilState(selectedOrgAtom);

	const orgModal = useMemo(() => {
		if (!userSession) return false;
		return !userSession.lastSelectedOrg;
	}, [userSession]);

	useEffect(() => {
		if (!userSession) return;
		if (!userSession.lastSelectedOrg) {
			setLastSelectedOrg('');
			setShowOrgModal(true);
		}
		if (userSession.getQualifications().length) {
			Browser.navigate('/');
		}
		//reset data when user changes centres
		resetAtomFamily(registrationDataAtom, ['qualifications', 'job']);
		setRecoil(registrationStepAtom, 0);
		setShowIntro(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession]);

	if (!userSession) return null;

	return (
		<RegistrationWrapperElem className="pb-16" data-testid="registration-wrapper">
			{fi(orgModal, <ChangeOrgModal/>,
				<div className="pt-56">
					<div className="flex-row-start text-24 text-latoBold mb-24" data-testid="registration-header"
						 id="header">{RegistrationMessages.RegistrationHeader}</div>
					<RegistrationContentElem data-testid="registration-content">
						{fi(showIntro && !userSession.isTrial(),
							fi(userSession.shouldUpdatePreferences(),
								<Preferences/>,
								<CompleteProfile/>),
							<RegistrationStepper/>)}
					</RegistrationContentElem>
				</div>)}
		</RegistrationWrapperElem>
	);
};

export default Registration;