import { useEffect } from 'react';
import { Browser } from '../../utils/browser';
import { ROUTES } from '../../utils/routes';
import { selector, useRecoilValue } from 'recoil';
import { pageSelector } from './utils';
import { subjectSelector } from '../../state/product';
import { documentSelector } from '../../state/documents';
import { useParams } from 'react-router-dom';
import { subjectUpdateSelector } from '../../state/subjectUpdate';
import { useMenu } from '../../state/menu';

const loadMenuData = selector({
	key: 'loadMenuData',
    get: ({ get }) => {
		const selectedSubject = get(subjectSelector);
		const documents = get(documentSelector);
		const subjectUpdates = get(subjectUpdateSelector);
		return {selectedSubject, documents, subjectUpdates};
    }
})

const FilterMenu = () => {
	useParams();
	const pages = useRecoilValue(pageSelector);
	const data = useRecoilValue(loadMenuData);

	const params = Browser.getParams(ROUTES);
	const {state} = useMenu();

	useEffect(() => {
		state.setPages(pages);
	}, [pages]);

	useEffect(() => {
		state.setData(data);
	}, [data]);


	useEffect(() => {
		state.setParams(params);
	}, [params]);

	return null;
}

export default FilterMenu;