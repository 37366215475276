import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckboxComp from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import CheckboxOption from './CheckboxOption';
import { Lists } from '../../../utils/lists';
import { fi } from '../../../utils/helpers';
import { Strings } from '../../../utils/strings';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../utils/constants';
import { Objects } from '../../../utils/objects';
import styled from '@emotion/styled';
import CustomList from './CustomList';
import MultiValueLabel from './MultiValueLabel';

export interface ISelectValue {
	value: any;
	label: string;
	object: any;
	options?: ISelectValue[];
	priority?: boolean;
}

export interface IGroupOption {
	label: string;
	options: ISelectValue[];
}

export interface ISelect {
	label?: string;
	id: string;
	name?: string;
	ariaLabel?: string;
	value: any;
	onChange: (...props: any) => any;
	showError?: boolean;
	errorMessage?: string;
	values: ISelectValue[];
	checkbox?: boolean;
	dataTestId?: string;
	required?: boolean;
	loading?: boolean;
	readonly?: boolean;
	render?: (val: any) => string;
	showSelectAll?: boolean;
	selectAllValue?: boolean;
	shouldRenderValue?: boolean;
	onChangeSelectAll?: (selectValue: boolean, id: string) => any;
	placeholder?: string;
	portal?: any;
	isClearable?: boolean;
	styles?: any;
	component?: any;
	hideSelectedOptions?: boolean;
	notNative?: boolean;
	isGrouped?: boolean;
}

interface ICustomStyles {
	showError: boolean;
	alignRight: boolean;
	alignTop: boolean;
}

const option = (provided) => {
	return {
		...provided,
		backgroundColor: 'transparent',
		fontSize: 16,
		'&:hover': {
			backgroundColor: 'var(--color-selected)',
		},
		'&.bold-option': {
			padding: 0,
			display: 'flex',
			alignItems: 'flex-start',
			fontFamily: 'var(--font-semi-bold)',
			cursor: 'pointer',
			':active': {
				backgroundColor: 'transparent',
			},
			'& .checkbox-option-text': {
				paddingTop: 9,
			},
		},
	};
};

const customStyles = (props: ICustomStyles) => {
	return {
		menuPortal: base => ({...base, zIndex: 9999, fontFamily: 'var(--font-regular)'}),
		multiValue: (provided, {isDisabled}) => {
			return {
				...provided,
				backgroundColor: 'transparent',
				fontSize: 19,
				fontFamily: 'var(--font-regular)',
				paddingLeft: '0',
				display: 'contents',
				'& .css-12jo7m5': {
					paddingLeft: '0',
				},
				'& div': {
					color: fi(isDisabled, 'var(--color-border)', 'var(--color-black)'),
				},
				'& div:first-of-type': {
					overflow: 'visible',
				},
			};
		},
		multiValueRemove: (provided) => {
			return {...provided, display: 'none'};
		},
		singleValue: (provided) => {
			return {...provided, lineHeight: 'normal'};
		},
		control: (provided) => {
			return {
				...provided,
				borderColor: fi(props.showError, 'var(--color-red)', 'var(--color-border)'),
				boxShadow: 'none',
				fontFamily: 'var(--font-regular)',
				'&:hover': {
					borderColor: 'var(--color-border)',
				},
				backgroundColor: 'white',
			};
		},
		option,
	};
};

const groupStyles: CSSProperties = {
	alignItems: 'center',
	color: '#424242',
	display: 'flex',
	fontSize: 12,
	paddingBottom: 4,
	textTransform: 'capitalize',
	justifyContent: 'space-between',
};

const formatGroupLabel = (groupOption: IGroupOption) => (
	<div style={groupStyles}>
		<span>{groupOption.label}</span>
	</div>
);

const SelectNative = styled.select`
    min-height: 38px;
    height: 42px;
    border: solid 1px var(--color-border-light);
    border-radius: 4px;
    color: var(--color-black);
    background-color: var(--color-white);
`;

const SelectComponent = (props: ISelect) => {
	const [alignRight, setAlignRight] = useState(false);
	const [alignTop, setAlignTop] = useState(false);
	const mobileOrTablet = useMediaQuery(device.tablet);

	const menuObserver: any = useRef({});
	const selectRef: any = useRef();

	const onMenuOpen = () => {
		const observeOnscreen = (entries: IntersectionObserverEntry[], _observer: IntersectionObserver): void => {
			const {boundingClientRect, intersectionRect} = entries[0];
			const isOffscreenLeft = boundingClientRect.width < intersectionRect.width;
			setAlignRight(!isOffscreenLeft);
			const isOffscreenBottom = boundingClientRect.height < intersectionRect.height;
			setAlignTop(!isOffscreenBottom);
		};

		setTimeout(() => {
			const menuList = selectRef.current.menuListRef;
			menuObserver.current = new IntersectionObserver(observeOnscreen);
			if (!menuList) return;
			menuObserver.current.observe(menuList);
		}, 1);
	};

	const onMenuClose = () => {
		setAlignRight(false);
		if (menuObserver.current?.disconnect) {
			menuObserver.current.disconnect();
		}
	};

	const handleChange = (newValue) => {
		props.onChange(newValue, props.id);
	};

	const handleMobileSelectChange = (e, id) => {
		if (props.checkbox) {
			let selectedOptions = e.target.selectedOptions;
			let newValues: any[] = [];
			for (let i = 0; i < selectedOptions.length; i += 1) {
				if (selectedOptions[i].label !== '' && selectedOptions[i].value !== '') {
					newValues.push({label: selectedOptions[i].label, value: selectedOptions[i].value});
				}
			}
			props.onChange([...newValues], id);
		} else {
			let newValue = props.values.find(v => Strings.default(v.value) === Strings.default(e.target.value));
			props.onChange(newValue, id);
		}
	};

	const handleValue = useMemo(() => {
		if (props.checkbox) {
			return props.value;
		}
		// simple selection
		return Lists.default<ISelectValue>(props.values).find((opt) => opt.value === props.value) || null;
		// eslint-disable-next-line
	}, [props.value, props.values]);

	const handleMobileValue = useMemo(() => {
		if (props.checkbox) {
			return Lists.default(props.value).map((i: any) => i.value);
		}
		// simple selection
		return Objects.default(props.values.find((option) => option.value === props.value)).value || '';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value, props.values]);

	return (
		<FormControl variant="standard" fullWidth={true} data-testid="select-component">
			<div className="flex-row-space-between" id={props.dataTestId}>
				{props.label && <FormLabel htmlFor={props.id} required={Boolean(props.required)}>
					{props.label}
				</FormLabel>}
				{fi(props.showSelectAll,
					<div>
						<CheckboxComp
							aria-label={`select-all-${props.id}`}
							id={`select-all-${props.id}`}
							data-testid={`select-all-${props.id}`}
							name={`select-all-${props.id}`}
							checked={props.selectAllValue}
							value={props.selectAllValue}
							onChange={(event) => {
								props.onChangeSelectAll && props.onChangeSelectAll(event.target.checked, props.id);
							}}
							color={'primary'}
							size="medium"
							disableRipple
							disabled={props.readonly}
						/>
						<FormLabel htmlFor={`select-all-${props.id}`}>
							Select all
						</FormLabel>
					</div>)}
			</div>
			{fi(mobileOrTablet && !props.notNative,
				<SelectNative
					multiple={!!props.checkbox}
					value={handleMobileValue}
					onChange={e => handleMobileSelectChange(e, props.id)}
					data-testid={'mobile-select-' + props.name}
					disabled={props.readonly || props.selectAllValue}
					id={props.id}
				>
					<option disabled></option>
					{fi(props.isGrouped,
						Lists.default<IGroupOption>(props.values).map(groupOption => (
								<optgroup label={groupOption.label} key={groupOption.label}>
									{Lists.default<ISelectValue>(groupOption.options).map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))
									}
								</optgroup>
						))
						,
						Lists.default<ISelectValue>(props.values).map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))
					)}
				</SelectNative>,
				<CustomList
					classNamePrefix={props.id}
					ref={selectRef}
					menuPortalTarget={props.portal}
					styles={{
						...customStyles({
							showError: Boolean(props.showError),
							alignRight,
							alignTop,
						}), ...props.styles,
					}}
					required={Boolean(props.required)}
					IconComponent={ExpandMore}
					aria-label={Strings.default(props.ariaLabel, props.id)}
					components={fi(props.component, {...props.component}, props.checkbox && {
						Option: CheckboxOption,
						MultiValueLabel,
					})}
					title={props.name}
					variant="outlined"
					id={props.id}
					placeholder={props.placeholder ? props.placeholder : ''}
					name={Strings.default(props.name, props.id)}
					value={handleValue}
					onChange={handleChange}
					error={props.showError}
					isLoading={props.loading || false}
					isMulti={!!props.checkbox}
					data-testid={Strings.default(props.dataTestId)}
					options={props.values}
					menuPlacement="auto"
					windowThreshold={20}
					hideSelectedOptions={fi(typeof props.hideSelectedOptions !== 'undefined', props.hideSelectedOptions, !props.checkbox)}
					closeMenuOnSelect={!props.checkbox}
					isClearable={props.isClearable}
					onMenuOpen={onMenuOpen}
					onMenuClose={onMenuClose}
					isDisabled={props.readonly || props.selectAllValue}
					formatGroupLabel={fi(props.isGrouped, formatGroupLabel,'')}
					formatOptionLabel={(optionLabel) => {
						if (props.render) {
							return props.render(optionLabel);
						}
						return Strings.default(optionLabel.label);
					}}
					//menuIsOpen ={true}
					autoWidth={true}
					controlShouldRenderValue={props.shouldRenderValue}
				/>)}
			{fi(props.showError, <FormHelperText>{props.errorMessage}</FormHelperText>)}
		</FormControl>
	);
};

export default SelectComponent;
