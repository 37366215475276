import React, {useContext, useMemo} from 'react';
import {WidgetContext} from '../widgets/widget';
import styled from '@emotion/styled';
import {DefaultAction, WidgetType} from '../../tw/types';
import ChildPageCard from './ChildPageCard';
import ItemCard from './ItemCard';


export const Title = styled.div`
    padding-top: 8px;
    grid-area: info;
    word-break: break-word;
    font-family: var(--font-regular);
    font-size: 16px;
    line-height: 20px;

    h5 {
        font-family: var(--font-bold);
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }

        > span.extra-label {
            margin-left: 16px;
            background-color: var(--color-selected);
            border: 1px solid var(--color-selected);
            border-radius: 4px;
            color: var(--color-blue);
            padding: 0 8px;

            font-family: var(--font-semi-bold);
            font-size: 14px;
            text-decoration: none !important;
        }
    }


    &:has(>p:last-child:nth-of-type(1)) {
        p {
            margin-top: 38px;
        }
    }

    &.checkbox {
        h5 {
            width: calc(100% - 30px);
        }
    }
`;

export const Description = styled.p`
    overflow: hidden;
    color: var(--color-monochrome);
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

export const Icon = styled.div`
    margin-right: 16px;
    grid-area: icon;
    width: auto;

    svg {
        &:hover {
            cursor: pointer;

            > g > circle {
                fill: var(--color-grey) !important;
            }

            > g > g > g > circle {
                fill: var(--color-grey) !important;
            }
        }
    }

    .eventContainer {
        text-align: center;
        font-family: var(--font-regular);
        color: var(--color-white);

        &:hover {
            cursor: pointer;
        }

        .halo {
            border: 1px solid var(--color-grey);
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: var(--color-grey);
            }

            .eventIcon {
                width: 28px;
                height: 38px;
                background-color: var(--color-light-violet);
                border-radius: 4px;
                color: white;


                &.pastEvent {
                    background-color: var(--color-monochrome)
                }

                .dots {
                    height: 6px;
                    width: 100%;
                    position: relative;

                    &::before,
                    &::after {
                        content: '';
                        width: 2px;
                        height: 2px;
                        background-color: white;
                        display: inline-block;
                        position: absolute;
                        top: 3px;
                    }

                    &::before {
                        left: 8px;
                    }

                    &::after {
                        right: 8px;
                    }
                }
            }
        }
    ;
    }
`;

type CardProps = {
    item: any,
    showCheckbox: boolean,
    style?: any,
    inline?: boolean
    listMode?: boolean,
}

const Card = ({item, showCheckbox, style, inline, listMode}: CardProps) => {
    const ctx = useContext(WidgetContext);

    const renderChildPageCard = useMemo(() => {
        const widget = ctx.widget;
        return widget.type === WidgetType.ChildPages || widget.type === WidgetType.FAQ;
    }, [ctx]);

    const defaultAction: DefaultAction = useMemo(() => {
        if (!item) return;
        return item.defaultAction();
    }, [item]);

    if (!item) return null;

    if (renderChildPageCard) {
        return (
            <ChildPageCard item={item} defaultAction={defaultAction}/>
        )
    }

    return (
        <ItemCard item={item}
                  defaultAction={defaultAction}
                  widgetContext={ctx}
                  showCheckbox={showCheckbox}
                  style={style}
                  inline={inline}
                  listMode={listMode}
        />
    )
};

export default Card;