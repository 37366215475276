import {HBox} from '../widgets/commons/HBox';
import styled from '@emotion/styled';
import {useRecoilValue} from 'recoil';
import {hasRestrictedAccess} from '../../state/session';
import CopyLink from './CardActions/CopyLink';
import React from 'react';
import RatingComponent from './CardActions/RatingComponent';
import AddToFavorite from './CardActions/AddToFavorite';
import CardActions from './CardActions/CardActions';
import {InfoOutlined, RemoveRedEyeRounded, SaveAlt, Start} from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';

const Wrapper = styled(HBox)`
    justify-content: flex-end;
    gap: 16px;
    min-width: 230px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;


    div {
        cursor: pointer;

        .rate-average {
            display: none;
        }

        svg {
            width: 24px;
        }
    }

    .card-actions {
        gap: 16px;

        .card-action {
            display: flex;
            align-items: center;

            button {
                padding: 0;
                min-width: 24px;
                width: 24px;
                height: 24px;

                &:hover {
                    background: none;
                }

                svg rect {
                    stroke: transparent;
                }

                svg path {
                    fill: var(--color-monochrome) !important;
                }
            }
        }
    }
`;

const ListModeActions = ({item, onToggleDetails}: {item: any, onToggleDetails: () => void}) => {
	const restrictedAccess = useRecoilValue(hasRestrictedAccess);

	if (item.hideActions()) {
		return null;
	}


	if (item.onlyShareAction) { // for event_material items display only share action
		return (
			<Wrapper>
				<CopyLink item={item} disabled={restrictedAccess}/>
			</Wrapper>
		);
	}

	return (
		<Wrapper className={'list-actions'}>
			<CopyLink item={item} disabled={restrictedAccess}/>
			<AddToFavorite item={item} disabled={restrictedAccess}/>
			<RatingComponent item={item} disabled={restrictedAccess}/>
			<span className={'show-info'} title={'Show card info'} onClick={onToggleDetails} role='button'>
                <InfoOutlined/>
            </span>
			<CardActions item={item}
						 downloadIcon={<SaveAlt/>}
						 previewIcon={<RemoveRedEyeRounded/>}
						 linkIcon={<HBox title={'Go to'}><LinkIcon/></HBox>}
						 courseIcon={<HBox title={'Start course'}><Start/></HBox>}
			/>
		</Wrapper>
	);
};

export default ListModeActions;