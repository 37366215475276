import FourthStep from './steps/FourthStep';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import React from 'react';
import {FeedbackMessages, InternationalFeedbackMessages} from './messages';
import FinalStep from './steps/FinalStep';
import {
	OCR_STREAM,
} from '../../utils/constants';

export const FeedbackConfig = (stream = OCR_STREAM) => {
	const messages = {
		OCR: FeedbackMessages,
		International: InternationalFeedbackMessages,
	};

	return [
		{
			component: () => <FirstStep/>,
			question: messages[stream].FirstStepQuestion,
			sliderLabels: ['Very hard', 'Very easy'],
			valuesOption: ['1', '2', '3', '4', '5'],
		},
		{
			component: () => <SecondStep/>,
			question: messages[stream].SecondStepQuestion,
			sliderLabels: ['Not likely at all', 'Extremely likely'],
			valuesOption: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
		},
		{
			component: () => <ThirdStep/>,
		},
		{
			component: () => <FourthStep/>,
			question: messages[stream].FourthStepQuestion,
			valuesOption: [
				{label: 'Yes', value: 'Yes'},
				{label: 'No', value: 'No'}],
		},
		{
			component: () => <FinalStep/>,
		},
	];
}
