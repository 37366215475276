import React, { Suspense, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import Form from './components/Form/Form';
import Loader from '../../Loader/Loader';
import TuneIcon from '@mui/icons-material/Tune';
import FilterSubjectWidget from '../../widgets/SubjectFilter/FilterSubjectWidget';
import { Backdrop, useMediaQuery } from '@mui/material';
import { device } from '../../../utils/constants';
import ClearFilters from './components/Form/ClearFilters';
import ClearIcon from '@mui/icons-material/Clear';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Results from './components/Results/Results';
import FilterForm from './components/Form/FilterForm';
import { resourceFinderFilterSelector, searchResultsAtom, toggleFilterAtom } from './state';
import { groupingAtom } from './components/Results/utils';

const FilterIcon = styled.div`
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-family: var(--font-bolder);
        color: var(--color-blue);
    }

    > svg {
        &.filters {
            color: var(--color-blue);
        }
    }
`;

const FilterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Filters = styled.div`
    background: white;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    max-height: 600px;
    z-index: 9999;
    padding: 16px;
    border-radius: 5px;
    overflow: auto;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ResourceFinder = () => {
	const mobileOrTablet = useMediaQuery(device.mobile);
	const [toggleFilter, setToggleFilter] = useRecoilState(toggleFilterAtom);
	const resetFilter = useResetRecoilState(resourceFinderFilterSelector);
	const resetResults = useResetRecoilState(searchResultsAtom);
	const filter = useRecoilValue(resourceFinderFilterSelector);
	const groupBy = useRecoilValue(groupingAtom);

	useEffect(() => {
		return () => {
			resetFilter();
			resetResults();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filtersNo = useMemo(() => {
		let appliedFilters: number = 0;
		appliedFilters = Number(filter.query.length > 0)
			+ Number(filter.series.length > 0)
			+ Number(filter.years.length > 0)
			+ Number(filter.contentTypes.length > 0)
			+ Number(groupBy !== 'none');
		return appliedFilters;
	}, [filter.query, filter.series, filter.years, filter.contentTypes, groupBy]);

	const onToggleFilter = () => {
		const appLayout = document.getElementById('app-layout');
		if (appLayout) {
			appLayout.classList.add('block-scroll');
		}

		setToggleFilter(!toggleFilter);
	};

	const onCloseFilter = () => {
		setToggleFilter(false);
		removeClass();
	};

	const removeClass = () => {
		const appLayout = document.getElementById('app-layout');
		if (appLayout) {
			appLayout.classList.remove('block-scroll');
		}
	};

	return (
		<>
			<FilterSubjectWidget key={1}/>
			{mobileOrTablet ?
				<FilterWrapper data-testid="filter">
					<FilterIcon onClick={onToggleFilter}>
						<TuneIcon className={filtersNo > 0 ? 'filters' : ''}/>
						{filtersNo > 0 && <div>({filtersNo})</div>}
					</FilterIcon>
					{toggleFilter &&
                        <>
                            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                      open={toggleFilter}/>
                            <Filters data-testid="filters">
                                <Header data-testid="filters-header">
                                    <span className="text-latoBold">Sort & Filter</span>
                                    <ClearIcon fontSize={'small'} onClick={onCloseFilter}/>
                                </Header>
                                <Form/>
                                <ClearFilters/>
                            </Filters>
                        </>
					}
				</FilterWrapper> :
				<FilterForm/>
			}

			<Suspense fallback={<Loader/>}>
				<Results/>
			</Suspense>

		</>
	)
		;
};

export default ResourceFinder;
