import React, {Suspense, useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import {Header as WidgetHeader} from '../commons/WidgetHeader';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {defaultSubjectAtom, sessionAtom} from '../../../state/session';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import {references} from '../../../state/state';
import {Subject} from '../../../tw/models/Subject';
import {SeenOptions, Types} from '../../../tw/types';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import {subjectSelector} from '../../../state/product';
import {Page} from '../../../tw/models/Page';
import {useMenu} from '../../../state/menu';
import {Browser} from '../../../utils/browser';
import {CancelOutlined, Delete, Done, Edit} from '@mui/icons-material';
import {
	getQualificationDataSource,
	getSubjectByQualification,
	getSubjectDataSource,
	getUnitDataSource,
	saveUserPreferences,
} from '../../Registration/utils';
import {Lists} from '../../../utils/lists';
import {registrationDataAtom} from '../../../pages/Registration';
import {Objects} from '../../../utils/objects';
import {ConfirmationModalProps, modalAtom} from '../../ModalDialogs/ModalDialog';
import {fi} from '../../../utils/helpers';
import {Messages} from '../../../utils/messages';
import RadioButton from '../../FormComponents/Radio/RadioButton';
import {FormControlLabel, useMediaQuery} from '@mui/material';
import SelectComponent from '../../FormComponents/Select/SelectComponent';
import {UserQualification, UserSession} from '../../../tw/models/Session';
import {Strings} from '../../../utils/strings';
import Client from '../../../tw/client';
import {AlertSeverity, snackbarStateAtom} from '../../Snackbar/SnackbarComponent';
import GA from '../../../tw/models/GA';
import Loader from '../../Loader/Loader';
import {ISubject, selectedSubjectAtom} from '../../SubjectsComponents/utils';
import {device} from '../../../utils/constants';
import SubjectsComponent from '../../SubjectsComponents/SubjectsComponent';
import {preferredSubjectsInfosSelector} from '../../../state/subjectUpdate';
import { SubjectUpdateTypes } from '../../../tw/models/SubjectUpdate';

const Wrapper = styled.div`
    user-select: none;

    @media ${device.mobile} {
        border: solid 1px var(--color-border-light);
        border-radius: 6px;
    }
`;

const Header = styled(WidgetHeader)`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media ${device.mobile} {
        hr {
            width: 100%;
            border: none;
            height: 1px;
            color: var(--color-border-light);
            background-color: var(--color-border-light);
        }

        h2 {
            padding: 8px 16px;
            margin: 0;
        }

        flex-direction: column;
        align-items: flex-start;

        div {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 8px 0;
        }

        button {
            height: fit-content;
            padding: 8px;
            font-size: 14px;
        }
    }
`;

const TableWrapper = styled.div`
    border: 1px solid var(--color-border-light);
    border-radius: 6px;
    background: white;
    overflow-y: auto;
    max-height: 440px;
`;

const Table = styled.table`
    overflow: hidden;
    width: 100%;


    tbody tr {
        border-top: 1px solid var(--color-border-light);

        &:hover {
            background: var(--color-grey);

            .action-buttons {
                visibility: visible;
            }
        }

        &.edit-mode {
            background: var(--color-grey);

            .MuiFormControl-root {
                margin-bottom: 0;
            }

            td:last-of-type {
                vertical-align: middle;
            }
        }
    }


    thead th {
        font-weight: 700;
        padding: 18px 16px;
    }

    td, th {
        &:first-of-type {
            width: 25%;
        }

        &:nth-of-type(2) {
            width: 25%;
        }

        &:nth-of-type(3) {
            width: 20%;
        }

        &:nth-of-type(3) {
            width: 20%;
        }

        &:last-of-type {
            width: 20%;
        }

        padding: 8px 8px 8px 16px;
        text-align: left;
        line-height: 17px;
    }

    td {
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-of-type {
            text-align: right;
        }

		&.subject-updates {
			width: 200px;
			display: flex;
			align-items: center;
			column-gap: 12px;

			a {
				color: var(--color-blue);
				text-decoration: underline;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			span {
				display: flex;
				align-items: center;
				column-gap: 6px;

				span {
					&.flag {
						padding: 0px 4px;
						border-radius: 20px;
						color: var(--color-white);
						line-height: unset;
						font-size: 10px;
					}
					&.urgent {
						background-color: var(--color-darker-red);
					}
					&.new {
						background-color: var(--color-green);
					}
					&.updated {
						background-color: var(--color-green)
					}
				}
			}
		}

        button {
            padding: 8px;
            margin-right: 8px;

            &:last-of-type {
                margin-right: 0;
            }

            min-width: 0;

            span {
                margin: 0;

                svg {
                    color: var(--color-menu-grey);
                }
            }
        }
    }
`;

const SubjectData = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
        color: var(--color-blue);
        margin-right: 8px;
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ActionWrapper = styled.div`
    visibility: hidden;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media ${device.tablet} {
        visibility: visible;
    }
`;

const SubjectsWrapper = styled.div`
    background: var(--color-white);
    border-top: solid 1px var(--color-border-light);
    border-radius: 4px;
    padding: 8px;
`;
type editStateProps = {
	state: {
		qualification: string,
		subject: string,
		modular: boolean,
		technicals: boolean,
		assessments: string[],
		units: string[],
		subjectObj: Subject | null
	},
	idx: number,
}

const MySubjectWidget = (props: any) => {
	const session = useRecoilValue(sessionAtom);
	const setSubject = useSetRecoilState(subjectSelector);
	const setValue = useSetRecoilState(modalAtom);
	const defaultSubject = useRecoilValue(defaultSubjectAtom);
	const {setPage} = useMenu();
	const [editState, setEditState] = useState<editStateProps | null>(null);
	const [addNew, setAddNew] = useState(false);
	const [isUnitTrue, setIsUnitTrue] = useState(false);
	const [selectedSubject, setSelectedSubject] = useRecoilState(selectedSubjectAtom);
	const mobile = useMediaQuery(device.mobile);
	const preferredSubjectsInfos = useRecoilValue(preferredSubjectsInfosSelector)

	useEffect(() => {
		return () => {
			cancelEdit();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isUnit = false
		session?.getQualifications().forEach(q => {
			const sObj = getRecoil(references(q.subject)) as Subject;
			if (sObj) {
				const userUnits = sObj.userUnits().filter(u => u.getType() === Types.CHILD_ASSESSMENT);
				if (userUnits.length > 0) {
					isUnit = true
				}
			}
		})
		setIsUnitTrue(isUnit)
	}, [session?.getQualifications(), editState?.state.modular])

	const userSubjects = useMemo(() => {
		const subjects: any = [];
		if (!session) {
			return {subjects: [], preferences: []};
		}

		session.getQualifications().forEach(q => {
			const qObj = getRecoil(references(q.qualification));
			const sObj = getRecoil(references(q.subject)) as Subject;

			if (qObj && sObj) {
				const subjectUnits = sObj.getUnits().length;
				const userUnits = sObj.userUnits().filter(u => u.getType() === Types.CHILD_ASSESSMENT);
				let unitLabel: string = 'All';
				if (subjectUnits !== userUnits.length) {
					unitLabel = sObj.userUnits().filter(u => u.getType() === Types.CHILD_ASSESSMENT).map(u => u.displayLabel()).join(', ');
				}
				if (userUnits.length === 0) {
					unitLabel = '-';
				}
				const units = userUnits.map(u => ({
					label: u.displayLabel(),
					value: u.getId(),
				}));
				//filter to subject update type
				const subjectInfos = preferredSubjectsInfos[sObj.getId()].filter(si => si.type === SubjectUpdateTypes.Update || si.type === SubjectUpdateTypes.UpdateAnnouncement)
				const urgentUpdates = subjectInfos.filter(si => si.urgent).length
				const newUpdates = subjectInfos.filter(si => si.__seen === SeenOptions.New).length
				const updatedUpdates = subjectInfos.filter(si => si.__seen === SeenOptions.Updated).length
				const subjectUpdates = {
					flags: [
						urgentUpdates && <span key={'urgent'} className='flag urgent'>{urgentUpdates} Urgent</span>,
						updatedUpdates && <span key={'updated'} className='flag updated'>{updatedUpdates} Updated</span>,
						newUpdates && <span key={'new'} className='flag new'>{newUpdates} New</span>
					],
					total: subjectInfos.length
				}
				subjectUpdates.flags = subjectUpdates.flags.filter(f => f)
				subjects.push([qObj, sObj, unitLabel, defaultSubject === sObj.getId(), units, q.created, subjectUpdates]);
			}
		});

		subjects.sort((a, b) => {
			const aVal = a[0].displayLabel();
			const bVal = b[0].displayLabel();
			const qCompare = aVal.localeCompare(bVal);

			if (qCompare !== 0) {
				return qCompare;
			}

			return a[1].displayLabel().localeCompare(b[1].displayLabel());

		});
		let preferencesSubjects = subjects.map(s => {
			return new UserQualification({
				qualification: s[0].getId(),
				subject: s[1].getId(),
				assessments: s[1].getAssessments().map(a => a.getId()),
				created: s[0].created,
				units: s[1].userUnits().map(u => u.getId()),
			});
		});
		return {subjects: subjects, preferences: preferencesSubjects};
	}, [session, defaultSubject, preferredSubjectsInfos]);

	const redirectUrl = useMemo(() => {
		const page = getRecoil(references(props.pageLink)) as Page;
		if (page) {
			return page.getURL();
		}
		return '/';
	}, [props.pageLink]);

	const subjectUpdatesUrl = useMemo(() => {
		const page = getRecoil(references(props.updateLink)) as Page;
		if (page) {
			return page.getURL();
		}
		return '/';
	}, [props.updateLink]);

	const onSubjectClick = (evt: any, subject: Subject) => {
		evt.preventDefault();

		setSubject(subject.getId() as any);
		Browser.setQueryParam({subject: subject.getId(), unit: 'all'});

		setPage(props.pageLink);
	};

	const removeSubject = (subject: Subject, isDefault: boolean) => {
		if (!session) {
			return;
		}
		let newQualifications = session.getQualifications().filter(q => q.subject !== subject.getId());
		setRecoil(registrationDataAtom('qualifications'), newQualifications);
		if (!session.isFirstTimeUser() && !session.shouldUpdatePreferences()) {
			//call savePreferences
			const qualificationToRemove = Objects.default(getRecoil(references(subject.qualificationMappingID))).displayLabel();
			let confirmationModalObject: ConfirmationModalProps = {
				title: `Remove subject`,
				message:
					<p>Are you sure you want to
						remove <strong>{qualificationToRemove}: {subject.displayLabel()}? </strong>
						{fi(isDefault, ' This will also reset your default subject selection.')}
					</p>,
				onConfirm: async () => {
					// removing a subject that is marked as default - updated preferences first (tour & defaultSubject) and after that qualifications
					if (isDefault) {
						session.setDefaultSubject('').then(() => {
							saveUserPreferences().catch();
						});
					} else {
						await saveUserPreferences();
					}
				},
				hasCancel: true,
				confirmText: 'Remove',
				dangerColor: true,
			};
			setValue(confirmationModalObject);
		}
	};

	const onEditSubject = (evt: any, row: any, idx: number) => {
		evt.preventDefault();
		setAddNew(false);
		setEditState({
			state: {
				qualification: row[0].getId(),
				subject: row[1].getId(),
				units: fi(row[2].toLowerCase() === 'all', [{label: 'All units', value: 'all'}], row[4]),
				modular: row[1].isModular(),
				assessments: row[1].assessmentIDs,
				subjectObj: row[1],
				technicals: row[1].isTechnicals(),
			},
			idx,
		});
	};

	const cancelEdit = () => {
		setEditState(null);
		setAddNew(false);
		setSelectedSubject(null);
	};

	const onChange = (obj, field) => {
		const tmp = Objects.default({...editState});
		switch (field) {
			case 'qualification':
				tmp.state.qualification = obj.value;
				tmp.state.subject = '';
				tmp.state.units = [];
				tmp.state.modular = false;
				tmp.state.assessments = [];
				tmp.state.technicals = false;
				tmp.subjectObj = null;
				break;
			case'subject':
				tmp.state.subject = obj.value;
				tmp.state.units = [];
				const subject = getSubjectByQualification(tmp.state.qualification, obj.value);
				if (subject) {
					tmp.state.subjectObj = subject;
					tmp.state.modular = false;
					if (subject.isModular()) {
						tmp.state.modular = true;
						tmp.state.units = [{label: 'All units', value: 'all'}];
					}
					if (subject.isTechnicals()) {
						tmp.state.technicals = true;
						tmp.state.assessments = subject.getAssessments().map(a => a.getId());
					} else {
						tmp.state.assessments = subject.assessmentIDs;
					}
				}
				break;
			case 'units':
				if (Array.isArray(obj) && obj.find(u => u.value === 'all')) {
					const selectedUnits = obj.filter(u => u.value !== 'all');
					// if old state didn't have all units and now all units is selected, keep only all units option
					if (!tmp.state.units.find(u => u.value === 'all')) {
						tmp.state.units = [{label: 'All units', value: 'all'}];
						break;
					}
					if (selectedUnits.length) {
						tmp.state.units = selectedUnits;
					}
				} else {
					// if length of selected units is the same as the length of subject's units, select all units option
					const subjectUnitsLength = Objects.default(getSubjectByQualification(tmp.state.qualification, tmp.state.subject)).getUnits().length;
					if (subjectUnitsLength === obj.length) {
						tmp.state.units = [{label: 'All units', value: 'all'}];
						break;
					}
					tmp.state.units = obj;
				}
				break;
			default:
				break;
		}
		setEditState(tmp);
	};

	useEffect(() => {
		if (mobile) {
			if (selectedSubject == null) {
				setAddNew(false);
				setEditState(null);
			}
			return;
		}
		if (selectedSubject && selectedSubject.qualification === '') {
			onAddNewSubject();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSubject, mobile]);

	const onAddNewSubject = (evt?: any) => {
		if (evt) evt.preventDefault();
		if (mobile) {
			let newSubject: ISubject = {
				qualification: '',
				class_size: 0,
				teaching: '',
				subject: '',
				units: [],
			};
			setSelectedSubject(newSubject);
		}
		setAddNew(true);
		setEditState({
			state: {
				qualification: '',
				subject: '',
				units: [],
				modular: false,
				assessments: [],
				subjectObj: null,
				technicals: false,
			},
			idx: -1,
		});
	};

	useEffect(() => {
		if (addNew || (selectedSubject && selectedSubject.qualification === '')) {
			const editRow = mobile ? document.getElementById('subjects-wrapper') : document.getElementById('my-subjects-header');
			if (editRow) {
				setTimeout(() => {
					editRow.scrollIntoView();
				}, 10);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addNew, selectedSubject]);

	/*row = [qualif, subject, unitLabel, default, units]*/
	const renderRow = (row: any, idx: number) => {
		if (!session) {
			return null;
		}
		const userSubjectIds = userSubjects.subjects.map((s: any) => s[1]).map(i => i.getId());
		const [qualification, subject, unitLabel, isDefault] = row;
		const subjectUpdates = row[6]

		if (editState && editState.idx === idx) {
			const {qualification, subject, units, modular} = editState.state;
			if (subject) {
				const idxOf = userSubjectIds.indexOf(subject);
				userSubjectIds.splice(idxOf, 1);
			}
			return (
				<tr key={`${idx}-subject-row`} data-testid={`${idx}-subject-row`} className={'edit-mode'}
					id="edit-mode-row">
					<td>
						<SelectComponent
							id={'qualification'}
							value={qualification}
							onChange={onChange}
							values={getQualificationDataSource()}
							portal={document.body}
							placeholder="Select a qualification"
						/>
					</td>
					<td>
						<SelectComponent
							id={'subject'}
							value={subject}
							onChange={onChange}
							values={getSubjectDataSource(qualification, '').filter(s => !userSubjectIds.includes(s.value))}
							portal={document.body}
							placeholder="Select a subject"
						/>
					</td>
					{modular ?
						<td>
							<SelectComponent
								id={'units'}
								value={units || [{label: 'All units', value: 'all'}]}
								checkbox={true}
								onChange={onChange}
								values={getUnitDataSource({
									qualification: qualification,
									subject: subject,
								}, [{name: 'qualification'}, {name: 'subject'}], true)}
								render={(option) => {
									setIsUnitTrue(true)
									return Lists.default(Objects.default(option).label).toString().split(' - ')[0];
								}}
								portal={document.body}
							/>
						</td> : null
					}
					<td>
						<Button title="Cancel" data-testid="cancel-edit-btn"
								startIcon={<CancelOutlined fontSize={'small'}/>}
								onClick={cancelEdit}/>
						<Button disabled={!qualification || !subject} title="Save changes"
								data-testid="save-changes-btn"
								startIcon={<Done fontSize={'small'} style={{color: 'var(--color-blue)'}}/>}
								onClick={(e) => onConfirm(e, row[1])}/>
					</td>
				</tr>
			);
		}

		return (
			<tr key={`${idx}-subject-row`} data-testid={`${idx}-subject-row`}>
				<td><span title={qualification.displayLabel()}>{qualification.displayLabel()}</span></td>
				<td>
					<SubjectData>
						{isDefault && <span title="Default subject"
											data-testid="default-subject-icon"><CheckCircleOutlined/></span>}
						<a data-testid={`${idx}-subject-title`} title={subject.displayLabel()}
						   href={`${redirectUrl}?subject=${subject.getId()}`}
						   onClick={(evt) => onSubjectClick(evt, subject)}>{subject.displayLabel()}</a>
					</SubjectData>
				</td>
				{fi(isUnitTrue, <td><span title={unitLabel}>{unitLabel}</span></td>, null)}
				<td className={'subject-updates'}>
					{fi(subjectUpdates,
						<span>
							{
								fi(subjectUpdates.total,
									<a data-testid={`${idx}-subject-update-count`} title={subject.displayLabel()}
									href={`${subjectUpdatesUrl}?subject=${subject.getId()}`}>
										{ subjectUpdates.total }
									</a>,
									"-"
								)
							}
							<span data-testid={`${idx}-subject-update-flags`}>
								{ subjectUpdates.flags }
							</span>
						</span>
					)}
				</td>
				<td>
					<ActionWrapper className={'action-buttons'} data-testid={`${idx}-actions-cell`}>
						<div>
							<FormControlLabel
								data-testid={`${subject.getId()}-test-id`}
								value="Mark as default"
								label="Mark as default"
								style={{marginLeft: '8px'}}
								title={Messages.DefaultSubjectTooltip}
								control={<RadioButton value={subject.getId()}
													  data-testid={`${idx}-default-subject-btn`}
													  checked={isDefault}
													  onChange={(evt) => session.setDefaultSubject(evt.target.value)}/>}
							/>
						</div>
						<Button title="Edit" data-testid={`${idx}-edit-subject-btn`}
								startIcon={<Edit fontSize={'small'}/>}
								onClick={(evt) => onEditSubject(evt, row, idx)}/>
						<Button title="Remove" startIcon={<Delete fontSize={'small'}/>}
								data-testid={`${idx}-remove-subject-btn`}
								disabled={userSubjects.subjects.length === 1}
								onClick={() => removeSubject(subject, isDefault)}
						/>
					</ActionWrapper>
				</td>
			</tr>
		);
	};

	const disableAddButton = useMemo(() => {
		if (mobile) {
			return !!(selectedSubject && (selectedSubject.qualification === '' || selectedSubject.qualification));
		}
		return addNew || editState !== null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addNew, editState, selectedSubject]);

	if (!session) {
		return null;
	}

	const onConfirm = async (e, editedSubject: Subject | null) => {
		e.preventDefault();
		e.stopPropagation();
		const oldQualifications = JSON.parse(JSON.stringify(Lists.default<UserQualification>(session.getQualifications())));

		const newSubject = Objects.default(editState).state;

		// if a subject was edited
		if (editedSubject) {
			const idx = oldQualifications.findIndex(q => q.subject === editedSubject.getId());
			if (idx !== -1) {
				oldQualifications.splice(idx, 1);
			}
		}

		if (newSubject.technicals) {
			if (newSubject.assessments.length === 0) {
				newSubject.assessments = newSubject.subjectObj.getAssessments().map(a => a.getId());
			}
			newSubject.units = newSubject.subjectObj.getUnits().map(u => ({label: u.displayLabel(), value: u.getId()}));
		}

		if (newSubject.units.find(u => u.value === 'all')) {
			if (newSubject.subjectObj) {
				newSubject.units = newSubject.subjectObj.getUnits().map(u => ({
					label: u.displayLabel(),
					value: u.getId(),
				}));
			}
		}

		const qualification: any[] = [{
			qualification: newSubject.qualification,
			subject: newSubject.subject,
			assessments: newSubject.assessments,
			units: newSubject.units.map(u => u.value),
		}];

		oldQualifications.forEach(q => {
			qualification.push({
				qualification: q.qualification,
				subject: q.subject,
				assessments: q.assessments,
				units: q.units,
			});
		});

		const payload = {
			job_title: session.getJobTitle(),
			qualification: qualification,
			registrationinfo: session.getRegistrationInfo(),
			preferences: {...session.getPreferences(), defaultSubject: Strings.default(getRecoil(defaultSubjectAtom))},
		};

		setEditState(null);
		setAddNew(false);

		await Client.saveUserPreferences(payload).then((res) => {
			setRecoil(sessionAtom, new UserSession(res));
			setRecoil(snackbarStateAtom, {
				message: `${Messages.SubjectsUpdateSuccess}`,
				severity: AlertSeverity.Success,
			});
		}).catch(() => {
			setRecoil(snackbarStateAtom, {
				message: `${Messages.SubjectsUpdateError}`,
				severity: AlertSeverity.Error,
			});
		}).finally(() => {
			GA.UpdatePreferencesEvent();
		});
	};

	return (
		<Suspense fallback={<Loader/>}>

			<Wrapper data-testid="my-subjects">
				<Header data-testid="my-subjects-header">
					<h2>My subjects</h2>
					{fi(mobile, <hr/>)}
					<div>
						<Button variant={'contained'} onClick={onAddNewSubject} disabled={disableAddButton}>Add
							new subject</Button>
						<Button disabled={!defaultSubject} onClick={() => session.setDefaultSubject('')}>Reset the
							default</Button>
					</div>
				</Header>
				{fi(mobile,
					<SubjectsWrapper id="subjects-wrapper">
						<SubjectsComponent userData={userSubjects.preferences} keepOrder={true} redirectPageId={props.pageLink}/>
					</SubjectsWrapper>,
					<TableWrapper data-testid="my-subjects-table">
						<Table>
							<thead data-testid="my-subjects-table-header" id="my-subjects-header">
							<tr>
								<th>Qualification</th>
								<th>Subject</th>
								{fi(isUnitTrue,<th>Unit</th>, null)}
								<th>Subject Updates</th>
								<th></th>
							</tr>
							</thead>
							<tbody data-testid="my-subjects-table-body">
							{addNew && renderRow(['', '', '', false, []], -1)}
							{userSubjects.subjects.map((row: any, idx: number) => (renderRow(row, idx)))}
							</tbody>
						</Table>
					</TableWrapper>)}

			</Wrapper>
		</Suspense>
	);
};

export default MySubjectWidget;
