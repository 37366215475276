import { UUID, WidgetType } from '../../tw/types';
import { Lists } from '../../utils/lists';
import { atom, selector } from 'recoil';
import Client from '../../tw/client';
import { Page } from '../../tw/models/Page';
import { sessionAtom } from '../../state/session';
import { featureFlagsSelector, selectedPageAtom } from '../../state/state';
import { RESOURCE_FINDER_FLAG } from '../../utils/constants';
import { getRecoil } from '../../state/recoilNexus';
import { DocumentsWrapper } from '../../tw/models/DocumentsWrapper';
import { Objects } from '../../utils/objects';
import { SubjectUpdate } from '../../tw/models/SubjectUpdate';
import { addLevelToTree, fi } from '../../utils/helpers';

export interface ITreeItem {
	object: Page;
	index: number;
	id: UUID;
	order: number;
	level?: number;
	parent?: ITreeItem;
	opened?: boolean;
	selected?: boolean;
	matchChildren?: number;
	children: Array<ITreeItem>;
}

export const buildTree = (items: Page[]): ITreeItem[] => {
	const roots: ITreeItem[] = [];
	const index: { [key: UUID]: ITreeItem } = {};
	const selectedItem = getRecoil(selectedPageAtom);
	// generate a map of all items wrapped in a ITreeItem
	items.forEach((item, idx) => {
		const node = {
			index: idx,
			object: item,
			id: item.getId(),
			opened: false,
			order: item.order,
			children: [],
			selected: Objects.default(selectedItem).id === item.getId(),
		};
		if (!item.parent) {
			roots.push(node);
		}
		index[item.getId()] = node;
	});

	// Link children to their parents
	for (let id in index) {
		const node = index[id];
		if (!node.object.parent) {
			continue;
		}

		const parent = index[node.object.parent];
		if (parent) {
			node.parent = parent;
			parent.children.push(node);
			Lists.sort(parent.children, 'order');
		}
	}
	Lists.sort(roots, 'order');
	addLevelToTree(roots);
	return roots;
};

export const findTreeNode = (nodes: ITreeItem[], predicate: string | ITreeItem | ((node: ITreeItem) => boolean)) => {
	if (!predicate) {
		return null;
	}
	for (let i = 0; i < nodes.length; i++) {
		const node = nodes[i];
		const isTreeItem = (typeof predicate === 'object' && predicate.id && predicate.object instanceof Page);
		if (typeof predicate === 'string' || isTreeItem) {
			if (node.id === fi(isTreeItem, (predicate as ITreeItem).id, predicate)) {
				return node;
			}
		} else if (typeof predicate === 'function') {
			if (predicate(node)) {
				return node;
			}
		}
		if (node.children.length > 0) {
			const matched = findTreeNode(node.children, predicate);
			if (matched) {
				return matched;
			}
		}
	}
	return null;
};

export const filterTree = (pageTree: ITreeItem[], documents: DocumentsWrapper, subjectUpdates: SubjectUpdate[]) => {
	if (documents.length === 0 && subjectUpdates.length === 0) {
		return;
	}
	const keepPage = (page: ITreeItem): boolean => {
		let keep = !page.parent || page.object.isLinkPage() || page.object.hasContent(documents, subjectUpdates);
		for (let i = 0; i < page.children.length; i++) {
			const child = page.children[i];
			const keepChild = keepPage(child);
			if (keepChild) {
				keep = true;
			} else {
				//remove child
				page.children.splice(i, 1);
				i--;
				child.parent = undefined;
			}
		}
		return keep;
	};

	for (let i = 0; i < pageTree.length; i++) {
		const node = pageTree[i];
		if (!keepPage(node)) {
			pageTree.splice(i, 1);
			i--;
		}
	}
};

// Stores pages fetched from the server and adds resource finder page if feature flag is enabled
export const pageSelector = selector({
	key: 'pageTreeSelector',
	get: async ({get}) => {
		const session = get(sessionAtom);
		const featureFlags = get(featureFlagsSelector);

		if (!session || !session.getQualifications().length) {
			return [];
		}

		let pages = await Client.getPages();

		// remove resource finder page if feature flag is disabled
		if (featureFlags.length && !featureFlags.includes(RESOURCE_FINDER_FLAG)) {
			pages.forEach(p => {
				p.config = p.config.filter(c => c.type !== WidgetType.ResourceFinder) as any;
				p.__data.config = Lists.default(p.__data.config).filter((c: any) => c.type !== WidgetType.ResourceFinder) as any;
			});
		}

		return pages;
	},
});

type HomePaths = {
	subjectHome: string;
	home: string
}

export const homepagePath = atom<HomePaths>({
	key: 'homepageAtom',
	default: {
		subjectHome: '',
		home: '',
	},
});