import React from "react"
import styled from "@emotion/styled";
import {CMSObject} from "../../tw/models/__CMSObject";
import { fi } from '../../utils/helpers';

const Wrapper = styled.div`
    position: absolute;
    right: 0;
    font-family: var(--font-semi-bold);
    cursor: default;
    font-size: 12px;
    margin-top: -1px;
    line-height: 17px;

    > span {
        border-radius: 0 0 4px 4px;
        color: white;
        padding: 0 8px;
        height: 18px;
        display: inline-block;

        :first-of-type {
            background-color: var(--color-darker-red);
            margin-right: 8px;
			
			&.new {
                background-color: var(--color-green);
			}
        }

        :last-of-type {
            background-color: var(--color-green);
            margin-right: 16px;

            &.essential {
                background-color: var(--color-darker-violet);
            }
		
            &.urgent {
                background-color: var(--color-darker-red);
            }
			
            &.draft {
                background-color: var(--color-monochrome);
            }
        }

        &.pastEvent {
            background-color: var(--color-grey);
            color: var(--color-monochrome)
        }
		
		&.fullyBooked {
            background-color: var(--color-green);
		}
    }
	
	&.page-content {
		position: relative;
		
		> span {
			border-radius: 4px;
			margin-bottom: 8px;
			font-size: 14px;
		}
	}

    &.page-title {
        position: relative;

        > span {
            border-radius: 4px;
            font-size: 14px;
			margin-left: 16px;
        }
    }
`

const Flag = ({item, className}: {item: CMSObject, className?: any}) => {
	return (
		<Wrapper data-testid='label' className={`flag ${fi(className, className, '')}`} >
			{item.getFlag()}
		</Wrapper>
	)
}

export default Flag