import React, {useMemo} from 'react';
import styled from '@emotion/styled';
import RootTreeComponent from '../components/TreeMenu/RootTreeMenu';
import {useRecoilValue} from 'recoil';
import {scrollTopPosition} from '../state/state';
import {device} from '../utils/constants';
import {useMediaQuery} from '@mui/material';

const MainMenuLayout = styled.div`
    display: grid;
    grid-area: mainMenu;
    height: auto;
    min-height: 100vh;
    //overflow-y: auto;
    position: relative;
    border-right: solid 1px var(--color-border-light);

    &.sticky-position {
        position: fixed;
        top: 70px;
        width: 89px;
        z-index: 1;
    }

    @media ${device.mobile} {
        position: sticky;
        bottom: 0;
        z-index: 100;
        height: auto;
        min-height: unset;
        border: solid 1px var(--color-grey);
        width: 100%;
    }

`;
const VMenu = () => {
	const positionToTop = useRecoilValue(scrollTopPosition);
	const tabletOrMobile = useMediaQuery(device.mobile);

	const styling = useMemo(() => {
		if (tabletOrMobile) return '';
		if (positionToTop) return 'sticky-position';
	}, [positionToTop, tabletOrMobile]);

	return (
		<MainMenuLayout data-testid="main-menu" id="main-menu" className={styling}>
			<RootTreeComponent/>
		</MainMenuLayout>
	);
};

export default VMenu;