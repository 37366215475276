export enum FeedbackMessages {
	FirstStepQuestion = 'Would you say finding resources on this website is:',
	SecondStepQuestion = 'How likely are you to recommend Teach Cambridge to a friend or colleague?',
	ThirdStepPositiveQuestion = 'We are thrilled you feel that way. If we could do anything, what should we do to WOW you?',
	ThirdStepNegativeQuestion = 'What can we do to improve your experience and your score? Please be brutal and direct!',
	FourthStepQuestion = 'Your feedback is really important to us. Can we contact you about how we might improve your experience of Teach Cambridge?',
	FinalMessageStart = 'Thank you for answering this survey, ',
	FinalMessageEnd = '. Your feedback is highly appreciated.'
}

export const InternationalFeedbackMessages = {
	...FeedbackMessages,
	SecondStepQuestion: 'How likely are you to recommend School Support Hub to a friend or colleague?',
	FourthStepQuestion: 'Your feedback is really important to us. Can we contact you about how we might improve your experience of School Support Hub?',
}
