import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { device } from '../../../../../utils/constants';
import { filterFavoritesAtom, resourceFinderFilterSelector, searchResultsSelector, weHaveFiltersAtom } from '../../state';
import { invalidateFilterAtom } from '../../utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    button {
        width: max-content;
        padding: 0;

        :first-of-type {
            margin-right: 8px;
        }
    }

    @media ${device.mobile} {
        margin-top: 8px;
        button {
            width: 100%;
        }
    }
`;

const ClearFilters = () => {
	const resetFilter = useResetRecoilState(resourceFinderFilterSelector);
	const resetFavorites = useResetRecoilState(filterFavoritesAtom);
	const filter = useRecoilValue(resourceFinderFilterSelector);
	const setInvalidate = useSetRecoilState(invalidateFilterAtom);
	const filterFavorites = useRecoilValue(filterFavoritesAtom);
	const {filter: resultsFilter} = useRecoilValue(searchResultsSelector);
	const [weHaveFilters, setWeHaveFilters] = useRecoilState(weHaveFiltersAtom);

	useEffect(() => {
		setWeHaveFilters((filter.query.length > 0 || filter.series.length > 0 || filter.years.length > 0 || filter.contentTypes.length > 0 || filterFavorites) ||
			(resultsFilter.query.length > 0 || resultsFilter.series.length > 0 || resultsFilter.years.length > 0 || resultsFilter.contentTypes.length > 0 || resultsFilter.includeOnlyFavorites));
	}, [filter, resultsFilter])

	const clearFilters = () => {
		resetFilter();
		resetFavorites();
		setInvalidate((val) => val + 1);
	};

	return (
		<Wrapper className="clear-filter">
			<Button disabled={!weHaveFilters} size="small" onClick={clearFilters} variant="text" color={'secondary'}
					data-testid="clear-filters-btn">
				Clear filters
			</Button>
		</Wrapper>
	);
};

export default ClearFilters;
