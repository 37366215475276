import React from 'react'
import styled from '@emotion/styled'
import { useRecoilState } from 'recoil'
import { selectedTabAtom } from '../state'

const Wrapper = styled.div`
  .tabs-container {
    width: 76vw;
    overflow: auto;
    white-space: nowrap;
  }

  .tabs {
    display: inline-block;
    justify-content: left;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #757575;
    display: inline-block;
  }

  .tab.active {
    border-bottom: 4px solid #0076bd;
    color: #0076bd;
  }

  @media (max-width: 1000px) {
    .tabs-container {
      width: 87vw;
    }
  }

  @media (max-width: 450px) {
    .tabs-container {
      width: 90vw;
    }
  }
`

const Tab = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabAtom)

  return (
    <Wrapper>
      <div className='tabs-container'>
        <div className='tabs'>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab ${selectedTab === tab.id ? 'active' : ''}`}
              onClick={() => setSelectedTab(tab.id)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default Tab
