import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { getRecoil } from '../../../state/recoilNexus';
import { references } from '../../../state/state';
import { Page } from '../../../tw/models/Page';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Browser } from '../../../utils/browser';

export type PathwayWidget = {
	name: string;
	age: string;
	color: string;
	description: string;
	markdown: string;
	link: string;
	subjectInfo: string;
}

const Wrapper = styled.div<{color: string}>`
    display: flex;
    margin-right: 4px;

    :last-of-type {
        margin-right: 0;
    }

    * div.background {
        background-color: ${({color}) => color}80;
        color: var(--color-white);
    }

    * div.background-semi {
        background-color: ${({color}) => `${color}33`};
        font-weight: normal;
    }
`;

const Form = styled.div<{color: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 200px;
    & > div.color {
        color: ${({color}) => color};
    }

    * {
        font-family: var(--font-regular);
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 3px; 
        padding: 2px;
        margin:0;
        background:linear-gradient(75deg,${({color}) => color}, ${({color}) => `${color}80`} 90%);
        mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        mask-composite: xor;
        mask-composite: exclude; 
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index:100;
    font-size: 18px;
    
    padding: 8px 8px 8px 8px;
    line-height: 20px;
    font-weight: 700;
    margin: 2px;
    height: 68px;
`;

const Arrow = styled.svg<{ color: string }>`
    width:180px;
    height:70px;
    clip-path: path("M0 3C0 1.34315 1.34315 0 3 0H166.972L177.374 27.597C179.068 32.0912 179.088 37.0449 177.431 41.5527L166.972 70H0V3Z");
    position: absolute;
    background:${({ color }) => color};
`;

const Subtitle = styled.div`
    display: flex;
    padding: 8px 0 0 8px;
    z-index:100;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
`;

const Description = styled.div`
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
    z-index:100;
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
    }

    p {
        margin: 8px 0;
    }

    ul, ol {
        list-style: initial;
        padding-left: 17px;
    }
`;

const Link = styled.div`
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    z-index:100;
    color: var(--color-blue);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;

    :hover {
        cursor: pointer;
    }

    svg {
        margin-bottom: -2px;
        height: 20px;
        width: 20px;
    }
`;

const SubjectInfo = styled.div`
    display: flex;
    padding: 8px;
    font-size: 14px;
    height: 100px;
    z-index:100;
    line-height: 20px;
`;

const Pathway = (pathway: PathwayWidget) => {
	const pageLink = useMemo(() => {
		const page = getRecoil(references(pathway.link)) as Page;
		if (!page) return '/';
		return page.getURL();
	}, [pathway.link]);

	const title = useMemo<string[]>(() => {
		const result: string[] = [];
		const parts = pathway.name.split(' ');
		if (parts.length === 1) {
			result.push(parts[0]);
			result.push('');
		}
		if (parts.length > 1) {
			result.push(parts[0]);
			const last = parts.slice(1, parts.length).join(' ');
			result.push(last);
		}
		return result;
	}, [pathway.name]);

	return (
        <Wrapper color={pathway.color}>
                <Form color={pathway.color} data-testid="pathway-form">
                    <Arrow color={pathway.color}/>
                    <Title className={'background'} data-testid="pathway-title">
                        <span><h3>{title[0]}<br />{title[1]}</h3></span>
                    </Title>
                    <Subtitle className={'color'} data-testid="pathway-subtitle">Age {pathway.age}</Subtitle>
                    <Description data-testid="pathway-description" dangerouslySetInnerHTML={{ __html: pathway.markdown }} />
                    <SubjectInfo className={'background-semi'}>{pathway.subjectInfo}</SubjectInfo>
                    <Link className={'background-semi'} data-testid="pathway-link" onMouseDown={() => Browser.navigate(pageLink)} tabIndex={0}>
                        <span>Read more</span>
                        <NavigateNextIcon fontSize={'small'} />
                    </Link>
                </Form>
        </Wrapper>
	);
};
export default Pathway;