import React from 'react';
import styled from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';
import { sessionAtom } from '../../state/session';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { draftPreviewAtom } from '../../state/state';
import Toggle from '../FormComponents/Toggle/Toggle';

const PreviewElem = styled.div<{toggled: boolean}>`
    width: 100%;
    background-color: var(--color-lighter-blue-background);
    border: solid 1px var(--color-blue);
    position: fixed;
    top: 0;
    font-size: 16px;
    color: var(--color-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
    z-index: 9999;

    svg {
        margin-right: 16px;
    }

    ${props => !props.toggled &&
            `background-color: var(--color-lighter-grey); 
			 color: var(--color-grey-text);
			 border: solid 1px var(--color-grey-text);
			`}
`;
const Preview = () => {
	const user = useRecoilValue(sessionAtom);
	const [toggled, setToggled] = React.useState(true);
	const setDraftPreview = useSetRecoilState(draftPreviewAtom);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setToggled(!toggled);
		setDraftPreview(!event.target.checked);
	};
	if (!user || !user.preview) return null;
	return (
		<PreviewElem toggled={toggled}>
			<InfoOutlined/>
			<div className={'text-semiBold'}> Preview mode</div>
			<div className={'ml-16 text-bold'}>Draft</div>
			<Toggle value={toggled} onChange={onChange}/>
			<div className={'text-bold'}>Published and draft</div>
		</PreviewElem>
	);
};

export default Preview;