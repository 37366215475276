import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useRecoilState, useRecoilValue } from 'recoil';
import { filteredDocumentsSelector, hasFavoritesSelector, invalidateFilterAtom } from '../../utils';

import InputField from '../../../../FormComponents/Input/InputField';
import SelectComponent, { IGroupOption, ISelectValue } from '../../../../FormComponents/Select/SelectComponent';
import { subjectSelector } from '../../../../../state/product';
import { fi } from '../../../../../utils/helpers';
import { Chip, InputAdornment, useMediaQuery } from '@mui/material';
import { device } from '../../../../../utils/constants';
import ClearFilters from './ClearFilters';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { filterFavoritesAtom, resourceFinderFilterSelector, searchResultsAtom } from '../../state';
import { getFilterOptions } from '../Results/utils';
import { DocumentsWrapper } from '../../../../../tw/models/DocumentsWrapper';
import { selectedGroupAtom } from './Categories';

const Wrapper = styled.div`
    grid-area: filters;

    input {
        background-color: white;
        border-radius: 4px;
    }

    .search-term {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;

        .MuiInputBase-root {
            border-color: var(--color-grey);
        }
    }

    @media ${device.mobile} {
        margin-top: 16px;
    }
`;

const FilterWrapper = styled.div`
    display: flex;
    align-items: center;
    color: var(--color-monochrome);
    gap: 16px;

    .MuiFormControl-root {
        width: max-content;
        margin-bottom: 0;
    }

    .MuiInputAdornment-root {
        margin-left: 0;
        display: flex;
        width: 48px;
        height: 39px;
        max-height: 39px;
        align-items: center;
        justify-content: center;
        border-left: 1px solid var(--color-grey);

        button {
            width: 100%;
            height: 100%;
        }
    }

    .MuiDivider-root {
        margin-left: 0;
    }

    & > div {
        color: var(--color-lighter-monochrome);
    }
`;

const StyledChip = styled(Chip)(() => ({
    fontFamily: 'var(--font-semi-bold)',
    color: 'var(--color-lighter-monochrome)',
    borderRadius: '20px',
    padding: '18px 6px',
    borderColor: 'var(--color-grey)',

    '&.Mui-disabled': {
        pointerEvents: 'auto',

    },

    '&.selected': {
        backgroundColor: 'var(--color-lighter-blue-background)',
        color: 'var(--color-blue)',
        borderColor: 'var(--color-blue)',
    },
    '&:hover': {
        boxShadow: 'none',
        borderColor: 'var(--color-lighter-monochrome)',
        backgroundColor: 'var(--color-background)',
    },
}));

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '20px',
        width: '120px',
        fontSize: '15px',
        fontFamily: 'var(--font-semi-bold)',

        '&:hover': {
            borderColor: 'var(--color-lighter-monochrome)',
            cursor: 'pointer',
            backgroundColor: 'var(--color-background)',
        },
        '&:active': {
            borderColor: 'var(--color-lighter-monochrome)',
        },
        '&:focus-visible': {
            boxShadow: 'none',
        },
        boxShadow: fi(state.isFocused, 'none'),
        backgroundColor: fi(state.hasValue, 'var(--color-lighter-blue-background)'),
        borderColor: fi(state.hasValue, 'var(--color-blue)', fi(state.isFocused, 'var(--color-lighter-monochrome)', 'var(--color-grey)')),
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: fi(state.hasValue, 'var(--color-blue)', 'var(--color-lighter-monochrome)'),
    }),
    indicatorContainer: (provided, state) => ({
        ...provided,
        color: fi(state.hasValue, 'var(--color-blue)', 'var(--color-lighter-monochrome)'),
        '&:hover': {
            color: 'var(--color-monochrome)',
        },
        '&:active': {
            color: 'var(--color-monochrome)',
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        textAlign: 'center',

        color: fi(state.hasValue, 'var(--color-blue)', 'var(--color-lighter-monochrome)'),
    }),
    multiValue: (provided) => ({
        ...provided,
        display: 'none',
    }),
    menu: (provided) => ({
        ...provided,
        width: '300px',
    }),
};

const Form = () => {
    const [filter, setFilter] = useRecoilState(resourceFinderFilterSelector);
    const options = useRecoilValue(getFilterOptions);
    const subject = useRecoilValue(subjectSelector);
    const hasFavorites = useRecoilValue(hasFavoritesSelector)
    const results = useRecoilValue(searchResultsAtom)
    const [query, setQuery] = useState("")
    const filteredDocuments = useRecoilValue(filteredDocumentsSelector)
    const mobileOrTablet = useMediaQuery(device.mobile);
    const [favorites, setToggleFilter] = useRecoilState(filterFavoritesAtom);
    const selectedGroup = useRecoilValue(selectedGroupAtom);
    const [invalidate, setInvalidate] = useRecoilState(invalidateFilterAtom)

    const [contentTypes, setContentTypes] = useState<ISelectValue[]>([]);
    const [years, setYears] = useState<ISelectValue[]>([]);
    const [series, setSeries] = useState<ISelectValue[]>([]);

    useEffect(() => {
        setContentTypes(options.contentTypesOptions);
        setYears(options.yearsOptions);
        setSeries(options.seriesOptions);
    }, [subject]);

    //For content types with #priority tags
    const groupedContentTypes: IGroupOption[] = [
        {
            label: 'Priority',
            options: contentTypes.filter(contentType => contentType.priority),
        },
        {
            label: 'Non-Priority',
            options: contentTypes.filter(contentType => !contentType.priority),
        },
    ];

    useEffect(() => {
        setQuery(filter.query)
    }, [results])

    useEffect(() => {
        setInvalidate((val) => val + 1);
    }, [selectedGroup, favorites]);

    const onToggleFavorites = () => {
        if (!hasFavorites) {
            return
        }
        setToggleFilter((val) => !val)
    }

    useEffect(() => {
        if(!hasFavorites && favorites) {
            setToggleFilter(false)
        }
    }, [hasFavorites]);

    const setFilters = (docs: DocumentsWrapper, changes: any) => {
        const remainingContentTypes = docs.contentTypes().map(c => c.getId())
        const remainingYears = docs.examYears()
        const remainingSeries = docs.series()

        const c = filter.contentTypes.filter(i => remainingContentTypes.includes(i.value))
        const y = filter.years.filter(i => remainingYears.includes(i.value))
        const s = filter.series.filter(i => remainingSeries.includes(i.value))

        const updates = {
            contentTypes: c,
            series: s,
            years: y,
        }

        const yearOptions = options.yearsOptions.filter(y => remainingYears.includes(y.value));
        const seriesOptions = options.seriesOptions.filter(s => remainingSeries.includes(s.value))
        const contentTypesOptions = options.contentTypesOptions.filter(s => remainingContentTypes.includes(s.value))

        const change = Object.keys(changes)[0]
        switch (change) {
            case 'contentTypes':
                setYears(yearOptions);
                setSeries(seriesOptions);
                break;
            case 'years':
                setContentTypes(contentTypesOptions);
                setSeries(seriesOptions);
                break;
            case'series':
                setContentTypes(contentTypesOptions);
                setYears(yearOptions);
                break;
            default:
                setContentTypes(contentTypesOptions)
                setYears(yearOptions)
                setSeries(seriesOptions)
        }

        // update filter
        setFilter(val => ({...val, ...updates, ...changes}));
    }

    useEffect(() => {
        if (invalidate > 0) {
            onChange([], '')
        }
    }, [invalidate]);

    const onChange = (obj: any, field: string) => {
        let docs = filteredDocuments;
        if (filter.contentGroupTypes.length) {
            docs = filteredDocuments.byContentType(...filter.contentGroupTypes)
        }
        if (favorites) {
            docs = docs.filter(item => item.isFavorite())
        }

        switch (field) {
            case 'content-type-selector':
                // filter all documents by selected content types
                if (obj.length > 0) {
                    docs = docs.byContentType(...obj.map(c => c.value))
                }
                // filter all documents by selected year options
                if (filter.years.length) {
                    docs = docs.byYears(...filter.years.map(y => y.value))
                }
                // filter all documents by selected series options
                if (filter.series.length) {
                    docs = docs.bySeries(...filter.series.map(s => s.value))
                }
                // update dropdowns
                setFilters(docs, {contentTypes: obj})

                break;
            case 'year-selector':
                if (obj.length > 0) {
                    docs = docs.byYears(...obj.map(c => c.value))
                }
                // filter all documents by selected content types
                if (filter.contentTypes.length > 0) {
                    docs = docs.byContentType(...filter.contentTypes.map(c => c.value))
                }
                // filter all documents by selected series options
                if (filter.series.length) {
                    docs = docs.bySeries(...filter.series.map(s => s.value))
                }
                setFilters(docs, {years: obj});

                break;
            case 'series-selector':
                if (obj.length > 0) {
                    docs = docs.bySeries(...obj.map(c => c.value))
                }
                // filter all documents by selected content types
                if (filter.contentTypes.length > 0) {
                    docs = docs.byContentType(...filter.contentTypes.map(c => c.value))
                }
                // filter all documents by selected years options
                if (filter.years.length) {
                    docs = docs.byYears(...filter.years.map(y => y.value))
                }
                setFilters(docs, {series: obj});
                break;
            default:
                setFilters(docs, {});
        }
    }

    const onChangeQuery = (evt: any) => {
        setQuery(evt.target.value)
    }

    const handleInputKeyPress = (evt: any) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            handleSearch();
        }
    }

    const handleSearch = (q: string = query) => {
        setFilter((val) => ({...val, query: q}))
    }

    useEffect(() => {
        if (!subject) {
            return
        }

        let assessments: string[] = [];
        if (subject.isTechnicals()) {
            assessments = subject.getQualificationSizes();
        } else {
            assessments = subject.getAssessmentCodes();
        }
        setFilter((val) => ({...val, assessments: assessments}))
    }, [subject]);

    return (
        <Wrapper data-testid="form-wrapper">
            <form>
                <FilterWrapper>
                    <div className="text-regular text-16">Filter by</div>
                    <StyledChip
                        className={`text-semiBold ${favorites ? 'selected' : ''}`}
                        label="Favourite"
                        variant="outlined"
                        onClick={onToggleFavorites}
                        title={fi(hasFavorites, 'Filter by favourites', 'No more favorites items to filter by the current search criteria')}
                        disabled={!hasFavorites}/>
                    <SelectComponent
                        id="content-type-selector"
                        placeholder="Type"
                        value={results.filter.contentTypes}
                        onChange={onChange}
                        values={fi(
                            groupedContentTypes[0].options.length > 0,
                            groupedContentTypes,
                            contentTypes,
                        )}
                        isGrouped={fi(
                            groupedContentTypes[0].options.length > 0,
                            true,
                            false,
                        )}
                        checkbox={true}
                        styles={customStyles}
                        shouldRenderValue={false}
                        isClearable={false}
                    />
                    <SelectComponent
                        id="year-selector"
                        placeholder="Year"
                        value={results.filter.years}
                        onChange={onChange}
                        values={years}
                        checkbox={true}
                        styles={customStyles}
                        shouldRenderValue={false}
                        isClearable={false}
                    />
                    <SelectComponent
                        id="series-selector"
                        placeholder="Series"
                        value={results.filter.series}
                        onChange={onChange}
                        values={series}
                        checkbox={true}
                        styles={customStyles}
                        shouldRenderValue={false}
                        isClearable={false}

                    />

                    <div data-testid="search-bar-section" className="search-term">
                        <InputField
                            placeholder="Search a resource"
                            id="search-input"
                            value={query}
                            field={{name: 'search', label: ''}}
                            onChange={onChangeQuery}
                            onKeyPress={handleInputKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleSearch()}
                                        title={'Type a search term and click to search'}
                                        style={{pointerEvents: 'auto'}}
                                    >
                                        <SearchIcon fontSize={'small'}/>
                                    </IconButton>

                                </InputAdornment>
                            }
                        />
                    </div>
                    {!mobileOrTablet && <ClearFilters/>}
                </FilterWrapper>

            </form>
        </Wrapper>
    );
};

export default Form;
