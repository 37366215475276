import {atom, selector} from 'recoil';
import Client from '../tw/client';
import {subjectSelector} from './product';


export const selectedArticle = atom<any | null>({
    key: "selectedArticleAtom",
    default: null
})

export const articlesSelector = selector<any[] | null>({
    key: 'articlesSelector',
    get: async ({get}) => {
        const subject = get(subjectSelector);
        if (!subject) {
            return [];
        }
        let codes = subject.getAssessmentCodes();
        if (subject.isTechnicals()) {
            codes = subject.getQualificationSizes();
        }
        return await Client.getFAQArticles(codes);
    }
})