import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Header from './Header';
import TableMode from './TableMode';
import Grid from './Grid';
import { fi } from '../../../../../utils/helpers';
import { useLocalStorage } from '../../../../../utils/hooks';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../../../utils/constants';
import GroupDetails from './GroupDetails';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';
import { selectedItems } from '../../../helpers';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const toasterIsDisplayed = atom<boolean>({
	key: 'toasterIsDisplayed',
	default: false,
});

const Results = () => {
	const [displayMode] = useLocalStorage('display-mode', 'grid');
	const mobileOrTablet = useMediaQuery(device.mobile);
	const {enqueueSnackbar} = useSnackbar();
	const selectedDocs = useRecoilValue(selectedItems);
	const [toasterDisplayed, setToasterDisplayed] = useRecoilState(toasterIsDisplayed);

	useEffect(() => {
		if (selectedDocs.length && !toasterDisplayed) {
			setToasterDisplayed(true);
			enqueueSnackbar('', {
				variant: 'downloadToaster', persist: true, anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'center',
				},
			});
		}
	}, [selectedDocs, toasterDisplayed]);

	return (
		<Wrapper>
			<Header/>
			<GroupDetails/>
			{fi(displayMode === 'grid' || mobileOrTablet, <Grid/>, <TableMode/>)}

		</Wrapper>
	);
};

export default Results;