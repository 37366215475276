import {CMSObject, DisplayMode} from './__CMSObject';
import {Types, UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {MediaItemFile} from './__MediaLibrary';
import {getRecoil} from '../../state/recoilNexus';
import {ContentGroup} from './ContentGroup';
import {contentLoader} from '../../components/widgets/ResourceFinder/utils';
import {references} from '../../state/state';
import {CMSFile} from './File';
import {GroupingRule} from '../../components/widgets/ResourceFinder/components/Results/utils';
import {fi} from '../../utils/helpers';

const GroupingTags: string[] = ['#group_by_group', '#group_by_sy_asc', '#group_by_sy_desc'];

export class ContentCategory extends CMSObject {
	public name: string;
	public description: string;
	public content_groups: UUID[];

	constructor(object: any = {}) {
		super(object);

		this.name = Strings.default(object.name);
		this.description = Strings.default(object.description);
		this.content_groups = Lists.default(object.content_groups);
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}

	public contentGroups(): ContentGroup[] {
		const contentGr = getRecoil(contentLoader(Types.CONTENT_GROUP)) as ContentGroup[] || [];
		let contentGroups: ContentGroup[] = [];
		this.content_groups.forEach(cg => {
			let group = contentGr.find(c => c.getId() === cg);
			if (group) {
				contentGroups.push(group);
			}
		});
		return contentGroups;
	}

	// how many documents are mapped against the content types within all content groups that are configured in this content category
	public documentCount(): number {
		const docs = this.documents();
		return docs.length;
	}

	// retrieves all items as LibraryItem objects mapped against the content types within all content groups that are configured in this content category
	public documents(): MediaItemFile[] {
		const groups = this.contentGroups();
		const list = Array.from(new Set(groups.map(g => g.contentTypes()).flat())).filter(a => a);
		return list.map(l => l.documents()).flat() as any as MediaItemFile[];
	}

	public defaultGroupingRule(): GroupingRule {
		const groupingRule = this.getTags()
			.filter(t => GroupingTags.includes(t))
			.map(t => {
				const splitted = Lists.default(t.split('_'))
				const type = Lists.default(t.split('_'))[2];
				if (type === 'group') {
					return GroupingRule.ContentGroup;
				} else if (type === 'sy') {
					let order = splitted[3];
					return fi(order === 'asc', GroupingRule.SeriesAndYearAsc, GroupingRule.SeriesAndYearDesc);
				}
				return GroupingRule.None;
			})[0];
		return groupingRule ? groupingRule : GroupingRule.None;
	}

	// retrieves total size of all documents mapped against the content types within this content group
	public documentSize(): number {
		const docs = this.documents();
		let size = 0;
		docs.forEach(doc => {
			const file = getRecoil(references(doc.file)) as any as CMSFile;
			if (file) {
				size += file.size;
			}
		});
		return size;
	}

}