import LogoOCR from '../assets/images/ocr.svg';
import LogoCI from '../assets/images/international.svg';
import LogoDefault from '../assets/images/logo.png';


// error routes
export const ROUTE_NOT_FOUND = '/error-not-found';
export const ROUTE_UNAUTHORIZED = '/error-unauthorized';
export const ROUTE_SERVER_ERROR = '/error-unknown';

//registration & account
export const ROUTE_REGISTRATION = '/registration';
export const ROUTE_PREFERENCES = '/preferences';
export const ROUTE_COMING_SOON = '/coming-soon';
export const ROUTE_MY_ACCOUNT = '/my-account';
export const ROUTE_LANDING = '/landing';

// trial access routes
export const ROUTE_TRIAL_SURVEY = '/trial-survey';
export const ROUTE_SIGNUP_TRIAL_ACCESS = '/trial-access-signup';

// details pages
export const ROUTE_NEW_UPDATED = '/newAndUpdated';
export const ROUTE_FAVOURITES = '/favourites';
export const ROUTE_KEY_DOCS = '/keyDocs';

export const ROUTE_IP = '/ip-notice/teaching-resources';

export const ROUTE_RESOURCE_FINDER = '/resource_finder';

export const ACCOUNT_LABEL = 'Account & settings';


// Setting keys and defaults
export const AUTH_TOKEN = 'token';
export const PREVIEW_TOKEN = 'preview';
export const SELECTED_SUBJECT = 'subject';
export const SELECTED_UNIT = 'unit';
export const USER_TOUR = 'user_tour';

export const SHARED_ITEM = 'sharedItem'

export const SIGN_UP_URL = 'https://ocr.org.uk/qualifications/email-updates/';

export const device = {
	mobile: `(max-width: 767px)`,
	tablet: `(max-width: 1024px)`,
	ultraWide: `(min-width: 1981px)`,
};

export const TRIAL_ACCESS_FLAG = 'TrialAccess';
export const RESOURCE_FINDER_FLAG = 'ResourceFinder';
export const BUSINESS_STREAM_FLAG = 'BusinessStreams';

export const OCR_STREAM = 'OCR';
export const INTERNATIONAL_STREAM = 'International';
export const ALL_STREAMS = 'CSD';

export const AVAILABLE_STREAMS = [OCR_STREAM, INTERNATIONAL_STREAM]
export const CURRENCY_SYMBOLS = {
	EUR: {position: 'right', symbol: '€'},
	GBP: {position: 'left', symbol: '£'},
	USD: {position: 'left', symbol: '$'},
};
export const showTrialWelcome = 'showTrialWelcome';

export const YesOrNoOptions = [
	{label: 'Yes', value: 'Yes'},
	{label: 'No', value: 'No'},
];

/** cookies **/
export const COOKIE_NAME = 'AcceptedCookies';
export const COOKIE_VERSION = 'v1';
export const FEEDBACK_COOKIE_NAME = "Feedback";
export const FEEDBACK_COOKIE_VERSION = "v1";

export const BusinessStreams = [
	{
		id: 'default',
		logo: LogoDefault,
		name: 'Cambridge Assessment'
	},
	{
		id: OCR_STREAM,
		logo: LogoOCR,
		name: 'Teach Cambridge'
	},
	{
		id: INTERNATIONAL_STREAM,
		logo: LogoCI,
		name: 'School Support Hub'
	}
]