import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {eventsCodesAtom, eventsSelector, eventsTypeAtom, selectedEvent} from '../../../state/events';
import {Browser} from '../../../utils/browser';
import {ROUTES} from '../../../utils/routes';
import DetailsPage from '../commons/DetailsPage';
import {setRecoil} from '../../../state/recoilNexus';
import {selectedObject} from '../../../state/state';
import {subjectSelector} from '../../../state/product';
import DetailsContent from './components/DetailsContent';
import DetailsFooter from './components/DetailsFooter';
import EventHeading from './components/EventHeading';
import {useMenu} from '../../../state/menu';
import {redirectFromDetailsPage} from '../helpers';
import {HomepageWidgets} from '../widget';
import {WidgetType} from '../../../tw/types';

const EventDetails = () => {
	const events = useRecoilValue(eventsSelector);
	const [event, setEvent] = useRecoilState(selectedEvent);
	const selectedSubject = useRecoilValue(subjectSelector);
	const [eventsCodes, setEventsCodes] = useRecoilState(eventsCodesAtom);
	const [eventsType, setEventsType] = useRecoilState(eventsTypeAtom);
	const {setPage, state: {selectedPage, subTree}} = useMenu();

	const {pageId, pageSlug, id} = Browser.getParams(ROUTES);

	// if the event is ongoing -> show info message
	const [showInfo, setShowInfo] = useState<boolean>(false);

	useEffect(() => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		if (!event) return;
		let eventDate = new Date(event.startDate);
		eventDate.setHours(0, 0, 0, 0);
		if (today.getTime() === eventDate.getTime()) {
			setShowInfo(true);
		} else {
			setShowInfo(false);
		}
	}, [event]);

	useEffect(() => {
		if (!selectedPage) {
			return;
		}
		const eventsWidget = selectedPage.object.config.find(c => c.type === WidgetType.Events);
		if (!eventsWidget) {
			return;
		}
		setEventsType(eventsWidget.eventType);
	}, [selectedPage]);

	useEffect(() => {
		if (!selectedSubject) return;
		setEventsCodes(selectedSubject.getAssessmentCodes());
	}, [selectedSubject]);

	useEffect(() => {
		if (!events || (events && !events.length) || !eventsType || !eventsCodes) return;
		let selectedEv = events.find(s => s.code === id);
		if (selectedEv) {
			if (!event && id) {
				setEvent(selectedEv);
				setRecoil(selectedObject, selectedEv);
			}
		} else {
			Browser.navigate(`/${pageId}/${pageSlug}${document.location.search}`);
		}
	}, [id, events, eventsCodes, eventsType]);

	if (!event) return null;

	const goBack = () => {
		redirectFromDetailsPage(HomepageWidgets.Events, subTree, setPage, selectedPage);
	};

	return (
		<DetailsPage heading={<EventHeading event={event} showInfo={showInfo}/>}
					 content={<DetailsContent event={event}/>}
					 footer={<DetailsFooter event={event} showInfo={showInfo} goBack={goBack}/>}/>
	);
};

export default EventDetails;
