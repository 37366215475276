import React, {useMemo, useState} from 'react';
import {FeedbackConfig} from '../config';
import {useRecoilValue} from 'recoil';
import {feedbackData, feedbackStepAtom} from '../../../state/feedback';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import RadioButtonsGroup from '../../FormComponents/Radio/RadioButtonsGroup';
import {Lists} from '../../../utils/lists';
import styled from '@emotion/styled';
import {sessionAtom} from '../../../state/session';

const FourthElem = styled.div`
    span {
        text-align: center;
    }

    .MuiFormControl-root {
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        align-items: center;
    }
`;
//4th step in feedback form
const FourthStep = () => {
	const [localValue, setLocalValue] = useState<string>('');
	const feedbackStep = useRecoilValue(feedbackStepAtom);
	const storedValue = getRecoil(feedbackData(feedbackStep));
	const session = getRecoil(sessionAtom);

	const onChange = (e) => {
		setLocalValue(e);
		setRecoil(feedbackData(feedbackStep), e);
	};

	const value = useMemo(() => {
		if (localValue) return localValue;
		return storedValue;
	}, [feedbackStep, storedValue, localValue]);

	return (
		<FourthElem>
			<span
				className="text-16 text-semiBold mb-16 flex-column-center align-center">{FeedbackConfig(session?.selectedBusinessStream)[feedbackStep].question}</span>
			<RadioButtonsGroup
				field={{
					options: Lists.default<any>(FeedbackConfig()[feedbackStep].valuesOption),
					name: 'feedbackContact',
					label: '',
				}}
				onChange={onChange} value={value} styling="flex-row-center"/>
		</FourthElem>
	);
};

export default FourthStep;
