import React, {useContext, useEffect, useMemo} from 'react';
import {WidgetContext} from '../widget';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {eventsCodesAtom, eventsSelector, EventsType, eventsTypeAtom} from '../../../state/events';
import WidgetHeader from '../commons/WidgetHeader';
import {HomepageMessages} from './messages';
import {fi} from '../../../utils/helpers';
import Group from '../../Card/Group';
import NoContentComponent from './NoContentComponent';
import {viewAll} from './utils';
import ViewAllBtn from '../commons/ViewAllBtn';
import styled from '@emotion/styled';
import {subjectSelector} from '../../../state/product';
import {Lists} from '../../../utils/lists';
import {shouldHideButton} from './Homepage';

const NoContentElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
`;

const HomepageEvents = () => {
	const setEventsCodes = useSetRecoilState(eventsCodesAtom);
	const context = useContext(WidgetContext);
	const events = useRecoilValue(eventsSelector);
	const setEventsType = useSetRecoilState(eventsTypeAtom);
	const selectedSubject = useRecoilValue(subjectSelector);

	useEffect(() => {
		setEventsType(EventsType.Active);
	}, []);

	useEffect(() => {
		if (!selectedSubject) return;
		setEventsCodes(selectedSubject.getAssessmentCodes());
	}, [selectedSubject]);

	const sortedEvents = useMemo(() => {
		if (!events) return [];
		const copy = [...events];
		return Lists.sort(copy, 'startDate').slice(0, context.widget.numberOfItems);
	}, [events]);

	const viewAllItems = () => {
		viewAll(context.widget)
	}
	if (!events) return null;

	return (
		<div data-testid="events-widget">
			<WidgetHeader>
				<h2>{HomepageMessages.EventsWidgetLabel}</h2>
			</WidgetHeader>
			{fi(events.length, <Group items={sortedEvents}/>,
				<NoContentElem>
					<NoContentComponent message={HomepageMessages.NoEvents} extra={true}/>
				</NoContentElem>)}
			{fi(shouldHideButton(context.widget), null, <div data-testid="online-course-view-all" className="flex-row-end mr-16">
				<ViewAllBtn viewAll={viewAllItems} noIcon={true} nrOfItems={events.length}/>
			</div>)}
		</div>
	);
};

export default HomepageEvents;