import { CMSObject, DisplayMode } from './__CMSObject';
import { Strings } from '../../utils/strings';
import { fi } from '../../utils/helpers';
import { getRecoil } from '../../state/recoilNexus';
import { ContentTyped, LibraryItem } from './__MediaLibrary';
import {filteredDocumentsSelector} from "../../components/widgets/ResourceFinder/utils";
import {ISelectValue} from "../../components/FormComponents/Select/SelectComponent";

export class ContentType extends CMSObject {
	public name: string;
	public description: string;
	public rateable_content: boolean;

	constructor(obj: any = {}) {
		super(obj);

		this.name = Strings.default(obj.name);
		this.description = Strings.default(obj.description);
		this.rateable_content = fi(typeof obj.rateable_content === 'undefined', true, Boolean(obj.rateable_content));
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}

	public isRateable(): boolean {
		return this.rateable_content;
	}

	public isPriority(): boolean {
		return fi(this.getTags().find(tag => tag === '#priority'), true, false);
	}

	// retrieves first occurrence of a #
	public getNameTag(): string {
		const parts = this.name.split(' ');
		return Strings.default(parts.find(part => part.includes('#')));
	}

	// how many documents are mapped against this content type
	public documentCount(): number {
		const docs = this.documents();
		return docs.length;
	}

	// retrieves all items as LibraryItem objects mapped against this content type
	public documents(): LibraryItem[] {
		const filteredItems = getRecoil(filteredDocumentsSelector);
		if (typeof filteredItems['then'] === 'function') {
			return []
		}
		return filteredItems.filter((item: CMSObject) => (item as any as ContentTyped).content_type === this.getId()) as any as LibraryItem[];
	}

	public asOption(): ISelectValue {
		const tmp = super.asOption()
		tmp.priority = this.isPriority();
		return tmp;
	}
}