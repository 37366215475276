import React, {useMemo} from 'react';
import IconButton from '@mui/material/IconButton';
import {useRecoilState} from 'recoil';
import {fi} from '../../../../../utils/helpers';
import {ReactComponent as AscendingIcon} from '../../../../../assets/images/ascending.svg';
import {ReactComponent as DescendingIcon} from '../../../../../assets/images/descending.svg';
import styled from '@emotion/styled';
import {device} from '../../../../../utils/constants';
import {resourceFinderFilterSelector} from '../../state';

const IconBtn = styled(IconButton)`
    &.Mui-disabled {
        pointer-events: auto;
    }

    @media ${device.mobile} {
        margin-top: 20px;
        margin-left: 8px;
    }
`;

const SortingButton = () => {
	const [filter, setFilter] = useRecoilState(resourceFinderFilterSelector);

	const disableBtn = useMemo(() => {
		return filter.sort === 'default';
	}, [filter.sort]);

	const setSorting = () => {
		setFilter((val) => ({...val, order: fi(filter.order === 'asc', 'desc', 'asc')}));
	};

	return (
		<IconBtn onClick={setSorting} data-testid="sorting-icon-btn" disabled={disableBtn}>
			{fi(filter.order === 'asc',
				<AscendingIcon
					title={fi(disableBtn,'Asc/desc options cannot be used when items are sorted by default.', 'Switch to descending order')}
					data-testid="ascending-order"/>,
				<DescendingIcon
					title={fi(disableBtn,'Asc/desc options cannot be used when items are sorted by default.', 'Switch to ascending order')}
					data-testid="descending-order"/>,
			)}
		</IconBtn>
	);
};

export default SortingButton;