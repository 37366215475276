import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import React, { useEffect, useMemo } from 'react';
import { fi } from '../../../utils/helpers';
import { Strings } from '../../../utils/strings';
import InfoElement from '../../commons/InfoElement';

const InputFieldElement = styled(Input)`
    padding: 0;
    margin-top: 0 !important;

    &:after {
        display: ${(props: any) => fi(typeof props['data-max'] === 'undefined', 'none', 'block')};
        position: absolute;
        content: attr(data-length) "/" attr(data-max);
        font-size: 14px;
        color: var(--color-border);
        right: 4px;
    }

    input {
        padding: ${(props: any) => fi(typeof props['data-max'] === 'undefined', '8px', '8px 70px 8px 8px')};
    }

    textarea {
        padding: 8px;
    }
`;

const InputField = (props: any) => {
	const [length, setLength] = React.useState(0);

	useEffect(() => {
		setLength(Strings.default(props.value).length);
	}, [props.value]);

	const onChange = (e) => {
		if (props.max && props.max < e.target.value.length) {
			e.preventDefault();
			return;
		}

		setLength(e.target.value.length);
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const forwardProps = useMemo(() => {
		const obj = {...props};
		if (obj.ariaLabel) {
			obj['aria-label'] = obj.ariaLabel;
		}
		if (obj.dataTestId) {
			obj['data-testid'] = obj.dataTestId;
		}

		['showError', 'isTextArea', 'errorMessage', 'touched', 'ariaLabel', 'dataTestId', 'helpText'].forEach(key => {
			delete (obj[key]);
		});

		return obj;
	}, [props]);

	return (
		<FormControl variant="standard" focused={false}>
			<div className="flex-row-space-between">
				{props.field.label &&
                    <FormLabel htmlFor={props.field.name} required={props.required}>
						{props.field.label}
                    </FormLabel>
				}
				{fi(props.helpText, <InfoElement helpText={props.helpText}/>)}
			</div>
			<InputFieldElement
				{...forwardProps}
				error={props.showError}
				onChange={onChange}
				data-length={length}
				data-max={props.max}
				data-min={props.min}
				multiline={props.isTextArea}
				rows={4}
				data-testid={props.id}
			/>
			{fi(props.showError, <FormHelperText error={props.showError}
												 data-testid="error-message">{props.errorMessage}</FormHelperText>)}
		</FormControl>
	);
};

export default InputField;
