import React, {useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {feedbackData, feedbackStepAtom} from '../../../state/feedback';
import {Strings} from '../../../utils/strings';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import {FeedbackConfig} from '../config';
import {Lists} from '../../../utils/lists';
import styled from '@emotion/styled';
import FeedbackRadioGroup from '../../commons/FeedbackRadioGroup';
import {sessionAtom} from '../../../state/session';

const GenericRadioElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const RadioStep = () => {
	const [localValue, setLocalValue] = useState<string>('');
	const feedbackStep = useRecoilValue(feedbackStepAtom);
	const feedbackDataValues = useRecoilValue(feedbackData(feedbackStep))
	const session = getRecoil(sessionAtom);

	const onChange = (e) => {
		setLocalValue(Strings.default(e.target.value).toString());
		setRecoil(feedbackData(feedbackStep), Strings.default(e.target.value).toString());
	};

	const value = useMemo(() => {
		return localValue !== '' ? localValue : getRecoil(feedbackData(feedbackStep)) as string;
	}, [feedbackDataValues, feedbackStep, localValue]);

	return (
		<GenericRadioElem>
			<span className='text-16 text-semiBold mb-16'>{FeedbackConfig(session?.selectedBusinessStream)[feedbackStep].question}</span>
			<FeedbackRadioGroup formName={'resourcesFeedback'}
								labels={Lists.default<string>(FeedbackConfig()[feedbackStep].sliderLabels)}
								onChange={onChange}
								options={Lists.default<string>(FeedbackConfig()[feedbackStep].valuesOption)}
								value={value} />
		</GenericRadioElem>
	);
};

export default RadioStep;
