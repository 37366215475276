import React from 'react';
import TreeItem from './TreeItem';
import styled from '@emotion/styled';
import {useMenu} from '../../state/menu';
import {ITreeItem} from './utils';
import {walkTreeUp} from '../../utils/helpers';
import {Objects} from '../../utils/objects';
import {device} from '../../utils/constants';

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--color-background);
    width: 100%;
    padding: 8px;

    & > div > div > div > div > p {
        font-family: var(--font-semi-bold);
    }

    @media ${device.tablet} {
        background: var(--color-white);
    }
`;

const TreeItemElem = styled('div')<{selected: boolean, opened: boolean}>`
    ${props => props.selected && `
		border: solid 1px var(--color-blue);
        border-radius: 6px;
    `};
    ${props => props.opened && `
		border: solid 1px var(--color-border-light);
        border-radius: 6px;
    `}
`;

const RootPageTitle = styled.div`
    font-size: 14px;
    color: var(--color-black);
    padding: 16px 8px;
    font-family: var(--font-semi-bold);
`;

const TreeComponent = () => {
	const {state: {subTree, rootPage, selectedPage}} = useMenu();

	const showBorder = (item) => {
		if (!selectedPage) return false;
		let parents: ITreeItem[] = [];
		walkTreeUp(selectedPage as ITreeItem, (node) => {
			parents.push(node);
		});
		return Objects.default(parents[parents.length - 2]).id === Objects.default(item).id;
	};

	return (
		<ListWrapper data-depth={0} data-parentid={null} data-testid="tree-component-wrapper">
			<RootPageTitle>
				{rootPage?.object.title}
			</RootPageTitle>
			{subTree.map((item) => {
					return <TreeItemElem selected={showBorder(item)} opened={(item.opened && !showBorder(item)) || false}
										 key={item.id}>
						<TreeItem item={item} depth={0}/>
					</TreeItemElem>;
				},
			)}
		</ListWrapper>
	);
};

export default TreeComponent;