import React, { useMemo } from 'react';
import { Button, FormControlLabel, useMediaQuery } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { registrationDataAtom } from '../../pages/Registration';
import { sessionAtom } from '../../state/session';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import { references } from '../../state/state';
import { Subject } from '../../tw/models/Subject';
import { fi } from '../../utils/helpers';
import styled from '@emotion/styled';
import { disableActionButtons, hideTeachingSection, saveUserPreferences } from '../Registration/utils';
import { ISubject, selectedSubjectAtom } from './utils';
import { ConfirmationModalProps, modalAtom } from '../ModalDialogs/ModalDialog';
import { Objects } from '../../utils/objects';
import { Lists } from '../../utils/lists';
import RadioButton from '../FormComponents/Radio/RadioButton';
import { Messages } from '../../utils/messages';
import { device } from '../../utils/constants';
import IconButton from '@mui/material/IconButton';
import { Browser } from '../../utils/browser';
import { subjectSelector } from '../../state/product';
import { useMenu } from '../../state/menu';
import { Page } from '../../tw/models/Page';

const SubjectRowElem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;

    label {
        display: inline-block;
        font-family: var(--font-semi-bold);
        min-width: 110px;
        margin-right: 32px;
        max-width: 190px;
    }

    span {
        flex: 1;
        color: var(--color-monochrome);
        word-break: break-word;
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;
    }
`;

const BtnElem = styled(Button)`
    padding: 6px 8px;
    margin: 0;

    &:first-of-type {
        margin-right: 8px;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .mobile-buttons {
        button {
            &:first-of-type {
                margin-left: 8px;
            }

            margin-right: 16px;
        }
    }
`;
const SubjectPreview = ({qualification, hideActions, defaultSubject, redirectPageId}: {
	qualification: ISubject,
	hideActions?: boolean,
	defaultSubject: string,
	redirectPageId?: string
}) => {
	const setSelectedSubject = useSetRecoilState(selectedSubjectAtom);
	const setValue = useSetRecoilState(modalAtom);
	const userData = useRecoilValue(registrationDataAtom('qualifications'));
	const userSession = useRecoilValue(sessionAtom);
	const mobile = useMediaQuery(device.mobile);
	const setSubject = useSetRecoilState(subjectSelector);
	const {setPage} = useMenu();

	const redirectUrl = useMemo(() => {
		if (!redirectPageId) {
			return '';
		}
		const page = getRecoil(references(redirectPageId)) as Page;
		if (page) {
			return page.getURL();
		}
		return '/';
	}, [redirectPageId]);

	const userPreferences = useMemo(() => {
		if (!userSession) return [];
		//is not first time user
		if (!userSession.isFirstTimeUser() && !userSession.shouldUpdatePreferences()) {
			return userSession.getQualifications();
		}
		return userData;
	}, [userData, userSession]);
	if (!userSession) return null;
	const editForm = () => {
		setSelectedSubject(qualification);
	};

	const removeSubject = () => {
		let newQualifications = userPreferences.filter(p => p.subject !== qualification.subject);
		setRecoil(registrationDataAtom('qualifications'), newQualifications);
		if (!userSession.isFirstTimeUser() && !userSession.shouldUpdatePreferences()) {
			//call savePreferences
			const qualificationToRemove = Objects.default(getRecoil(references(qualification.qualification))).displayLabel();
			const subjectToRemove = Objects.default(getRecoil(references(qualification.subject))).displayLabel();
			let confirmationModalObject: ConfirmationModalProps = {
				title: `Remove subject`,
				message:
					<p>Are you sure you want to remove <strong>{qualificationToRemove}: {subjectToRemove}? </strong>
						{fi(defaultSubject === qualification.subject, ' This will also reset your default subject selection.')}
					</p>,
				onConfirm: async () => {
					// removing a subject that is marked as default - updated preferences first (tour & defaultSubject) and after that qualifications
					if (defaultSubject === qualification.subject) {
						userSession.setDefaultSubject('').then(() => {
							saveUserPreferences().catch();
						});
					} else {
						await saveUserPreferences();
					}
				},
				hasCancel: true,
				confirmText: 'Remove',
				dangerColor: true,
			};
			setValue(confirmationModalObject);
		}
	};


	const getQualification = () => {
		let qual = getRecoil(references(qualification.qualification));
		if (qual) return qual.displayLabel();
		return '';
	};

	const onSubjectClick = (evt: any, subjectId: string) => {
		evt.preventDefault();

		setSubject(subjectId as any);
		Browser.setQueryParam({subject: subjectId, unit: 'all'});

		if (redirectPageId) {
			setPage(redirectPageId);
		}
	};

	const getSubject = () => {
		const subject = getRecoil(references(qualification.subject)) as Subject;
		if (!subject) return '';
		if (redirectUrl) {
			return <a href={`${redirectUrl}?subject=${subject.getId()}`}
					  onClick={(evt) => onSubjectClick(evt, qualification.subject)}>
				{subject.displayLabel()}
			</a>;
		}
		return <span>{subject.displayLabel()}</span>;
	};

	const isSubjectModular = () => {
		let subj = getRecoil(references(qualification.subject));
		if (subj) return (subj as Subject).isModular();
		return '';
	};

	const getUnits = () => {
		let subj = getRecoil(references(qualification.subject)) as Subject;
		if (subj) {
			if (subj.getUnits().length === subj.userUnits().length)
				return 'All';
		}
		return qualification.units.map((u) => {
			return getRecoil(references(u))?.displayLabel();
		}).join(', ');
	};

	return (
		<div className="flex-column-start mb-24 mt-16" data-testid="subject-preview-wrapper">
			<SubjectRowElem data-testid="qualification">
				<label>Qualification</label>
				<span>{getQualification()}</span>
			</SubjectRowElem>
			<SubjectRowElem data-testid="subject">
				<label>Subject</label>
				{getSubject()}
			</SubjectRowElem>
			{fi(isSubjectModular(),
				<SubjectRowElem data-testid="units">
					<label>Units</label>
					<span>{getUnits()}</span>
				</SubjectRowElem>)}
			{fi(hideTeachingSection(), null,
				<>
					<SubjectRowElem data-testid="teaching">
						<label>Started teaching</label>
						<span>{qualification.teaching ? 'Yes' : 'No'}</span>
					</SubjectRowElem>
					<SubjectRowElem data-testid="students">
						<label>No. of students</label>
						<span>{qualification.class_size || 0}</span>
					</SubjectRowElem>
				</>)}
			{fi(!hideActions,
				<ActionsWrapper className="mt-8">
					{fi(mobile,
						<div className="mobile-buttons">
							<IconButton onClick={editForm} data-testid="edit-subject-button"
										disabled={disableActionButtons()} color="primary">
								<Edit fontSize={'small'}/>
							</IconButton>
							<IconButton color="error"
										disabled={disableActionButtons() || Lists.default<any>(userPreferences).length === 1}
										data-testid="remove-subject-button"
										onClick={removeSubject}>
								<Delete fontSize={'small'}/>
							</IconButton>
						</div>,
						<>
							<BtnElem onClick={editForm}
									 startIcon={<Edit fontSize={'small'}/>}
									 data-testid="edit-subject-button"
									 disabled={disableActionButtons()}
									 size="small">
								Edit
							</BtnElem>
							<BtnElem color="error" variant="text"
									 startIcon={<Delete fontSize={'small'}/>}
									 disabled={disableActionButtons() || Lists.default<any>(userPreferences).length === 1}
									 data-testid="remove-subject-button"
									 onClick={removeSubject}
									 size="small">
								Remove
							</BtnElem>
						</>)}
					{fi(!userSession.isFirstTimeUser(),
						<FormControlLabel
							data-testid={`${qualification.subject}-test-id`}
							value="Mark as default"
							label="Mark as default"
							style={{marginLeft: '8px'}}
							title={Messages.DefaultSubjectTooltip}
							disabled={disableActionButtons()}
							control={<RadioButton value={qualification.subject}
												  data-testid="default-subject-btn"
												  checked={Boolean(defaultSubject === qualification.subject)}
												  onChange={(evt) => userSession.setDefaultSubject(evt.target.value)}/>}
						/>)}
				</ActionsWrapper>)}
		</div>
	);
};

export default SubjectPreview;