import { EventsAir } from '../../../../tw/models/EventsAir';
import React, { useMemo } from 'react';
import GA from '../../../../tw/models/GA';
import Button from '@mui/material/Button';
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import { fi } from '../../../../utils/helpers';
import { useRecoilValue } from 'recoil';
import { sessionAtom } from '../../../../state/session';
import { INTERNATIONAL_STREAM } from '../../../../utils/constants';

const DetailsFooter = ({event, showInfo, goBack}: { event: EventsAir, showInfo: boolean, goBack: () => void }) => {
	const userSession = useRecoilValue(sessionAtom)

	const bookEventDisabled = useMemo(() => {
		if (!event) return true;
		return showInfo || event.availableSeats === 0 || new Date() > new Date(event.startDate);
	}, [event]);

	const bookEvent = () => {
		GA.EventActionEvent('Book event', event.displayLabel());
		
		let url = "https://events.ocr.org.uk/ocrevents/portal?courseCode";
		if (userSession?.selectedBusinessStream === INTERNATIONAL_STREAM) {
			url = "https://events.cambridgeassessment.org.uk/CABookingPortal/planner/user/Calendar?courseCode"
		} 

		window.open(`${url}=${event.code}`, '_blank');
	};

	return (
		<>
			<Button variant="text" color="primary" startIcon={<ArrowBackIosOutlined/>} size="small"
					onClick={goBack} data-testid='go-back-btn'>
				Find more events
			</Button>
			{fi(!event.isPastEvent(), <Button variant="contained" color="primary" size="small" onClick={bookEvent}
											  disabled={bookEventDisabled} data-testid='book-event-btn'>
				Book a place
			</Button>)}
		</>
	);
};

export default DetailsFooter;