import {useHotkeys} from "react-hotkeys-hook";
import {DocumentsWrapper} from "../../../../../tw/models/DocumentsWrapper";
import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {useMediaQuery} from "@mui/material";
import {device} from "../../../../../utils/constants";
import styled from "@emotion/styled";
import TablePagination from "@mui/material/TablePagination";
import {groupingAtom, searchCurrentPage} from "./utils";

const Table = styled.table`
    width: 100%;
    margin-top: 8px;

    td.MuiTablePagination-root {
        border-bottom: none;
    }

    .MuiTablePagination-displayedRows {
        flex: 1;
        text-align: right;
    }

    .MuiTablePagination-spacer {
        display: none;
    }
`;

const CustomTablePagination = styled(TablePagination)`
    width: 100%;

    > div {
        padding: 0 15px 0 0 !important;
    }

    @media ${device.mobile} {
        padding: 0 !important;

        .MuiTablePagination-toolbar {
            display: flex;
            justify-content: space-between;
            padding: 0 !important;

            .MuiTablePagination-displayedRows {
                flex: none;
            }
        }
    }
`;

const Pagination = ({setItems, allItems}:{setItems: (DocumentsWrapper) => void, allItems: DocumentsWrapper}) => {
    const [page, setPage] = useRecoilState(searchCurrentPage);
    const grouping = useRecoilValue(groupingAtom);
    const mobileOrTablet = useMediaQuery(device.mobile);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    useEffect(() => {
        if (grouping !== 'none') {
            setRowsPerPage(-1);
            setPage(0);
        } else {
            setRowsPerPage(20);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grouping]);

    useEffect(() => {
        let limit = page * rowsPerPage + rowsPerPage;
        let slice = allItems;
        if (limit !== -1) {
            slice = allItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) as DocumentsWrapper;
        }
        setItems(slice as DocumentsWrapper);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItems, page, rowsPerPage]);

    useHotkeys('ArrowRight,PageDown,ArrowDown', (evt) => {
        evt.preventDefault();
        const newPage = page + 1;
        if (rowsPerPage * newPage < allItems.length) {
            setPage(newPage);
            const slice = allItems.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage);
            setItems(slice as DocumentsWrapper);
        }
    });

    useHotkeys('ArrowLeft,PageUp,ArrowUp', (evt) => {
        evt.preventDefault();
        const newPage = page - 1;
        if (page > 0) {
            setPage(newPage);
            const slice = allItems.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage);
            setItems(slice as DocumentsWrapper);
        }
    });

    useHotkeys('Home', (evt) => {
        evt.preventDefault();
        setPage(0);
        const slice = allItems.slice(0, rowsPerPage);
        setItems(slice as DocumentsWrapper);
    });

    useHotkeys('End', (evt) => {
        evt.preventDefault();
        const newPage = Math.floor(allItems.length / rowsPerPage);
        setPage(newPage);
        const slice = allItems.slice(newPage, newPage * rowsPerPage + rowsPerPage);
        setItems(slice as DocumentsWrapper);
    })

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        let slice: any[];
        if (newRowsPerPage === -1) {
            slice = allItems;
        } else {
            slice = allItems.slice(0, newRowsPerPage);
        }

        setItems(slice as DocumentsWrapper);
    };

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        const slice = allItems.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) as DocumentsWrapper;
        setItems(slice);
    };


    return (
        <Table style={{width: '100%'}}>
            <tbody>
            <tr>
                <CustomTablePagination
                    showFirstButton={true}
                    showLastButton={true}
                    rowsPerPageOptions={!mobileOrTablet ? [20, 40, {label: 'All', value: -1}] : []}
                    count={allItems.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                    SelectProps={{
                        disabled: grouping !== 'none',
                    }}
                    labelRowsPerPage={'Results per page:'}/>
            </tr>
            </tbody>
        </Table>
    )
}

export default Pagination