import React, {useState} from 'react';
import {CMSObject} from '../../../tw/models/__CMSObject';
import {fi} from '../../../utils/helpers';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import {Messages} from '../../../utils/messages';
import Tooltip from '@mui/material/Tooltip';

const CopyLink = ({item, disabled}: { item: CMSObject, disabled: boolean }) => {
	const [copied, setCopied] = useState<boolean>(false);

	const copyToClipboard = (evt) => {
		if (disabled) return;
		evt.stopPropagation();
		evt.preventDefault();
		const itemURL = `${window.location.origin}/item/${item.getId()}`;
		navigator.clipboard.writeText(itemURL);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	return (
		<Tooltip data-testid={'copy-to-clipboard-tooltip'} title={fi(disabled, Messages.FeatureNotAvailable, fi(copied, 'Copied!', 'Click to copy link'))}>
			<div data-testid={'copy-to-clipboard-btn'} onClick={copyToClipboard} className={`${disabled ? 'low-opacity' : ''}`} role="button">
				<FileCopyOutlined/>
			</div>
		</Tooltip>
	);

};
export default CopyLink;