import {Lists} from '../../../utils/lists';
import {
	components as simpleSelectComponents,
	components as windowedComponents,
} from 'react-select';
import React from 'react';
import {ISelectValue} from './SelectComponent';

const MultiValueLabel = props => {
	let tmp = JSON.parse(JSON.stringify(props));
	let selectedValues = tmp.selectProps.value;
	let lastValue = selectedValues[selectedValues.length - 1];
	if (selectedValues.length > 1 && tmp.data.label !== lastValue.label) {
		tmp.children = tmp.children + ',';
	}
	const hasOptions = Lists.default<ISelectValue>(props.options).find(i => !!i.options);
	if (hasOptions) {
		return <windowedComponents.MultiValueLabel {...tmp} />;
	}
	return <simpleSelectComponents.MultiValueLabel {...tmp} />;
};

export default MultiValueLabel;