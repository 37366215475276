import React, {useMemo} from "react";
import {useRecoilValue} from "recoil";
import {subjectSelector} from "../../../state/product";
import {Messages} from "../../../utils/messages";
import styled from '@emotion/styled';
import { device } from '../../../utils/constants';

const Wrapper = styled.div`
    @media ${device.tablet} {
        text-align: center;
    }
`

const NoContentComponent = (props) => {
    const subject = useRecoilValue(subjectSelector)

    const qualificationAndSubject = useMemo(() => {
        if (!subject) return ""
        return subject.getQualificationAndSubject()
    }, [subject])

    return (
        <Wrapper className="flex-column-center align-center text-16 pall-24">
            <div>{props.message}</div>
            <div className="text-semiBold" data-testid="mapping">{qualificationAndSubject}</div>
            <br/>
            {props.extra && <div> {Messages.CheckAgainLater}</div>}
        </Wrapper>
    )
}

export default NoContentComponent;