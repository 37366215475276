import React, {useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useMenu } from '../state/menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITreeItem } from '../components/TreeMenu/utils';
import { fi } from '../utils/helpers';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useRecoilValue } from 'recoil';
import { enableSubjectFilter } from '../state/product';
import { Objects } from '../utils/objects';
import { WidgetType } from '../tw/types';

const Wrapper = styled.div<any>`
    margin: 0 16px;
    background-color: var(--color-white);
    border: 1px var(--color-border-light);
    border-style: none solid solid solid;
    padding: 16px 24px 0;
    box-shadow: 0 5px 2px -1px var(--color-box-shadow);
    z-index: 1;
    position: relative;
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    grid-area: nav;
`;

const MenuWrapper = styled.ul<any>`
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    min-width: 0;
`;

const MenuItem = styled.li`
    padding: 16px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    border-bottom: 4px solid var(--color-white);
    color: var(--color-grey-text);
    font-family: var(--font-semi-bold), sans-serif;

		[data-testid='collapse-icon'] {
			transition: all 250ms ease;
			transform: rotate(-90deg);
		}

    &:hover {
      background: var(--color-background);
      border-bottom-color: var(--color-background);

			:not(.closed) [data-testid='collapse-icon'] {
				transform: rotate(0deg);
			}

			[data-testid='submenu'] {
				display: flex;
				opacity: 1;
				translate: 0 0;
				transition-delay: 100ms;
				
				@starting-style {
					opacity: 0;
				}
			}

			&.closed {
				[data-testid='submenu'] {
					display: none;
					opacity: 0;
				}
			}
		}

    [data-testid='tree-item--page-title'] {
        white-space: nowrap;
    }

    &.selected {
        border-top: none;
        border-bottom-color: var(--color-blue);

        [data-testid='tree-item--page-icon'] {
            img, svg {
                filter: invert(15%) sepia(41%) saturate(1368%) hue-rotate(178deg) brightness(86%) contrast(90%);
            }
        }

        [data-testid='tree-item--page-title'] {
            color: var(--color-blue);
            white-space: nowrap;
        }
    }
`;

const PageIcon = styled.div`
    svg {
        width: 24px;
        height: 24px;
    }
`;

const Title = styled.div`
    margin: 0 8px;
`;

const Submenu = styled.div`
    display: none;
		opacity: 0;
		transition-duration: 250ms;
		transition-behavior: allow-discrete;
		background: var(--color-lighter-blue-background);
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    right: 0;
    padding: 24px;
    box-shadow: 0px 12px 20px 0px var(--color-box-shadow);
		border: 1px solid var(--color-blue-border);
		border-radius: 6px;
    justify-content: center;
    align-content: flex-start;
    flex-direction: row;
    align-items: stretch;
`;

const SubmenuColumn = styled.div`
    cursor: default;
    border-right: 1px solid var(--color-border-light);
    padding: 16px;

    &:last-of-type {
        border-right: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .selected {
        color: var(--color-blue);
    }

    li {
        padding: 8px 16px;
				width: 100%;
        font-weight: bold;
        white-space: normal;

        span {
            cursor: pointer;

            &:hover {
                color: var(--color-blue);
								text-decoration: underline;
            }
        }

        > ul:not(:empty) {
            padding-top: 8px;
        }

        li {
            font-weight: normal;
            padding-left: 12px;
            font-family: var(--font-regular), sans-serif;
            white-space: normal;
        }
    }
`;

const TabItem = ({ item }: { item: ITreeItem }) => {
	const { setPage } = useMenu();
	const hasChildren = item.children.length > 0;
	const [megamenuState, setMegamenuState] = useState("closed");
	const [columns, setColumns] = useState<ITreeItem[][]>([[], [], []]);

	useEffect(() => {
		if (!item.children.length) return;
		const cols: ITreeItem[][] = [[], [], []];
		item.children.forEach((child, idx) => {
			cols[idx % 3].push(child);
		});
		setColumns(cols);
	}, [item]);

	const handleClick = (evt: any, treeItem: ITreeItem) => {
		evt.preventDefault();
		evt.stopPropagation();

		if (evt.type === "click") {
			setMegamenuState(fi(megamenuState === "closed", "", "closed"))
		} 

		//Megamenu only. Secondary level behavior
		if (treeItem.level === 2 && treeItem.object.hasWidget(WidgetType.ChildPages)) {
			setPage(treeItem.children[0], true);
			return;
		}

		if (treeItem.object.isLinkPage()) {
			window.open(treeItem.object.link, '_blank');
			return;
		}

		setPage(treeItem, true);
	};

	const handleMouseEnter = () => {
		setMegamenuState("");
	}

	const drawColumnItem = (item: ITreeItem, idx: number) => {
		const hasWidgets = item.object.config.length > 0 || item.object.isLinkPage();
		return (
			<li key={idx}>
				{fi(hasWidgets, <span className={fi(item.selected, 'selected', '')}
					onClick={(evt) => handleClick(evt, item)} role='button'>{item.object.displayLabel()}</span>,
					<strong className={fi(item.selected, 'selected', '')}>{item.object.displayLabel()}</strong>)}
				{hasChildren && (
					<ul>
						{item.children.map(drawColumnItem)}
					</ul>
				)}
			</li>
		);
	};

	const drawColumn = (column: ITreeItem[], idx: number) => {
		if (column.length === 0) return null;

		return (
			<SubmenuColumn key={idx}>
				<ul>
					{column.map(drawColumnItem)}
				</ul>
			</SubmenuColumn>
		);
	};

	return (
		<MenuItem className={item.selected || item.opened ? `selected ${hasChildren ? megamenuState : ''}` : hasChildren ? megamenuState: ''} data-testid={`tree-item--root-page--${Objects.default(item.object).displayLabel().toLowerCase().trim().replace(/\s+/g, "-")}`}
			onClick={(evt) => handleClick(evt, item)}
			onMouseEnter={handleMouseEnter}
		>
			<PageIcon style={{userSelect: 'none'}} data-testid="tree-item--page-icon">
				{item.object.getPageIcon()}
			</PageIcon>
			<Title style={{userSelect: 'none'}} data-testid="tree-item--page-title">
				{item.object.displayLabel()}
			</Title>
			{hasChildren && <ExpandMoreIcon data-testid="collapse-icon" />}
			{hasChildren && <Submenu data-testid="submenu">
				{columns.map(drawColumn)}
			</Submenu>}
		</MenuItem>
	);
};

const RightArrow = styled.div`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 16px;
    bottom: 0;
    width: 32px;
    display: flex;
    align-items: center;
`;

const LeftArrow = styled.div`
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 16px;
    bottom: 0;
    width: 32px;
    display: flex;
    align-items: center;
`;

const RightGradient = styled.div`
    position: absolute;
    right: 24px;
    top: 16px;
    bottom: 0;
    width: 80px;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;

const LeftGradient = styled.div`
    position: absolute;
    left: 24px;
    top: 16px;
    bottom: 0;
    width: 80px;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(0, 0, 0, 0) 100%);
`;

const ScrollWrapper = styled.div`
    scrolbar-width: none;

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

const HMenu = () => {
	const { state: { subTree, rootPage, selectedPage, secondaryMenuVisible } } = useMenu();
	const [maxWidth, setMaxWidth] = useState(0);
	const [needsScroll, setNeedsScroll] = useState(false);
	const parentRef = useRef<HTMLDivElement>();
	const menuRef = useRef<HTMLUListElement>();
	const [scroll, setScroll] = useState([false, false]);
	const subjectFilterEnabled = useRecoilValue(enableSubjectFilter);

	useEffect(() => {
		let selectedElem = document.querySelector('ul .selected');
		let coords = selectedElem?.getBoundingClientRect();
		if (selectedElem && coords && (coords.x > maxWidth || coords.x < 0)) {
			selectedElem.scrollIntoView();
			measureWidth();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPage]);

	const measureWidth = () => {
		const box = parentRef.current?.getBoundingClientRect();
		if (!box) return;
		const width = box.width - 60;
		if (width !== maxWidth) {
			setMaxWidth(width);
		}

		if (!menuRef.current) return;
		const menu = menuRef.current;

		const requireScroll = menu.getBoundingClientRect().width < menu.scrollWidth;
		setNeedsScroll(requireScroll);

		let needsScrollLeft = false;
		let needsScrollRight = false;

		if (menu.parentElement!.scrollLeft > 0) {
			needsScrollLeft = true;
		}
		if (requireScroll) {
			needsScrollRight = menu.scrollWidth !== width + menu.parentElement!.scrollLeft;
		}
		setScroll([needsScrollLeft, needsScrollRight]);
	};

	useEffect(() => {
		measureWidth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxWidth, subTree, subjectFilterEnabled, secondaryMenuVisible]);

	useEffect(() => {
		window.addEventListener('resize', measureWidth);
		return () => {
			window.removeEventListener('resize', measureWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!parentRef.current) return;
		measureWidth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentRef, menuRef, rootPage]);

	useEffect(() => {
		if (!menuRef.current) return;
		measureWidth();

		const ref = menuRef.current;
		ref.parentElement!.addEventListener('scroll', measureWidth);
		return () => {
			ref.parentElement!.removeEventListener('scroll', measureWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuRef, parentRef]);

	const scrollLeft = () => {
		menuRef.current!.parentElement!.scrollTo({ left: menuRef.current!.parentElement!.scrollLeft - maxWidth / 2 });
		measureWidth();
	};

	const scrollRight = () => {
		menuRef.current!.parentElement!.scrollTo({ left: menuRef.current!.parentElement!.scrollLeft + maxWidth / 2 });
		measureWidth();
	};

	if (!subTree.length) {
		return null;
	}
	return (
		<Wrapper id="hmenu" data-testid="hmenu" ref={parentRef}>
			{(needsScroll && scroll[0]) && (
				<>
					<LeftArrow onClick={scrollLeft}>
						<KeyboardArrowLeftIcon />
					</LeftArrow>
					<LeftGradient />
				</>
			)}
			{/*<div style={{overflowX: 'clip', display: 'flow-root', width: maxWidth}} id={'parent'}>*/}
			<ScrollWrapper style={{ overflowX: 'auto', display: 'flow-root', width: maxWidth, scrollbarWidth: 'none' }} id="parent"
				data-testid="parent">
				{maxWidth > 0 && (
					<MenuWrapper ref={menuRef} id="hmenu-box" data-testid="hmenu-box">
						{subTree.map((item, index) => (<TabItem item={item} key={`${index}-item`} />))}
					</MenuWrapper>
				)}
			</ScrollWrapper>
			{(needsScroll && scroll[1]) && (
				<>
					<RightGradient />
					<RightArrow onClick={scrollRight}>
						<KeyboardArrowRightIcon />
					</RightArrow>
				</>
			)}
		</Wrapper>
	);
};

export default HMenu;