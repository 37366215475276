import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { selectedGroupAtom } from '../Form/Categories';
import { useRecoilValue } from 'recoil';
import { getRecoil } from '../../../../../state/recoilNexus';
import { references } from '../../../../../state/state';
import { ContentGroup } from '../../../../../tw/models/ContentGroup';
import { Numbers } from '../../../../../utils/numbers';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
	flex-direction: column;

    border-bottom: 1px solid var(--color-border-light);
    padding: 16px;
`;

const DetailWrapper = styled.div`
	display: flex;
	width: 100%;
	gap: 16px;
	
	font-size: 12px;
	line-height: 16px;
	font-family: var(--font-regular);
	color: var(--color-lighter-monochrome);
`

const GroupTitle = styled.div`
    font-family: var(--font-lato-regular);
	color: var(--color-black);
    font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	
`

const GroupDetails = () => {
	const selectedGroup = useRecoilValue(selectedGroupAtom);

	const group = useMemo(() => {
		return getRecoil(references(selectedGroup)) as ContentGroup;
	}, [selectedGroup]);

	if (!group) {
		return null;
	}

	return (
		<Wrapper>
			<GroupTitle>{group.displayLabel()}</GroupTitle>
			<DetailWrapper>
				<div>{group.documentCount()} Resources</div>
				<div>({Numbers.asFileSize(group.documentSize())})</div>
			</DetailWrapper>
		</Wrapper>
	);
};

export default GroupDetails;