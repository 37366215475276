import React, {useContext, useMemo, useState} from 'react';
import {UUID} from '../../../tw/types';
import {WidgetContext} from '../widget';
import {useRecoilValue} from 'recoil';
import {documentSelector} from '../../../state/documents';
import {Objects} from '../../../utils/objects';
import {Lists} from '../../../utils/lists';
import {getRecoil} from '../../../state/recoilNexus';
import {references} from '../../../state/state';
import {ContentGroup as ContentGroupCls} from '../../../tw/models/ContentGroup';
import {fi} from '../../../utils/helpers';
import styled from '@emotion/styled';
import {subjectSelector, UnitSelector} from '../../../state/product';
import {Unit} from '../../../tw/models/Subject';
import {DisplayMode} from '../../../tw/models/__CMSObject';
import {ContentType} from '../../../tw/models/ContentType';
import {groupByTag} from '../helpers';
import WidgetHeader from '../commons/WidgetHeader';
import AccordionComponent from '../commons/AccordionComponent';
import Group from '../../Card/Group';
import ViewAllBtn from '../commons/ViewAllBtn';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';


const Wrapper = styled.div`
    border: 1px solid var(--color-grey);
    border-radius: 6px;
    box-shadow: 2px 0 4px 0 var(--color-box-shadow);
`;

interface ContentGroupWidget {
	contentTypes: UUID[];
	contentGroup: UUID;
	groupBy: 'content_types' | 'units';
	sortDescending: boolean;
	showTitle: boolean;
	showDescription: boolean;
}

const ContentGroup = () => {
	const context = useContext(WidgetContext);
	const widget = context.widget as ContentGroupWidget;

	const documents = useRecoilValue(documentSelector);
	const subject = useRecoilValue(subjectSelector);
	const unit = useRecoilValue(UnitSelector);
	const contentGroupObject = getRecoil(references(widget.contentGroup)) as ContentGroupCls;

	const [expanded, setExpanded] = useState(false);

	// filter items by content types
	const filteredDocuments = useMemo(() => {
		return Lists.sort(documents.byContentType(...widget.contentTypes).byUnitList(unit.map(u => u.value)), 'title') as DocumentsWrapper;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documents, unit, context]);

	const allUnits = useMemo(() => {
		return Objects.default(Lists.default(unit)[0]).value === 'all';
	}, [unit]);

	const unitObject = useMemo(() => {
		let objs: Unit[] = [];
		if (unit.length === 1 && !allUnits) {
			unit.forEach(u => {
				objs.push(getRecoil(references(u.value)) as Unit);
			});
			return objs;
		}
		return null;
	}, [widget, unit]);

	type ContentTypeSection = {items: DocumentsWrapper, contentType?: ContentType, title: string, description?: string};

	// form the list of content to be displayed based on widget configuration
	const list = useMemo<ContentTypeSection[]>(() => {
		let result: ContentTypeSection[] = [];

		if (!filteredDocuments.length) {
			return result;
		}

		// group by content types
		if (widget.groupBy === 'content_types') {
			widget.contentTypes.forEach((contentTypeId) => {
				const contentTypeObject = Objects.default(getRecoil(references(contentTypeId)));
				const items = filteredDocuments.byContentType(contentTypeId);
				if (items.length && contentTypeObject) {
					result.push({
						contentType: contentTypeObject,
						items: items,
						title: contentTypeObject.displayLabel(),
						description: contentTypeObject.description,
					});
				}
			});

			result = Lists.sort(result, 'title');

			const newResult: ContentTypeSection[] = [];
			result.forEach(r => {
				const group = groupByTag(r.contentType as any, r.items);
				group.forEach((g: any) => {
					newResult.push({
						items: g.items,
						contentType: g.contentType,
						title: g.title,
						description: g.description,
					});
				});
			});

			result = newResult.filter(r => r.items.length > 0);

		} else if (widget.groupBy === 'units') {
			//get units or components for selected subject
			let units: any[];
			if (allUnits) {
				units = Lists.default<Unit>(subject?.userUnits());
			} else {
				units = unit; //selected units
			}
			units.forEach(u => {
				// because if a document is mapped to all it will belong to all the units we don't display all the groups
				// when the user selects only one unit
				// if (!allUnits && u.getId() !== unit) {
				// 	return;
				// }
				let unitId = allUnits ? u.getId() : u.value;
				const unitItems = filteredDocuments.byUnit(unitId);
				if (unitItems.length) {
					let unitObj = allUnits ? u : getRecoil(references(unitId));
					result.push({
						items: unitItems,
						title: unitObj.displayLabel(DisplayMode.FULL),
					});
				}
			});

			result = Lists.sort(result, 'title', Boolean(widget.sortDescending));
		}

		result.forEach(r => {
			// sort items by '#sort' tag if there is one
			r.items = r.items.sortByTag('#sort')
		})

		return result;
	}, [filteredDocuments, unit]);

	const showViewAllButton = useMemo(() => {
		if (filteredDocuments.length < 5) {
			return false;
		}
		const moreThanOneWidget = context.filteredWidgets.cardWidgets().length > 1;
		setExpanded(!moreThanOneWidget);
		return moreThanOneWidget;
	}, [filteredDocuments, context]);

	if (!filteredDocuments) {
		return null;
	}

	return (
		<>
			<WidgetHeader>
				{fi(widget.showTitle, <h2>{contentGroupObject.displayLabel()}</h2>)}
				{fi(!unitObject && widget.showDescription, <div>{contentGroupObject.description}</div>)}
			</WidgetHeader>
			{fi(unitObject,
				<>
					{list.map((l: any, idx: number) => (
						<div data-testid={`content-group-widget-${idx}`} key={l.title}>
							<WidgetHeader>
								<div>{l.title}</div>
							</WidgetHeader>
							<Group items={fi(expanded, l.items, l.items.slice(0, 4))} allItems={l.items} label={l.title} itemsOriginalLength={l.items.length}></Group>
							<ViewAllBtn viewAll={() => setExpanded(!expanded)} expanded={expanded}
										nrOfItems={l.items.length}/>

						</div>
					))}
				</>,
				<Wrapper data-testid='accordion-wrapper'>
					<AccordionComponent list={list} showViewAllButton={showViewAllButton} />
				</Wrapper>,
			)}
		</>
	);
};

export default ContentGroup;