import Button from '@mui/material/Button';
import React, {useMemo} from 'react';
import {ReactComponent as PlayIcon} from '../../../../assets/images/card/actions/play-icon.svg';
import {fi} from '../../../../utils/helpers';
import {Messages} from '../../../../utils/messages';
import {disableButton} from './utils';
import {CMSObject} from '../../../../tw/models/__CMSObject';

const VideoButton = ({item, onClick}: { item: CMSObject, onClick: () => void }) => {
	const disableAction = useMemo(() => {
		return disableButton(item);
	}, [item]);

	return (
		<span className='card-action' title={fi(disableAction, Messages.FeatureNotAvailable, 'Play')}>
			<Button data-testid='video-play-btn' color="primary" variant="text" size="large" onClick={onClick}
					disabled={disableAction}>
				<PlayIcon title="Play"/>
			</Button>
		</span>
	);
};

export default VideoButton;