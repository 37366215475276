import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import {bindPopover, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import {fi} from '../../../../../utils/helpers';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import React, {useEffect, useMemo} from 'react';
import styled from '@emotion/styled';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useMediaQuery} from '@mui/material';
import {device} from '../../../../../utils/constants';
import SelectComponent from '../../../../FormComponents/Select/SelectComponent';
import {
	contentCategoryGroupingRuleAtom,
	defaultGroupingAtom,
	groupingAtom,
	GroupingRule,
	SortingAndGroupingTypes,
} from './utils';
import {contentGroupsTagRules, resourceFinderFilterSelector} from '../../state';
import {inCategoryLevelAtom, selectedGroupOrCategoryAtom} from '../Form/Categories';
import {Objects} from '../../../../../utils/objects';
import {ContentGroup} from '../../../../../tw/models/ContentGroup';
import {ContentCategory} from '../../../../../tw/models/ContentCategory';

const BtnElem = styled(Button)`
    color: var(--color-monochrome) !important;
    padding: 0 4px;
    font-family: var(--font-regular) sans-serif !important;
    font-weight: normal;
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    button {
        display: flex;
        justify-content: flex-start;
        font-family: var(--font-regular) sans-serif !important;
        font-weight: normal;
    }
`;

const sortingOptions = [
	{label: 'Default', value: 'default', object: null}, //in this case the sorting will be done on FE side
	{label: 'Relevance', value: 'relevance', object: null},
	{label: 'Title', value: 'title', object: null},
	{label: 'Description', value: 'description', object: null},
	{label: 'Content type', value: 'content_type_name', object: null},
	{label: 'Date', value: 'modified', object: null},
];

const groupingOptions = [
	{label: 'None', value: GroupingRule.None, object: null},
	{label: 'Content type', value: GroupingRule.ContentType, object: null},
	{label: 'Exam year', value: GroupingRule.Year, object: null},
	{label: 'Series', value: GroupingRule.Series, object: null},
	{label: 'Content group', value: GroupingRule.ContentGroup, object: null},
	{label: 'Series & year', value: GroupingRule.SeriesAndYearAsc, object: null},
	{label: 'Series & year', value: GroupingRule.SeriesAndYearDesc, object: null},
];

const sortingAndGrouping = {
	[SortingAndGroupingTypes.Sorting]: {
		label: 'Sort by:',
		options: sortingOptions,
	},
	[SortingAndGroupingTypes.Grouping]: {
		label: 'Group by:',
		options: groupingOptions,
	},
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

window['sortingChanged'] = false;
window['groupingChanged'] = false;
const DropdownSelection = (props) => {
	const [filter, setFilter] = useRecoilState(resourceFinderFilterSelector);
	const [grouping, setGrouping] = useRecoilState(groupingAtom);
	const mobileOrTablet = useMediaQuery(device.mobile);
	const defaultGrouping = useRecoilValue(defaultGroupingAtom);
	const inCategoryLevel = useRecoilValue(inCategoryLevelAtom);

	const selectedGroupOrCategory = useRecoilValue(selectedGroupOrCategoryAtom);
	const popupState = usePopupState({variant: 'popover', popupId: `sort-dropdown-${props.type}`});
	const contentGroupTags = useRecoilValue(contentGroupsTagRules);
	const contentCategoryGroupingRule = useRecoilValue(contentCategoryGroupingRuleAtom);


	const checkIfDefaultShouldBeRemoved = () => {
		if (!selectedGroupOrCategory) {
			// if all resources selected, default option should not be available
			return true;
		}
		if (selectedGroupOrCategory instanceof ContentGroup) {
			return selectedGroupOrCategory.defaultSortingRules().length === 0;
		}
		return false;
	};
	const options = (() => {
		if (props.type === SortingAndGroupingTypes.Sorting) {
			let opts = sortingAndGrouping[props.type].options;
			let remove = checkIfDefaultShouldBeRemoved();
			if (remove) {
				opts = opts.filter(o => o.value !== 'default');
			}
			if (!filter.query) {
				opts = opts.filter(o => o.value !== sortingOptions[1].value);
			}
			if (grouping === GroupingRule.ContentType) {
				opts = opts.filter(o => o.value !== 'content_type_name');
			}
			return opts;
		}

		return sortingAndGrouping[props.type].options.filter((o => {
			return (
				o.value === GroupingRule.None
				|| (inCategoryLevel && contentCategoryGroupingRule === GroupingRule.ContentGroup) && o.value === GroupingRule.ContentGroup
				|| (inCategoryLevel && contentCategoryGroupingRule === GroupingRule.SeriesAndYearDesc) && o.value === GroupingRule.SeriesAndYearDesc
				|| (inCategoryLevel && contentCategoryGroupingRule === GroupingRule.SeriesAndYearAsc) && o.value === GroupingRule.SeriesAndYearAsc
				|| (filter.contentTypes.length !== 1 && o.value === GroupingRule.ContentType)
				|| (filter.years.length !== 1 && o.value === GroupingRule.Year)
				|| (filter.series.length !== 1 && o.value === GroupingRule.Series)
			);
		}));
	})();

	/* grouping section */
	useEffect(() => {
		if (props.type === SortingAndGroupingTypes.Grouping) {
			// if no group_by tags are found on selected content category, do nothing
			if (!defaultGrouping) {
				setGrouping(GroupingRule.None);
				return;
			}
			// set group_by based on tags
			setGrouping(defaultGrouping);
		}
	}, [selectedGroupOrCategory]);

	/* sorting section */
	// if content group or category selected and has sorting/grouping tags, sort by should be set to 'default'
	useEffect(() => {
		if (props.type === SortingAndGroupingTypes.Sorting) {
			if ((contentGroupTags.length || grouping === GroupingRule.SeriesAndYearAsc || grouping === GroupingRule.SeriesAndYearDesc) && window['sortingChanged'] === false) {
				const defaultSortingOption = Objects.default(options.find(o => o.value === 'default'), 'title');
				setFilter({...filter, sort: defaultSortingOption.value});
			}
			if (!selectedGroupOrCategory && filter.sort === 'default') {
				setFilter({...filter, sort: 'title'});
			}
		}
	}, [selectedGroupOrCategory, grouping]);

	useEffect(() => {
		if (props.type === SortingAndGroupingTypes.Sorting) {
			// when we have group by tags, but group by dropdown selection is different from tags -> sort by default not applicable
			if (selectedGroupOrCategory && selectedGroupOrCategory instanceof ContentCategory
				&& (selectedGroupOrCategory.defaultGroupingRule() !== grouping || selectedGroupOrCategory.defaultGroupingRule() === GroupingRule.None)
				&& window['sortingChanged'] === false) {
				setFilter({...filter, sort: 'title'});
			}
		}
	}, [selectedGroupOrCategory, grouping, defaultGrouping, filter.sort]);

	useEffect(() => {
		if (props.type === SortingAndGroupingTypes.Sorting) {
			if (!filter.query && filter.sort === 'relevance') {
				setFilter({...filter, sort: 'title'});
			}
		}
	}, [filter.query]);

	const label = useMemo(() => {
		return sortingAndGrouping[props.type].label;
	}, [props]);

	const value = useMemo(() => {
		const val = props.type === SortingAndGroupingTypes.Sorting ? filter.sort : grouping;
		const def = options.find(o => o.value === val);
		return def ? def.label : '';
	}, [props, filter.sort, grouping]);

	const refreshData = async (e: any) => {
		if (props.type === SortingAndGroupingTypes.Sorting) {
			window['sortingChanged'] = true;
			setFilter({...filter, sort: e.value});
		} else {
			window['groupingChanged'] = true;
			setGrouping(e.value);
		}
	};

	const handleChange = (e: any) => {
		popupState.close();
		refreshData(e).catch();
	};

	if (mobileOrTablet) {
		return (
			<Wrapper>
				<SelectComponent
					label={label}
					id={`${label}-selector`}
					value={props.type === SortingAndGroupingTypes.Sorting ? filter.sort : grouping}
					onChange={(s) => handleChange(s)}
					values={options}
				/>
			</Wrapper>
		);
	}

	return (
		<>
			<BtnElem size="small" {...bindTrigger(popupState)}
					 title={`Switch ${props.type} property`}
					 endIcon={fi(popupState.isOpen, <ExpandLess fontSize="small"/>, <ExpandMore
						 fontSize="small"/>)} data-testid={`${props.type}-btn`}>
				<span>{label}</span>&nbsp; {value}
			</BtnElem>
			{popupState.isOpen && <Popover {...bindPopover(popupState)}
										   anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
										   transformOrigin={{vertical: 'top', horizontal: 'right'}}
										   data-testid={`${props.type}-popover`}
			>
				<ListWrapper data-testid={`${props.type}-options`}>
					{options.map((s: any) => (
						<Button size="small" onClick={() => handleChange(s)} color="secondary"
								data-testid={`${s.label}-id`} key={s.label}>
							{s.label}
						</Button>
					))}
				</ListWrapper>
			</Popover>}
		</>
	);
};

export default DropdownSelection;