import React, {useMemo, useState} from 'react';
import styled from '@emotion/styled';
import {useRecoilValue} from 'recoil';
import NoContent from '../../../../commons/NoContent';
import {Messages} from '../../../../../utils/messages';
import {fi} from '../../../../../utils/helpers';
import {HBox} from '../../../commons/HBox';
import {groupAndSortResults, groupingAtom} from './utils';
import Group from '../../../../Card/Group';
import {VBox} from '../../../commons/VBox';
import {DocumentsWrapper} from '../../../../../tw/models/DocumentsWrapper';
import Pagination from './Pagination';
import {contentGroupsTagRules, searchResultsSelector} from '../../state';

const GroupTitle = styled(HBox)`
    padding: 16px;
    background: var(--color-background);
    font-weight: bold;
    width: 100%;

    &:empty {
        padding: 0;
        display: none;
    }
`;

const TableMode = () => {
	const searchResults = useRecoilValue(searchResultsSelector);
	const grouping = useRecoilValue(groupingAtom);
	const [items, setItems] = useState<DocumentsWrapper | null>(null);
	const contentGroupTags = useRecoilValue(contentGroupsTagRules);

	const groups = useMemo(() => {
		return groupAndSortResults(searchResults.items, grouping, contentGroupTags);
	}, [grouping, searchResults, contentGroupTags]);

	// No results for whatever reason
	if (searchResults.items.length === 0) {
		return (
			<HBox justifyContent="center" padding={24}>
				<NoContent label={fi(!searchResults.showNoResults, Messages.NoSearchResults)}/>
			</HBox>
		);
	}

	// Grouped results
	return (
		<VBox alignItems={'flex-start'} width={'100%'}>
			{groups?.map((g) => (
				<VBox key={`groups-${g.title}`} alignItems={'flex-start'} width={'100%'}>
					<GroupTitle>{g.title}</GroupTitle>
					<Group listMode={true} items={fi(grouping === 'none', items, g.items)} hideActions={true}
						   selectState={true}/>
				</VBox>
			))}
			<Pagination setItems={(evt) => setItems(evt)} allItems={groups?.length === 1 ? groups[0].items : searchResults.items}/>
		</VBox>
	);
};

export default TableMode;