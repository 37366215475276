import {useEffect, useMemo, useRef, useState} from 'react';
import {Process} from "./process";
import {Objects} from './objects';

const empty = () => {
	return
};
const logger = {...console};
/**
 * Similar to useState but stores the values in localStorage
 *
 * @example
 *
 * const [name, setName] = useLocalStorage("name", "Bob");
 *
 * @param key LocalStorage key
 * @param initialValue initial valule if the key doesn't exist
 */

export const StorageEvents: any[] = [];

export const useLocalStorage = (key: string, initialValue: any) => {
	const getStoredValue = () => {
		try {
			const item = window.localStorage.getItem(key);
			if (!item) {
				const tmp = initialValue instanceof Function ? initialValue(storedValue) : initialValue;
				window.localStorage.setItem(key, JSON.stringify(tmp));
				return initialValue
			}
			return JSON.parse(item);
		} catch (error) {
			return initialValue;
		}
	};

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedRawValue, setStoredRawValue] = useState(() => getStoredValue());

	const storedValue = useMemo(() => {
		return getStoredValue();
		// eslint-disable-next-line
	}, [storedRawValue]);

	function checkUserData(_evt) {
		const item = window.localStorage.getItem(key);
		if (item) {
			setStoredRawValue(item);
		} else {
			setValue(initialValue);
		}
	}

	useEffect(() => {
		const callback = checkUserData.bind(this);
		window.addEventListener('storage', callback);
		StorageEvents.push(callback);

		return () => {
			window.removeEventListener('storage', callback);
			StorageEvents.splice(StorageEvents.indexOf(callback), 1);
		};
		// eslint-disable-next-line
	}, []);

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: any) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredRawValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
			const event = new StorageEvent('storage', {
				key: key,
				oldValue: storedValue,
				newValue: valueToStore,
			});
			window.dispatchEvent(event);
			StorageEvents.forEach((cb) => {
				cb();
			});
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [storedValue, setValue];
};

export const useLog = (name: string) => {
	const style = 'color: #badaff; font-weight:bolder;';
	const title = `%c${name} ::`;


	if (Process.ENV !== 'development') {
		return {
			log: empty,
			warn: empty,
			error: empty,
			info: empty,
			debug: empty,
			trace: empty,
			clear: empty,
			time: empty,
			timeEnd: empty,
			group: empty,
			groupCollapsed: empty,
			groupEnd: empty,
			count: empty,
			countReset: empty,
			profile: empty,
			profileEnd: empty,
		};
	}

	return {
		log: (...args) => logger.log(title, style, ...args),
		warn: (...args) => logger.warn(title, style, ...args),
		error: (...args) => logger.error(title, style, ...args),
		info: (...args) => logger.info(title, style, ...args),
		debug: (...args) => logger.debug(title, style, ...args),
		trace: (...args) => logger.trace(title, style, ...args),
		clear: () => logger.clear(),
		time: () => logger.time(name),
		timeEnd: () => logger.timeEnd(name),
		group: (...args) => logger.group(name, ...args),
		groupCollapsed: (...args) => logger.groupCollapsed(title, style, ...args),
		groupEnd: () => logger.groupEnd(),
		count: () => logger.count(name),
		countReset: () => logger.countReset(name),
		profile: () => logger.profile(name),
		profileEnd: () => logger.profileEnd(name),
	};
};

/**
 * This hook makes it super easy to utilize media queries in your component logic
 *
 * @example
 *
 * const columnCount = useMedia(
 *   ["(min-width: 1500px)", "(min-width: 1000px)", "(min-width: 600px)"],
 *   [5, 4, 3],
 *   2
 * );
 *
 * @param queries List of media queries
 * @param values List of values to apply by media query index
 * @param defaultValue Default value
 */
export const useMedia = (queries: string[], values: any[], defaultValue: any) => {
	// Array containing a media query list for each query
	const mediaQueryLists = queries.map((q) => window.matchMedia(q));
	// Function that gets value based on matching media query
	const getValue = () => {
		// Get index of first media query that matches
		const index = mediaQueryLists.findIndex((mql) => Objects.default(mql).matches);
		// Return related value or defaultValue if none
		return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
	};
	// State and setter for matched value
	const [ value, setValue ] = useState(getValue);
	useEffect(
		() => {
			const handler = () => setValue(getValue);
			// Set a listener for each media query with above handler as callback.
			mediaQueryLists.forEach((mql) => {
				if (mql) {
					mql.addEventListener("change", handler);
				}
			});
			// Remove listeners on cleanup
			return () =>
				mediaQueryLists.forEach((mql) => {
					if (mql) {
						mql.removeEventListener("change", handler);
					}
				});
		},
		// eslint-disable-next-line
		[], // Empty array ensures effect is only run on mount and unmount
	);
	return value;
};

export const useDetectSticky = () => {
	const ref = useRef<HTMLDivElement>(null)

	const [isSticky, setIsSticky] = useState(false)

	useEffect(() => {
		if (!ref.current) {
			return
		}

		const observer = new IntersectionObserver(
			([event]) => {
				setIsSticky(event.intersectionRatio < 198)
			},
			{threshold: [1], rootMargin: '-1px 0px 0px 0px',root: document.querySelector("[data-testid='subject-filter']")}
		)
		observer.observe(ref.current)

		return () => observer.disconnect()
	}, [])

	return [isSticky, ref]
}