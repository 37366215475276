import React, {useMemo} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {feedbackAtom, feedbackData, feedbackStepAtom, feedbackTrigger} from '../../state/feedback';
import {FeedbackConfig} from './config';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import {modalAtom} from '../ModalDialogs/ModalDialog';
import {getRecoil} from '../../state/recoilNexus';
import {fi, resetAtomFamily, setCookie} from '../../utils/helpers';
import {FeedbackMessages} from './messages';
import Client from '../../tw/client';
import {FEEDBACK_COOKIE_NAME, FEEDBACK_COOKIE_VERSION} from '../../utils/constants';
import GA from '../../tw/models/GA';

const Feedback = () => {
	const [feedbackStep, setFeedbackStep] = useRecoilState(feedbackStepAtom);
	const setShowFeedback = useSetRecoilState(feedbackAtom);
	const setValue = useSetRecoilState(modalAtom);
	const atomValue = useRecoilValue(feedbackData(feedbackStep));
	const feedbackTriggeredBy = useRecoilValue(feedbackTrigger);

	const backButtonText = useMemo(() => {
		return feedbackStep > 0 ? 'Back' : 'Cancel';
	}, [feedbackStep]);

	const nextButtonText = useMemo(() => {
		return feedbackStep < 3 ? 'Next' : 'Submit';
	}, [feedbackStep]);
	const onCancel = () => {
		if (feedbackStep > 0) {
			setFeedbackStep(old => old - 1);
			return;
		}
		resetAtomFamily(feedbackData, [0, 1, 2, 3]);
		setShowFeedback(false);
		setValue(null);
		setCookie(FEEDBACK_COOKIE_NAME, FEEDBACK_COOKIE_VERSION, 90, '/');
	};

	const sendFeedback = async () => {
		let payloadObj: any[] = [];
		const feedbackSteps = [0, 1, 2, 3];
		for (let i in feedbackSteps) {
			let idx = +i;
			let answer = getRecoil(feedbackData(idx));
			let question = FeedbackConfig()[idx].question;
			if (idx === 2) {
				//if 3rd step has no answer, skip question
				if (!answer) continue;
				//else add the corresponding question
				let prevAnswer = getRecoil(feedbackData(idx - 1));
				if (prevAnswer >= 8) {
					question = FeedbackMessages.ThirdStepPositiveQuestion;
				} else {
					question = FeedbackMessages.ThirdStepNegativeQuestion;
				}
			}
			if (idx === 3 && !answer) answer = 'Yes';
			payloadObj.push({question, answer});
		}
		await Client.addFeedback(payloadObj).then(() => {
			GA.FeedbackEvent(`User feedback submitted - ${feedbackTriggeredBy}`)
		});
		resetAtomFamily(feedbackData, [0, 1, 2, 3]);
		setCookie(FEEDBACK_COOKIE_NAME, FEEDBACK_COOKIE_VERSION, 90, '/');
	};
	const onConfirm = async () => {
		if (feedbackStep === 3) {
			await sendFeedback();
		}
		setFeedbackStep(old => old + 1);
	};

	const disableNext = useMemo(() => {
		if (feedbackStep === 2) return false;
		return !atomValue;
	}, [atomValue, feedbackStep]);

	return (
		<>
			<div className='mb-16'>{FeedbackConfig()[feedbackStep].component()}</div>
			{fi(feedbackStep < 4,
				<>
					<Divider />
					<div className='flex-row-end mt-16'>
						<Button variant='text' color='primary' onClick={onCancel} title={backButtonText}
								size='small' data-testid='cancel-btn'>{backButtonText}</Button>
						<Button variant='contained' color={'primary'} onClick={onConfirm}
								title={nextButtonText} data-testid='confirm-btn'
								size='small' disabled={disableNext}>{nextButtonText}</Button>
					</div>
				</>)}
		</>
	);
};

export default Feedback;
