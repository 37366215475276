import {DocumentsWrapper} from '../../../../../tw/models/DocumentsWrapper';
import {Lists} from '../../../../../utils/lists';
import {Objects} from '../../../../../utils/objects';
import {getRecoil} from '../../../../../state/recoilNexus';
import {references} from '../../../../../state/state';
import {fi} from '../../../../../utils/helpers';
import {Strings} from '../../../../../utils/strings';
import {atom, selector} from 'recoil';
import {filteredDocumentsSelector} from '../../utils';
import {ContentType} from '../../../../../tw/models/ContentType';
import {filterFavoritesAtom, resourceFinderFilterSelector} from '../../state';
import {ISelectValue} from '../../../../FormComponents/Select/SelectComponent';
import {ContentCategory} from '../../../../../tw/models/ContentCategory';
import {ContentGroup} from '../../../../../tw/models/ContentGroup';
import {selectedGroupOrCategoryAtom} from '../Form/Categories';
import {months} from '../../../../../tw/types';

type GroupedResults = {
	items: DocumentsWrapper,
	title: string,
	contentGroup?: ContentGroup
}

export type SortingRule = {
	type: 'series' | 'year' | 'type' | 'paper' | string,
	order: 'asc' | 'desc' | string,
	ruling?: any
}

export enum SortingAndGroupingTypes {
	Sorting = 'sorting',
	Grouping = 'grouping'
}

export enum GroupingRule {
	None = 'none',
	ContentType = 'content_type',
	ContentGroup = 'content_group',
	Year = 'exam_year',
	Series = 'series',
	SeriesAndYearAsc = 'series_year_asc',
	SeriesAndYearDesc = 'series_year_desc'
}

// set from Group by dropdown
export const groupingAtom = atom<GroupingRule>({
	key: 'groupingAtom',
	default: GroupingRule.None,
});

// set when group_by tag is used on content category
export const defaultGroupingAtom = atom<GroupingRule>({
	key: 'defaultGroupingAtom',
	default: GroupingRule.None,
});

// used to store grouping rule tag from current selected content category
export const contentCategoryGroupingRuleAtom = atom<GroupingRule | null>({
	key: 'contentCategoryGroupingRuleAtom',
	default: null,
});

export const searchCurrentPage = atom<number>({
	key: 'searchCurrentPage',
	default: 0,
});

const applyDefaultSorting = (groupedResults: GroupedResults[], contentGroupsTags: any[]): GroupedResults[] => {
	const selectedGroupOrCategory = getRecoil(selectedGroupOrCategoryAtom);

	//apply each content groups' sorting rule
	if (selectedGroupOrCategory instanceof ContentCategory) {
		for (let groupedResult of groupedResults) {
			const cg = groupedResult.contentGroup;
			if (!cg) {
				return groupedResults;
			}
			const rules = Lists.default<SortingRule>(Objects.default(contentGroupsTags.find(c => c.id === cg.getId())).rules);

			let list: string[] = [];
			// if by_content tag exists on content group, we need the list of content types for their order inside the content group
			if (rules.find(r => r.type === 'content_type')) {
				list = cg.content_types;
			}
			if (rules.length) {
				groupedResult.items = groupedResult.items.sort(dynamicSort(rules, list));
			}
		}
	} else if (selectedGroupOrCategory instanceof ContentGroup) {
		const rules = Lists.default<SortingRule>(Objects.default(contentGroupsTags.find(c => c.id === selectedGroupOrCategory.getId())).rules);

		let list: string[] = [];
		// if by_content tag exists on content group, we need the list of content types for their order inside the content group
		if (rules.find(r => r.type === 'content_type')) {
			list = selectedGroupOrCategory.content_types;
		}
		if (rules.length) {
			groupedResults.map(gr => {
				return gr.items.sort(dynamicSort(rules, list));
			});
		}
	}
	return groupedResults;
};

const returnSortValueForField = (fieldA, fieldB) => {
	let aValue = fieldA;
	let bValue = fieldB;
	if ((typeof aValue).toLowerCase() === 'string') {
		aValue = aValue.toLowerCase();
	}
	if ((typeof bValue).toLowerCase() === 'string') {
		bValue = bValue.toLowerCase();
	}
	if (Array.isArray(aValue)) {
		let copyA = [...aValue];
		aValue = copyA.sort()[0];
	}
	if (Array.isArray(bValue)) {
		let copyB = [...bValue];
		bValue = copyB.sort()[0];
	}
	return {aValue, bValue};
};

const refTypes = ['content_type'];
export const dynamicSort = (rules: SortingRule[], referencesList: string[] = []) => {
	return (a, b) => {
		let returnValues: number[] = [];
		for (let i = 0; i < rules.length; i++) {
			let property = rules[i].type;
			let order = rules[i].order;
			let {aValue, bValue} = returnSortValueForField(a[property], b[property]);
			// if typeof property is reference (eg. content type) we need to sort items by order of reference (as set in CMS)
			if (refTypes.includes(property) && referencesList.length) {
				aValue = referencesList.indexOf(a[property]);
				bValue = referencesList.indexOf(b[property]);
				returnValues[i] = aValue - bValue;
			} else {
				if (!aValue) {
					returnValues[i] = 1;
				} else if (!bValue) {
					returnValues[i] = -1;
				} else if (aValue && bValue) {
					if (aValue > bValue) {
						returnValues[i] = order === 'asc' ? 1 : -1;
					} else if (aValue < bValue) {
						returnValues[i] = order === 'asc' ? -1 : 1;
					} else {
						returnValues[i] = 0;
					}
				}
			}
		}

		return returnValues.reduce(
			(accumulator, currentValue) => accumulator || currentValue,
		);
	};
};

// used for group_by_sy_asc/desc to sort groups of Series + year in ascending or descending order
const sortYearsAndSeries = (list: any[], order: string) => {
	let rules: SortingRule[] = [
		{type: 'year', order},
		{type: 'month', order},
	];
	return list.sort(dynamicSort(rules));

};

// used for group_by_sy_asc/desc to create groups of Series + year
const groupbyYearAndSeries = (items) => {
	let yearAndSeriesGroups: any[] = [];
	items.forEach(d => {
		if (d['series'] && d['exam_year']) {
			let s = d['series'].toUpperCase();
			if (Array.isArray(d['exam_year'])) {
				d['exam_year'].forEach(e => {
					if (!yearAndSeriesGroups.find(g => g.month === months[s] && +g.year === +e)) {
						yearAndSeriesGroups.push({
							month: months[s],
							year: +e,
							series: s,
							title: `${Strings.capitalize(s)} ${e}`,
						});
					}
				});
			} else {
				if (!yearAndSeriesGroups.find(g => g.month === months[s] && +g.year === +d['exam_year'])) {
					yearAndSeriesGroups.push({
						month: months[s],
						year: +d['exam_year'],
						series: s,
						title: `${Strings.capitalize(s)} ${d['exam_year']}`,
					});
				}
			}
		}
	});
	return yearAndSeriesGroups;
};
export const groupResults = (results: any[], grouping: GroupingRule): GroupedResults[] => {
	if (results.length === 0) {
		return [{title: '', items: new DocumentsWrapper()}];
	}

	const wrapper: DocumentsWrapper = Lists.default(results) as DocumentsWrapper;
	const others: any[] = [];

	let result: any[] = [];
	switch (grouping) {
		default:
		case GroupingRule.None:
			result = [{title: '', items: [...wrapper]}];
			break;
		case GroupingRule.SeriesAndYearDesc:
			const c = getRecoil(selectedGroupOrCategoryAtom) as ContentCategory;
			if (!c) {
				break;
			}
			const documents = wrapper.from(c.documents());
			let ysgroups = groupbyYearAndSeries(documents);
			let tmp = sortYearsAndSeries(ysgroups, 'desc');

			// for group_by_sy_asc/desc we need ONLY a content group in the selected category to use the sorting rules assigned to that content group
			// if there are multiple content groups, no sorting rule will be applied!!
			let contentGroup = c.contentGroups().length === 1 ? c.contentGroups()[0] : undefined;
			Array.from(new Set(tmp)).forEach(sy => {
				result.push({title: sy.title, items: documents.bySeriesAndYear(sy), contentGroup: contentGroup});
			});
			break;
		case GroupingRule.SeriesAndYearAsc:
			const category = getRecoil(selectedGroupOrCategoryAtom) as ContentCategory;
			if (!category) {
				break;
			}
			const allDocs = wrapper.from(category.documents());
			let yearAndSeriesGroups = groupbyYearAndSeries(allDocs);
			let sorted = sortYearsAndSeries(yearAndSeriesGroups, 'asc');

			// for group_by_sy_asc/desc we need ONLY a content group in the selected category to use the sorting rules assigned to that content group
			// if there are multiple content groups, no sorting rule will be applied!!
			let cg = category.contentGroups().length === 1 ? category.contentGroups()[0] : undefined;
			Array.from(new Set(sorted)).forEach(sy => {
				result.push({title: sy.title, items: allDocs.bySeriesAndYear(sy), contentGroup: cg});
			});
			break;
		case GroupingRule.ContentGroup:
			const selectedGroupOrCategory = getRecoil(selectedGroupOrCategoryAtom);
			if (!selectedGroupOrCategory || !(selectedGroupOrCategory instanceof ContentCategory)) {
				break;
			}
			Lists.default<ContentGroup>(selectedGroupOrCategory.contentGroups()).forEach((cg) => {
				const docs = wrapper.byContentGroup(cg);

				if (docs.length === 0) {
					return;
				}

				result.push({
					items: docs,
					title: cg.displayLabel(),
					contentGroup: cg,
				});
			});
			break;
		case GroupingRule.ContentType:
			const contentTypeIds = Array.from(new Set(wrapper.map(item => item['content_type'])));
			contentTypeIds.forEach((contentType) => {
				const contentTypeObject = Objects.default(getRecoil(references(contentType)));
				if (!contentTypeObject) {
					return;
				}
				const label = contentTypeObject.displayLabel();
				result.push({
					items: wrapper.byContentType(contentType),
					title: fi(label.includes('#'), label.substring(0, label.indexOf('#')), label),
				});
			});

			result = Lists.sort(result, 'title');
			break;

		case GroupingRule.Series:
			const distinctSeries = Array.from(new Set(wrapper.map(item => item['series']))).sort((a, b) => a.localeCompare(b));
			distinctSeries.forEach((series) => {
				if (typeof series !== 'undefined') {
					result.push({
						items: wrapper.bySeries(series),
						title: Strings.capitalize(series),
					});
				} else {
					others.push({
						items: wrapper.filter(i => !i['series']),
						title: 'Others',
					});
				}
			});

			if (others.length > 0) {
				result = [...result, ...others];
			}
			break;

		case GroupingRule.Year:
			const years = wrapper.examYears().sort().reverse();
			years.forEach((year) => {
				if (typeof year !== 'undefined') {
					result.push({
						items: wrapper.byYears(year),
						title: year,
					});
				} else {
					others.push({
						items: wrapper.filter(i => !i['exam_year']),
						title: 'Others',
					});
				}
			});
			if (others.length > 0) {
				result = [...result, ...others];
			}
			break;
	}

	return result;
};

export const groupAndSortResults = (results: any[], grouping: GroupingRule, contentGroupsTags: any[]) => {
	const groupedResults = groupResults(results, grouping);
	const filtering = getRecoil(resourceFinderFilterSelector);

	if (!contentGroupsTags.length || filtering.sort !== 'default') {
		return groupedResults;
	} else {
		return applyDefaultSorting(groupedResults, contentGroupsTags);
	}
};

type FilterOptions = {
	contentTypes: ContentType[],
	contentTypesOptions: ISelectValue[],
	years: string[],
	yearsOptions: ISelectValue[],
	series: string[],
	seriesOptions: ISelectValue[],
}

export const getFilterOptions = selector<FilterOptions>({
	key: 'getFilterOptions',
	get: ({get}) => {
		const filters = get(resourceFinderFilterSelector);
		const favorites = get(filterFavoritesAtom);

		let filteredItems = get(filteredDocumentsSelector);
		if (favorites) {
			filteredItems = filteredItems.filter(item => item.isFavorite());
		}

		const result: FilterOptions = {
			contentTypes: [],
			contentTypesOptions: [],
			years: [],
			yearsOptions: [],
			series: [],
			seriesOptions: [],
		};

		if (filters.contentGroupTypes?.length) {
			filteredItems = filteredItems.byContentType(...filters.contentGroupTypes);
		}

		result.contentTypes = filteredItems.contentTypes();
		result.years = filteredItems.examYears();
		result.series = filteredItems.series();

		result.contentTypesOptions = result.contentTypes.map(ct => ct.asOption());
		Lists.sort(result.contentTypesOptions, 'label');

		result.years.forEach(year => result.yearsOptions.push({label: year, value: year, object: year}));
		Lists.sort(result.yearsOptions, 'label', true);

		result.series.forEach(s => result.seriesOptions.push({label: Strings.capitalize(s), value: s, object: s}));
		Lists.sort(result.seriesOptions, 'label');

		return result;
	},
});