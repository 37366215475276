import React, { Suspense, useEffect, useMemo } from 'react';
import PageLoader from '../components/Loader/PageLoader';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { Widgets } from '../tw/models/Page';
import { WidgetContext } from '../components/widgets/widget';
import ContentType from '../components/widgets/ContentType/ContentType';
import { selectedPageAtom } from '../state/state';
import HomepageResources from '../components/widgets/Homepage/HomepageResources';
import Homepage from '../components/widgets/Homepage/Homepage';
import SubjectUpdates from '../components/widgets/SubjectUpdates/SubjectUpdates';
import { subjectUpdateSelector } from '../state/subjectUpdate';
import UsefulLinks from '../components/widgets/UsefulLinks/UsefulLinks';
import ChildPages from '../components/widgets/ChildPages/ChildPages';
import ContentGroup from '../components/widgets/ContentGroup/ContentGroup';
import ContentItems from '../components/widgets/ContentItems/ContentItems';
import { documentSelector } from '../state/documents';
import EventsFilter from '../components/widgets/Events/EventsFilter';
import Events from '../components/widgets/Events/Events';
import FAQ from '../components/widgets/FAQ/FAQ';
import QuickLinks from '../components/widgets/QuickLinks/QuickLinks';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Forms from '../components/widgets/Forms/Forms';
import SizePathway from '../components/widgets/SizePathway/SizePathway';
import KeyDates from '../components/widgets/KeyDates/KeyDates';
import { Browser } from '../utils/browser';
import { ROUTES } from '../utils/routes';
import LowerSection from '../components/LowerSection/LowerSection';
import PageNotifications from '../components/Notifications/PageNotifications';
import { sessionAtom } from '../state/session';
import { device, ROUTE_PREFERENCES, SELECTED_SUBJECT } from '../utils/constants';
import UserTourComponent from '../components/UserTour/UserTourComponent';
import YearSeriesFilter from '../components/widgets/YearSeriesFilter/YearSeriesFilter';
import { Strings } from '../utils/strings';
import FilterSubjectWidget from '../components/widgets/SubjectFilter/FilterSubjectWidget';
import PageContentWidget from '../components/widgets/PageContent/PageContentWidget';
import ResourceFinder from '../components/widgets/ResourceFinder/ResourceFinder';
import SubjectsList from '../components/widgets/SubjectsList/SubjectsList';
import GlobalAnnouncements from '../components/widgets/SubjectUpdates/GlobalAnnouncements';
import MySubjectWidget from '../components/widgets/MySubject/MySubjectWidget';
import { WidgetType } from '../tw/types';
import Flag from '../components/Card/Flag';
import { setRecoil } from '../state/recoilNexus';
import { AlertSeverity, snackbarStateAtom } from '../components/Snackbar/SnackbarComponent';
import { Messages } from '../utils/messages';
import PathwayContainer from '../components/widgets/Pathway/PathwayContainer';

const PageContentElem = styled.div`
    grid-area: pageContent;
    padding: 24px;
    background-color: var(--color-background);

    @media ${device.tablet} {
        padding: 16px;
    }
`;

const Header = styled.div`
    margin-bottom: 24px;

    @media ${device.tablet} {
        margin-bottom: 12px;
    }
`;

const Title = styled.h1`
    font-family: var(--font-bolder), sans-serif;
    font-size: 32px;
    line-height: 36px;
    display: block;
    @media ${device.tablet} {
        font-size: 24px;
        margin-bottom: 8px;
    }
`;

const Description = styled.span`
    font-family: var(--font-regular), sans-serif;
    font-size: 16px;
    line-height: 20px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 32px;
    }
`;

const PageContent = ({children}: any) => {
	const selectedPage = useRecoilValue(selectedPageAtom);
	const documents = useRecoilValue(documentSelector);
	const user = useRecoilValue(sessionAtom);
	const params = Browser.getParams(ROUTES);
	const subjectUpdates = useRecoilValue(subjectUpdateSelector);

	useEffect(() => {
		if (!user) return;
		if (!user.availableQualifications()) {
			setRecoil(snackbarStateAtom, {
				message: `${Messages.NoSubjectsToAccount}`,
				severity: AlertSeverity.Info,
			});
		} else {
			setRecoil(snackbarStateAtom, null);
		}
	}, [user]);
	useEffect(() => {
		if (!user) return;
		if (user.shouldUpdatePreferences()) Browser.navigate(ROUTE_PREFERENCES);
	}, [user]);

	const filteredWidgets = useMemo<Widgets>(() => {
		const widgets = new Widgets();
		if (!selectedPage || !selectedPage.object) {
			return widgets;
		}

		return selectedPage.object.config.filterAvailable(documents, subjectUpdates);
	}, [selectedPage, documents, subjectUpdates]);

	useEffect(() => {
		let subjectParam = Browser.queryParam('subject');
		const localStorageSubject = localStorage.getItem(SELECTED_SUBJECT);
		if (!localStorageSubject) return;
		let storageSubject: string = Strings.default(localStorageSubject, Strings.default(subjectParam));
		if (subjectParam && subjectParam !== storageSubject) {
			localStorage.setItem(SELECTED_SUBJECT, subjectParam);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document.location.search]);

	// Custom routes that are handled by PageComponent instead of the routes
	// because we need all the wrappers and stuff for them
	switch (params.pageTitle) {
		case 'resource_finder':
			children = <ResourceFinder/>;
		// .. other cases should go here
	}

	if (!selectedPage) {
		return null;
	}

	if (children) {
		return (
			<PageContentElem data-testid="page-content-child">
				<Suspense fallback={<PageLoader/>}>
					{children}
				</Suspense>
			</PageContentElem>
		);
	}

	const renderWidget = (widget, index) => {
		switch (widget.type) {
			case WidgetType.FilterByQualification:
				return <FilterSubjectWidget key={index}/>;
			case WidgetType.EventFilter:
				return <EventsFilter key={index}/>;
			case WidgetType.FilterContent:
				return <YearSeriesFilter key={index}/>;
			case WidgetType.HomepageResources:
				return <HomepageResources key={index}/>;
			case WidgetType.Homepage:
				return <Homepage {...widget} key={index}/>;
			case WidgetType.ContentType:
				return <ContentType {...widget} key={`${index}-contentType`}/>;
			case WidgetType.ContentGroup:
				return <ContentGroup {...widget} key={`${index}-contentGroup`}/>;
			case WidgetType.ContentItems:
				return <ContentItems {...widget} key={`${index}-contentItems`}/>;
			case WidgetType.SubjectUpdate:
				return <SubjectUpdates {...widget} key={`${index}-subjectInfo`}/>;
			case WidgetType.GlobalAnnouncements:
				return <GlobalAnnouncements {...widget} key={`${index}-globalAnnouncements`}/>;
			case WidgetType.UsefulLinks:
				return <UsefulLinks {...widget} key={`${index}-usefulLinks`}/>;
			case WidgetType.ChildPages:
				return <ChildPages {...widget} key={`${index}-childPages`}/>;
			case WidgetType.Events:
				return <Events {...widget} key={`${index}-events`}/>;
			case WidgetType.FAQ:
				return <FAQ {...widget} key={`${index}-faq`}/>;
			case WidgetType.FreeText:
				return <PageContentWidget {...widget} key={`${index}-pageContent`}/>;
			case WidgetType.SizePathway:
				return <SizePathway key={`${index}-sizePathway`}/>;
			case WidgetType.QuickLinks:
				return <QuickLinks {...widget} key={`${index}-quickLinks`}/>;
			case WidgetType.Form:
				return <Forms key={`${index}-trainingForm`}/>;
			case WidgetType.KeyDates:
				return <KeyDates key={`${index}-keyDates`}></KeyDates>;
			case WidgetType.ResourceFinder:
				return <ResourceFinder {...widget} key={`${index}-resourceFinder`}></ResourceFinder>;
			case WidgetType.MySubject:
				return <MySubjectWidget {...widget} key={`${index}-mySubject`}/>;
			case WidgetType.Pathway:
				return <PathwayContainer key={`${index}-pathway`}/>;
			case WidgetType.SubjectsList:
				return <SubjectsList {...widget} key={`${index}-subjectsList`}/>;
			default:
				return null;
		}
	};

	return (
		<PageContentElem data-testid="page-content" id="page-content">
			<Breadcrumbs/>
			<Header data-testid="page-header">
				<div data-testid="title" className={'flex-row-start'}>
					<Title>{selectedPage.object.displayLabel()}</Title>
					<Flag key={selectedPage.object.getId()} className={'page-title'} item={selectedPage.object}></Flag>
				</div>
				<Description>{selectedPage.object.description}</Description>
			</Header>
			<PageNotifications/>
			<Body data-testid="page-body">
				{filteredWidgets.map((widget, index) => (
					<WidgetContext.Provider value={{widget, page: selectedPage.object, filteredWidgets}}
											key={`${selectedPage.object.getId()}-${index}`}>
						{renderWidget(widget, index)}
					</WidgetContext.Provider>
				))}
			</Body>
			<LowerSection/>
			<UserTourComponent/>
		</PageContentElem>
	);
};

export default PageContent;
