import styled from "@emotion/styled";
import {fi} from "../../../utils/helpers";

type Props = {
    gap?: number;
    alignItems?: string;
    justifyContent?: string;
    flexWrap?: string;
    padding?: number | string;
    margin?: number | string;
    width?: number | string;
}

export const VBox = styled.div<Props>`
    display: flex;
    flex-direction: column;
    padding: ${props => fi(typeof props.padding === 'number', `${props.padding|| 0}px`, props.padding || '0')};
    margin: ${props => fi(typeof props.margin === 'number', `${props.margin|| 0}px`, props.margin || '0')};
    gap: ${props => props.gap || 0}px;
    width: ${props => fi(typeof props.width === 'number', `${props.width|| 0}px`, props.width || '100%')};
    align-items: ${props => props.alignItems || 'center'};
    justify-content: ${props => props.justifyContent || 'flex-start'};
    flex-wrap: ${props => props.flexWrap || 'nowrap'};
`