import React from 'react';
import styled from '@emotion/styled';
import SecondaryContent from './SecondaryContent';
import VMenu from './VMenu';
import {useMenu} from '../state/menu';
import {device} from '../utils/constants';
import ExpandCollapse from '../components/ExpandCollapse/ExpandCollapse';

const MainContentLayout = styled('div')<{ visible: boolean }>`
    display: grid;
    grid-template-areas: 'mainMenu secondaryContent';
    grid-area: mainContent;
    height: auto;
    min-height: 100vh;
    //overflow-y: auto;
    position: relative;
    grid-template-columns: 0 1fr;
    ${props => props.visible && `grid-template-columns: 89px 1fr`};

    @media ${device.mobile} {
        grid-template-areas: 
				'secondaryContent' 
				'mainMenu';
        grid-template-rows: 1fr 70px;
        grid-template-columns: 1fr;
    }
`;

const MainContent = ({children}: any) => {
	const {state: {menuVisible}} = useMenu();

	return (
		<MainContentLayout data-testid="main-content" id="main-content" visible={menuVisible}>
			{menuVisible && <VMenu/>}
			<SecondaryContent>
				{children}
			</SecondaryContent>
			<ExpandCollapse/>
		</MainContentLayout>
	);
};

export default MainContent;