import styled from '@emotion/styled';
import NavigateNext from '@mui/icons-material/NavigateNext';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { fi, walkTreeUp } from '../../utils/helpers';
import { horizontalNavAtom, selectedObject } from '../../state/state';
import { Objects } from '../../utils/objects';
import { useMenu } from '../../state/menu';
import { Page } from '../../tw/models/Page';
import { WidgetType } from '../../tw/types';

const Wrapper = styled.div`
    flex-grow: 1;
    min-width: 10%;
`;

const BreadcrumbsElement = styled(MaterialBreadcrumbs)`
    margin-bottom: 24px;

    &:not(:has(li)) {
        display: none;
    }

    li p, li a {
        font-size: 16px;
        color: var(--color-monochrome);
        line-height: 20px;
        font-family: var(--font-regular);
    }

    li a {
        color: var(--color-blue);
    }

    svg {
        height: 20px;
    }
`;


type NavItem = {
	path: string,
	name: string,
	id: string,
	level?: number,
	firstChildPath?: string,
	isChildPages?: boolean,
	page?: Page,
}

const Breadcrumbs = () => {
	useLocation();
	const {state: {selectedPage}, setRootPage} = useMenu();
	const object = useRecoilValue(selectedObject);
	const horizontalNav = useRecoilValue(horizontalNavAtom);

	const items: NavItem[] = useMemo(() => {
		const result: NavItem[] = [];
		if (selectedPage?.parent) {
			const trace: any[] = [];
			walkTreeUp(selectedPage, (node) => {
				trace.push({
					path: node.object.getURL(),
					name: node.object.displayLabel(),
					id: node.id,
					level: node.level,
					firstChildPath: node.children.length > 0 ? node.children[0].object.getURL() : '',
					isChildPages: node.object.hasWidget(WidgetType.ChildPages),
					page: node.object,
				});
			});
			let reversedArray = [...trace].reverse();
			result.push(...reversedArray);
		}

		if (object && selectedPage) {
			if (!result.length) {
				result.push({
					path: selectedPage.object.getURL(),
					name: selectedPage.object.displayLabel(),
					id: selectedPage.object.getId(),
					page: selectedPage.object,
				});
			}
			if (object.getId() !== Objects.default(result[result.length - 1]).id) {
				result.push({
					path: '',
					name: object.displayLabel(),
					id: object.getId(),
				});
			}
		}
		return result;
	}, [selectedPage, object]);

	const onClick = (evt: any, item: any) => {
		if (item.page) {
			if (item.page.isLinkPage()) {
				window.open(item.page.link, '_blank');
				evt.preventDefault();
			}
			if (!item.page.parent) {
				// navigate to root page
				setRootPage(item.page.getId())
				evt.preventDefault();
			}
		}
	};

	//Additional for megamenu navigation
	const firstChildPath = (item: NavItem) => {
		let path = item.path;
		if(horizontalNav && item.level === 2 ) {
			if (item.isChildPages && item.firstChildPath) {
				path = item.firstChildPath;
			}
		}

		return path;
	}

	if (items.length === 0) {
		return null;
	}

	return (
		<Wrapper>
			<BreadcrumbsElement
				separator={<NavigateNext fontSize="small"/>}
				aria-label="breadcrumbs"
				maxItems={10}
				itemsAfterCollapse={5}
				itemsBeforeCollapse={2}
				id="breadcrumbs"
				data-testid={'breadcrumbs'}>
				{items.map((item, index) => fi(index === items.length - 1, (
							<span className="text-16 text-regular" key={item.name} title={item.name}
								  data-testid={`item-${item.id}`}>{item.name}</span>
						),
						<NavLink onClick={(evt) => onClick(evt, item)} to={firstChildPath(item)} key={item.name} title={item.name}
								 data-testid={`item-${item.id}`}>{item.name}</NavLink>,
					),
				)}
			</BreadcrumbsElement>
		</Wrapper>
	);
};

export default Breadcrumbs;
