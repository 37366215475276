import Button from '@mui/material/Button';
import React, {useMemo} from 'react';
import {ReactComponent as LinkIcon} from '../../../../assets/images/card/actions/go-to-icon.svg';
import {ReactComponent as StartCourseIcon} from '../../../../assets/images/card/actions/start-course-icon.svg';
import {Link} from '../../../../tw/models/Link';
import {fi} from '../../../../utils/helpers';
import {disableButton} from './utils';
import {Messages} from '../../../../utils/messages';

const LinkButton = ({item, icon = <LinkIcon title={'Go to'}/>, courseIcon = <StartCourseIcon title="Start course"/>}: { item: Link, courseIcon?: React.ReactNode, icon?: React.ReactNode }) => {
	const disableAction = useMemo(() => {
		return disableButton(item);
	}, [item]);

	const renderLinkAction = useMemo(() => {
		return fi(item.isOnlineCourse,
			courseIcon,
			icon,
		);
	}, [item]);

	const onClick = () => {
		if (item) {
			item.defaultAction().actionHandler();
		}
	};

	return (
		<span className='card-action' title={fi(disableAction, Messages.FeatureNotAvailable, '')}>
			<Button data-testid="link-btn" color="primary" variant="text" size="large" onClick={onClick}
					disabled={disableAction}>
			{renderLinkAction}
			</Button>
		</span>
	);
};

export default LinkButton;