import { Organization, SourceSystem, UserSession } from '../../../tw/models/Session';
import { useRecoilState } from 'recoil';
import React, { useEffect, useMemo } from 'react';
import { Lists } from '../../../utils/lists';
import { INTERNATIONAL_STREAM, OCR_STREAM } from '../../../utils/constants';
import { Objects } from '../../../utils/objects';
import { Strings } from '../../../utils/strings';
import RadioButtonsGroup from '../../FormComponents/Radio/RadioButtonsGroup';
import { selectedOrgAtom } from '../ChangeOrgModal';
import OrgModalInfoNote from './OrgModalInfoNote';
import styled from '@emotion/styled';

const WrapperElem = styled.div`
    span {
        color: var(--color-monochrome);
    }
`;

const OrganizationModalContent = ({user}: {user: UserSession}) => {
	const [selectedOrg, setSelectedOrg] = useRecoilState(selectedOrgAtom);

	const organizationsMappedToStreams = useMemo(() => {
		let streamsMap = {};
		if (!user) return streamsMap;
		let OCROrgs: Organization[] = [];
		let CIOrgs: Organization[] = [];
		Lists.default<Organization>(user.organizations).forEach(org => {
			if (org.productGroup.includes(OCR_STREAM) && org.productGroup.includes(INTERNATIONAL_STREAM)) {
				OCROrgs.push(org);
				CIOrgs.push(org);
			} else if (org.productGroup.includes(OCR_STREAM) || !org.productGroup.length) {
				OCROrgs.push(org);
			} else if (org.productGroup.includes(INTERNATIONAL_STREAM)) {
				CIOrgs.push(org);
			}
		});
		streamsMap[INTERNATIONAL_STREAM] = CIOrgs;
		streamsMap[OCR_STREAM] = OCROrgs;
		return streamsMap;
	}, [user]);


	useEffect(() => {
		if (!selectedOrg) {
			if (user.lastSelectedOrg) {
				setSelectedOrg(user.lastSelectedOrg + '~' + user.selectedBusinessStream);
			} else {
				let obj = Objects.default(organizationsMappedToStreams[OCR_STREAM][0]);
				setSelectedOrg(obj.value);
			}
		}
	}, [selectedOrg]);

	const onChange = (obj, _field) => {
		setSelectedOrg(obj);
	};

	const getField = (stream) => {
		let obj: any = {
			name: 'org',
			label: stream,
		};
		obj.options = Lists.default<Organization>(organizationsMappedToStreams[stream]).map(o => ({
			bpId: o.bpid,
			name: o.name,
			id: o.getOrgId(),
			code: Strings.default(Objects.default(Lists.default<SourceSystem>(o.sourceSystems).find(s => s.systemId === 'LegacyCustomerNum')).orgId, o.bpid),
		})).map(org => ({
			label: `${org.code} - ${org.name}`,
			value: `${org.bpId}~${stream}`,
			object: org,
		}));
		return obj;
	};

	return (
		<WrapperElem>
			<OrgModalInfoNote user={user}/>
			<div className="mt-16">
				{user.streams.map((stream, idx) => (
					<RadioButtonsGroup field={getField(stream)} onChange={onChange} value={selectedOrg}
									   styling={'flex-column-center'} key={getField(stream).label}/>
				))}
			</div>
		</WrapperElem>

	);
};

export default OrganizationModalContent;