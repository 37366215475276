import {atom, selector} from 'recoil';
import Client from '../tw/client';
import {subjectSelector} from './product';
import {SubjectUpdate} from '../tw/models/SubjectUpdate';
import {sessionAtom} from './session';
import {refreshItem} from './documents';
import {Objects} from '../utils/objects';
import { draftPreviewAtom } from './state';

export const selectedSubjectUpdate = atom<SubjectUpdate | null>({
	key: 'selectedSubjectUpdateAtom',
	default: null,
});

export const preferredSubjectsInfosSelector = selector<SubjectUpdate[]>({
	key: 'preferredSubjectsInfosSelector',
	get: async ({ get }) => {
		const session = get(sessionAtom)

		const subjectIds = session?.preferences?.qualification
			.map(q => q.subject)

		if (subjectIds) {
			const subjectInfoArr = await Promise.all(subjectIds?.map(s => (async () => Objects.default({[s]: await Client.getSubjectInfo(s)}))()))
			return Object.assign({}, ...subjectInfoArr)
		}
		return []
	}
})

const loadSubjectUpdateSelector = selector<SubjectUpdate[]>({
	key: 'loadSubjectUpdateSelector',
	get: async ({get}) => {
		const session = get(sessionAtom);
		const subject = get(subjectSelector);

		if (!subject || !session) {
			return [];
		}
		const result = await Client.getSubjectInfo(subject.getId());

		return result.sort((a: any, b: any): number => {
			let aValue = new Date(Objects.default(a.__meta).modified);
			let bValue = new Date(Objects.default(b.__meta).modified);
			if (aValue < bValue) {
				return 1;
			}
			if (aValue > bValue) {
				return -1;
			}
			return 0;
		});
	},
});

export const subjectUpdateSelector = selector<SubjectUpdate[]>({
	key: 'subjectUpdateSelector',
	get: async ({get}) => {

		let subjectUpdates = get(loadSubjectUpdateSelector);
		const draftOnly = get(draftPreviewAtom);
		const refreshed = get(refreshItem);

		if (draftOnly) {
			subjectUpdates = subjectUpdates.filter((su) => su.isDraft())
		}

		if (refreshed && Array.isArray(refreshed) && refreshed.length) {
			const tmp = [...subjectUpdates] as any;
			refreshed.forEach((doc) => {
				for (let i = 0; i < tmp.length; i++) {
					if (tmp[i].getId() === doc.getId()) {
						tmp[i] = doc;
						break;
					}
				}
			});
			return tmp;
		}
		return subjectUpdates;
	},
});