import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#424242',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#424242',
    color: '#FFFFFF',
    borderRadius: '4px'
  },
}));



<BootstrapTooltip title="Add">
<Button>Bootstrap</Button>
</BootstrapTooltip>


export default BootstrapTooltip
