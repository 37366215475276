export enum FormMessages {
	InfoNote = `In house training gives you the opportunity to hear from an OCR qualification expert at a time of your choosing, 
                perhaps on an Inset day or in an after-school slot.
                We can deliver one or more of our courses, selected from this year’s programme,
                either online or on-site in your school or college. 
                You choose who should take part – we don’t charge per attendee so you have flexibility with numbers.
				Complete this form to find out about availability.`,
	// training form components title
	PersonalInformation = `Personal information`,
	CentreInformation = `Centre information`,
	TrainingCourse = `Training course requested`,
	DeliveryDate = `Preferred date for delivery`,
	PriceList = `Price list`,
	TrainingCourseLineOne = `Please choose the training course(s) you would like from the drop down menu. Please note we can only offer courses as listed in our current programme, including the specified course length. We also regret that we can’t offer Ask the Examiner or Ask the Moderator events in-house.`,
	TrainingCourseLineTwo = `We’re happy to look at requests for several courses to be run on the same day, and please add in any other information that will help us arrange your training.`,
	Error = `Error submitting training request`,
	SubmittedSuccess = `Training request submitted successfully`
}