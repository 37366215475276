export enum Messages {
	Sorry = `We're sorry`,
	UnauthorizedError = 'You are not authorised to view this page.',
	NotFoundError = 'The page you are trying to reach was not found.',
	UnknownError = `The server encountered an internal error and was unable to complete your request.
                    Please go back and try again.`,
	DisabledInPreview = 'This feature is not available in preview mode',
	MandatoryField = 'This field is mandatory.',
	SubjectNotesLineOne = `Teach Cambridge is a new online service for OCR teachers. We want to make it easy for you to
                        find the materials that matter.
                        If your qualifications are not yet available on the site, we will be adding them soon!`,
	CheckAgainLater = 'Please check back again later.',
	PhoneNumberValidation = `Phone number is not valid.`,
	DateValidation = `You must enter a valid date.`,
	FutureDateValidation = `You must enter a future date.`,
	NumberTypeValidation = `You must specify a number.`,
	PositiveIntegerValidation = `You must specify a positive integer.`,
	FormsErrorMessage = `Please check the fields marked with error messages and try again.`,
	SubjectsUpdateSuccess = `Subjects updated successfully`,
	SubjectsUpdateError = `Error updating subjects. Please check the fields marked with error messages and try again.`,
	FeatureNotAvailable = 'This feature is available with full access.',
	FeatureNotAvailablePreview = 'This feature is not available in preview mode.',
	CookiesConsent = `We use cookies to improve your experience of our website. By clicking on any link you are giving consent for us to set cookies. You can change the cookie settings on your browser at any time.`,
	PreviewMode = ` You are viewing a preview for this page. The page has not yet been published.`,
	StudentsNumberNote = `Please note that this is an approximate figure that will 
only be used for OCR insight purposes. Your centre will still 
be required to make accurate student entries for each unit or 
qualification at the appropriate time.`,
	QualificationNotAvailable = `This service is not currently available for all qualifications and subjects`,
	RatingMessage = `We’re constantly trying to improve our content and user experience. We count on user feedback for this and your opinion is very important for us.`,
	ChangeOrgMessage = `Select the centre you want to switch to:`,
	ChangeOrgRegistrationLine1 = `Because you’re associated with multiple centres you need to select one first. `,
	ChangeOrgRegistrationLine2 = `Later, you will be able to switch between centres by clicking on the top header bar from the left of the screen.`,
	SubjectChanged = `Subject updated successfully`,
	NoSearchResults = `There are no results yet. Press "Search" button to fetch results.`,
	NoFAQ = `There are no FAQs for `,
	NoPlannedEvents = `There are no planned events for `,
	NoPastEvents = `There are no past event materials available for `,
	CustomerSupportCenter = 'https://www.ocr.org.uk/contact-us/',
	ChangeFilterMessage = `Please try changing the filters.`,
	NoContent = `There is no content that matches the filter you have applied.`,
	DefaultSubjectTooltip = 'Marking a subject as default will return you to this subject\'s home page each time you log back in.',
	DuplicateSubject = 'You already subscribed to this subject.',
	NoSubjectsToAccount = 'You don\'t have any subjects added to your account. In order to see subject related content you will need to add new subjects.'
}
