import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { device } from '../../utils/constants';
import { fi } from '../../utils/helpers';
import { ReactComponent as ExpandIcon } from '../../assets/images/expand-menu.svg';
import { ReactComponent as CollapseIcon } from '../../assets/images/collapse-menu.svg';
import { useMenu } from '../../state/menu';
import { useRecoilValue } from 'recoil';
import { horizontalNavAtom, scrollTopPosition } from '../../state/state';
import { enableSubjectFilter } from '../../state/product';

const Wrapper = styled.div<{expanded: boolean}>`
    position: absolute;
    top: 10px;
    left: ${props => fi(!props.expanded, '75px', '376px')};
    display: flex;
    align-items: center;
    border: 1px solid #cac6c6;
    border-radius: 18px;
    background-color: var(--color-background);

    &.sticky-position {
        z-index: 4;
        position: fixed;
        top: 80px;
    }

    svg {
        cursor: pointer;
        height: 24px;
        width: 24px;

        path {
            fill: var(--color-grey-text);
        }
    }

    @media ${device.tablet} {
        visibility: hidden;
    }

    @media ${device.mobile} {
        display: none;
    }
`;

const ExpandCollapse = () => {
	const {state: {toggleVisibleMenu, secondaryMenuVisible, subTree, menuVisible}} = useMenu();
	const positionToTop = useRecoilValue(scrollTopPosition);
	const horizontalNav = useRecoilValue(horizontalNavAtom);
	const subjectFilterEnabled = useRecoilValue(enableSubjectFilter);

	const showCollapseBtn = useMemo(() => {
		return menuVisible && ((subTree.length && !horizontalNav) || (horizontalNav && subjectFilterEnabled));
	}, [subTree, horizontalNav, subjectFilterEnabled, menuVisible]);

	if (!showCollapseBtn) {
		return null;
	}

	return (
		<Wrapper data-testid="expand-menu" onMouseDown={() => toggleVisibleMenu(!secondaryMenuVisible)}
				 expanded={secondaryMenuVisible} className={positionToTop && subjectFilterEnabled ? 'sticky-position' : ''}>
			{fi(secondaryMenuVisible,
				<CollapseIcon data-testid='expanded-state' title={subjectFilterEnabled ? 'Collapse the subject selection panel' : 'Collapse the navigation panel'}/>,
				<ExpandIcon data-testid='collapsed-state' title={subjectFilterEnabled ? 'Expand the subject selection panel' : 'Expand the navigation panel'}/>,
			)}
		</Wrapper>
	);
};

export default ExpandCollapse;