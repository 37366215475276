import React, {useContext, useMemo} from 'react';
import Button from '@mui/material/Button';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {fi} from '../../../utils/helpers';
import {WidgetContext} from '../widget';
import {displayShowAllButton} from '../Homepage/utils';
import styled from '@emotion/styled';
import {device} from '../../../utils/constants';
import { WidgetType } from '../../../tw/types';

const Wrapper = styled.div`
    display: inline-block;
    margin-top: 8px;
	
	@media ${device.mobile} {
		display:flex;
		justify-content: flex-end;
		button {
			padding: 0;
		}
	}
`;

const emptyDiv = <div style={{height: '42px'}} data-testid="no-view-all"></div>;

const ViewAllBtn = ({viewAll, nrOfItems, expanded, hideNrOfItems, noIcon}: {
	viewAll: () => void,
	nrOfItems: number,
	expanded?: boolean,
	hideNrOfItems?: boolean,
	noIcon?: boolean,
}) => {
	const context = useContext(WidgetContext);

	const buttonContent = useMemo(() => {
		if (noIcon) {
			return (
				<>
					View all {fi(!hideNrOfItems, `(${nrOfItems})`)}
				</>);
		}
		return (
			<>
				{fi(expanded,
					<>View less <ExpandLess/></>,
					<>View all {fi(!hideNrOfItems, `(${nrOfItems})`)} <ExpandMore/></>,
				)}
			</>
		);
	}, [noIcon, hideNrOfItems, nrOfItems, expanded]);

	const cardWidgets = context.filteredWidgets.cardWidgets();

	if (context.widget.type === WidgetType.Homepage) {
		if (!displayShowAllButton(context.widget, nrOfItems)) {
			return emptyDiv;
		}
	} else if (context.widget.type === WidgetType.FAQ) {
		if (Boolean(context.widget.showAll)) {
			return emptyDiv;
		}
	} else if (nrOfItems < 5) {
		return emptyDiv;
	}

	if (cardWidgets.length === 1 && cardWidgets[0]._id === context.widget._id) {
		if (!expanded) {
			viewAll();
			return emptyDiv;
		}
		return emptyDiv;
	}
	return (
		<Wrapper data-testid="view-all-wrapper">
			<Button onClick={viewAll} size="small" data-testid="view-all-btn">
				{buttonContent}
			</Button>
		</Wrapper>
	);
};

export default ViewAllBtn;