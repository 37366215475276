import Toaster from '../components/widgets/ResourceFinder/components/Results/toaster';

declare module 'notistack' {
	interface VariantOverrides {
		downloadToaster: true;
	}
}


export const SnackbarComponent = {
	downloadToaster: Toaster,
};