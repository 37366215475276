import styled from '@emotion/styled';
import React, {Suspense, useEffect} from 'react';
import Card from './Card';
import DownloadActions from '../widgets/commons/DownloadActions';
import {Strings} from '../../utils/strings';
import Loader from '../Loader/Loader';
import {fi} from '../../utils/helpers';
import {Lists} from '../../utils/lists';
import {LibraryItem} from '../../tw/models/__MediaLibrary';

const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    
    &.list {
        gap: 0;
        flex-direction: column;
        max-width: 100%;
        min-width: 0;
        width: 100%;
    }
`;

type GroupProps = {
    items: any,
    allItems?: any,
    label?: string,
    listMode?: boolean,
    itemsOriginalLength?: number,
    hideActions?: boolean,
    selectState?: boolean,
}

const Group = ({items, allItems, label, itemsOriginalLength, hideActions, selectState, listMode}: GroupProps) => {
    const [selectMode, setSelectMode] = React.useState<boolean>(Boolean(selectState));

    useEffect(() => {
        setSelectMode(Boolean(selectState));
    }, [selectState])

    return (
        <>
            <CardList data-testid='cards-list' className={fi(listMode, 'list')}>
                {Lists.default(items).map((item, idx) => (
                    <Card listMode={listMode} item={item} key={`${idx}-${label}`} showCheckbox={selectMode} />
                ))}
            </CardList>

            {fi(hideActions, null,
                <Suspense
                    fallback={(
                        <div className='mt-16 mr-16 flex-row-end'>
                            <Loader size={16}/> &nbsp; Downloading ...
                        </div>
                    )}>
                    <DownloadActions key={`dwd-${label}`}
                                     items={Lists.default<LibraryItem>(items)}
                                     allItems={allItems}
                                     fileName={Strings.default(label, 'documents')}
                                     cb={setSelectMode}
                    />
                </Suspense>)}
        </>
    );
};

export default Group;