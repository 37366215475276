import Add from '@mui/icons-material/Add';
import React from 'react';
import {ISubject, selectedSubjectAtom} from '../SubjectsComponents/utils';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import {Browser} from '../../utils/browser';
import {defaultSubjectAtom, sessionAtom} from '../../state/session';
import {fi} from '../../utils/helpers';
import {findTreeNode} from '../TreeMenu/utils';
import {useMenu} from '../../state/menu';
import {device} from '../../utils/constants';
import {WidgetType} from '../../tw/types';
import {ReactComponent as EditIcon} from '../../assets/images/edit-icon.svg';

const Wrapper = styled.div`
    @media ${device.tablet} {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
    }
`;

const BtnElem = styled(Button)`
    padding: 8px;

    @media ${device.tablet} {
        width: 100%;
        margin-right: 0;

        &:last-of-type {
            margin-top: 8px;
        }
    }
`;

const IconBtnElem = styled.div`
    cursor: pointer;
`
const AddSubjectButton = ({disabled, shouldNavigate, registerMode, iconMode}: {
	disabled: boolean,
	shouldNavigate: boolean
	registerMode?: boolean,
	iconMode?: boolean
}) => {
	const setSelectedSubject = useSetRecoilState(selectedSubjectAtom);
	const session = useRecoilValue(sessionAtom);
	const defaultSubject = useRecoilValue(defaultSubjectAtom);
	const {state: {filteredTree}} = useMenu();

	if (!session) {
		return null;
	}

	const addNewSubject = () => {
		let newSubject: ISubject = {
			qualification: '',
			class_size: 0,
			teaching: '',
			subject: '',
			units: [],
		};
		setSelectedSubject(newSubject);
		if (shouldNavigate) {
			let mySubjects = findTreeNode(filteredTree, (node) => node.object.config.find(c => c.type === WidgetType.MySubject));
			if (mySubjects) {
				Browser.navigate(`${mySubjects.object.getURL()}`);
			}
		}
	};

	return (
		<Wrapper>
			{fi(iconMode,
				<IconBtnElem title={'Edit your subjects'} data-testid='add-new-subject-icon'>
					<EditIcon onClick={addNewSubject}/>
				</IconBtnElem>,
				<>
					<BtnElem data-testid="add-new-subject-btn" className="mr-8" color="primary" variant="contained"
							 size="small"
							 disableRipple
							 startIcon={<Add fontSize={'small'}/>}
							 onClick={addNewSubject}
							 disabled={disabled}>
						Add new subject
					</BtnElem>
					{fi(!registerMode, <BtnElem data-testid="reset-default-subject-btn"
														 color="primary"
														 variant="text"
														 size="small"
														 disabled={defaultSubject === ''}
														 onClick={() => session.setDefaultSubject('')}>
						Reset default subject
					</BtnElem>)}
				</>,
			)}
		</Wrapper>
	);
};

export default AddSubjectButton;