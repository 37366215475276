import {LibraryItem} from './__MediaLibrary';
import {DefaultAction, UUID} from '../types';
import {Strings} from '../../utils/strings';
import React, {ReactElement} from 'react';
import {ReactComponent as LinkIcon} from '../../assets/images/card/link-normal.svg';
import {ReactComponent as LinkIconSmall} from '../../assets/images/card/link-small.svg';
import {ReactComponent as OnlineCourseIcon} from '../../assets/images/card/online-course-normal.svg';
import {ReactComponent as OnlineCourseIconSmall} from '../../assets/images/card/online-course-small.svg';
import {fi} from '../../utils/helpers';
import GA from './GA';
import {disableButton} from '../../components/Card/CardActions/Buttons/utils';
import {Messages} from '../../utils/messages';

const courseTags: string[] = ['#online_course', '#online_course_essential'];

export class Link extends LibraryItem {
	public static markAsSeen: boolean = true;

	public display_text: string;
	public link: string;
	public content_type: UUID;

	public isOnlineCourse: boolean;
	public isEssential: boolean;

	constructor(item: any = {}) {
		super(item);

		this.link = Strings.default(item.link);
		this.display_text = Strings.default(item.display_text);
		this.content_type = Strings.default(item.content_type);

		this.isOnlineCourse = this.isCourse();
		this.isEssential = this.getTags().includes(courseTags[1]);
	}

	public defaultAction(): DefaultAction {
		return {
			label: fi(this.isCourse(), 'Start course', 'Go to'),
			actionHandler: () => {
				if (disableButton(this)) {
					return;
				}
				GA.LinkEvent({
					event_category: 'Outbound Link',
					event_label: this.link,
					event_description: Strings.default(this.description),
					link_name: this.display_text,
					uuid: this.getId(),
					content_type: this.content_type,
					specificationcode: this.assessmentCodes.join(', '),
					unitcomponent: this.unitCodes.join(', '),
					examyear: Strings.default(this.exam_year),
					series: Strings.capitalize(Strings.default(this.series)),
				});
				this.markAsSeen();
				window.open(this.link, '_blank');
			},
		};
	}

	public getIcon(_small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		const Icon = fi(this.isCourse(), fi(_small, OnlineCourseIconSmall, OnlineCourseIcon), fi(_small, LinkIconSmall, LinkIcon));
		return <Icon onClick={defaultAction.actionHandler} title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)} />;
	}

	public hideActions() {
		return this.isCourse() || this.shared;
	}

	public getFlag() {
		if (this.isEssential) {
			return [<span className='essential' key={this.getId()} data-testid={'label-essential'}>Essential</span>];
		}
		if (this.isOnlineCourse) {
			return [];
		}
		return super.getFlag();
	}

	private isCourse() {
		return this.getTags().filter(tag => courseTags.includes(tag)).length > 0;
	}
}