import React, { Suspense } from 'react';
import { theme } from './layout/theme';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import Auth from './Auth';
import { Browser } from './utils/browser';
import PageRouter from './PageRouter';
import PageLoader from './components/Loader/PageLoader';
import GoogleAnalytics from './components/commons/GoogleAnalytics';
import { RecoilEnv } from 'recoil';
import { SnackbarProvider } from 'notistack';
import { SnackbarComponent } from './utils/notistackOverride';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

// useNavigate() forces the component it's used in to render every single time the url changes even though is used
// only to navigate to new pages. So this component extracts the useNavigate function and publishes it to a global
// where it can be used without having to redraw components that don't need to.
const Navigator = () => {
	Browser.navigate = useNavigate();
	return null;
};

const App = ({children}: {children?: any}) => {
	return (
		<SnackbarProvider maxSnack={1} Components={SnackbarComponent}>
			<ThemeProvider theme={theme}>
				<Suspense fallback={<PageLoader/>}>
					<BrowserRouter>
						<Navigator/>
						<Auth/>
						<GoogleAnalytics/>
						<PageRouter/>
						{children}
					</BrowserRouter>
				</Suspense>
			</ThemeProvider>
		</SnackbarProvider>
	);
};

export default App;