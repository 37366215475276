import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from '@emotion/styled';
import SelectComponent, {ISelectValue} from '../../FormComponents/Select/SelectComponent';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
	enableSubjectFilter,
	enableUnitFilter,
	getUnitCodesForURL,
	subjectSelector,
	UnitSelector,
} from '../../../state/product';
import {sessionAtom} from '../../../state/session';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import {horizontalNavAtom, references, scrollTopPosition} from '../../../state/state';
import {Lists} from '../../../utils/lists';
import {Subject, Unit} from '../../../tw/models/Subject';
import {fi} from '../../../utils/helpers';
import {useMediaQuery} from '@mui/material';
import {Messages} from '../../../utils/messages';
import {Browser} from '../../../utils/browser';
import {device} from '../../../utils/constants';
import AddSubjectButton from '../../commons/AddSubjectButton';
import {AlertSeverity, snackbarStateAtom} from '../../Snackbar/SnackbarComponent';
import {CTUnit} from '../../../tw/models/CTUnit';
import {useMenu} from '../../../state/menu';
import CustomMenu from './utils/CustomMenu';
import SingleSubjectValue from './utils/SingleSubjectValue';
import CustomOption from './utils/CustomOption';
import { selectedItems } from '../helpers';

const SubjectElem = styled.div`
    grid-area: subject;
    height: 100%;
	border-right: 1px solid var(--color-grey);

    .MuiFormControl-root {
        margin-bottom: 8px !important;
    }

    @media ${device.tablet} {
        height: 235px;
    }
`;

const TitleElem = styled.div`
    @media ${device.tablet} {
        margin-bottom: 8px;
    }
`;
const StickyContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 16px;
    background-color: var(--color-background);
    position: sticky;

    &.sticky-position {
        z-index: 3;
        top: 69px;
        width: 300px;
        position: fixed;
		border-right: 1px solid var(--color-grey);
    }

    @media ${device.tablet} {
        background-color: var(--color-blue-background-darker);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 235px;
        padding: 16px;
        &.sticky-position {
            width: calc(100% - 75px);
        }
    }

    @media ${device.mobile} {
        height: 235px;
        flex-direction: column;
        &.sticky-position {
            width: 100%;
        }
    }
`;

const UnitElem = styled.div`
    width: 100%;

    @media ${device.mobile} {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        max-width: 100%;
    }
`;

const UnitWrapper = styled.div`
    width: 100%;
    padding-bottom: 8px;
    @media ${device.tablet} {
        padding-bottom: 0;
    }
`;

const MySubjectsSection = styled.div`
    width: 100%;

    hr {
        width: 100%;
    }
`;
const UpdateSubjectsBtn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    div:first-of-type {
        flex: 1;
        color: var(--color-monochrome);
    }
`;
const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: fi(state.isSelected, 'var(--color-grey)', 'transparent'),
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			lineHeight: 'normal',
			color: 'var(--color-black)',
			'&:hover': {
				backgroundColor: 'var(--color-blue-background)',
				'.hidden': {
					display: 'inline',
				},
			},
			'&.subject-option': {
				justifyContent: 'space-between !important',
			},
		}),
		control: (provided, state) => ({
			...provided,
			cursor: 'pointer',
			boxShadow: 'none',
			color: state.isDisabled ? '#9e9e9e' : '#212121',
			whiteSpace: 'nowrap',
			borderColor: fi(state.isFocused, 'var(--color-monochrome)', 'var(--color-grey)'),
			height: 42,
			'.subject': {
				fontWeight: 'bold',
				color: 'var(--color-blue)',
			},
			'&.subjectFilterSelector__control': {
				height: '100%',
			},
			'&:hover': {
				borderColor: 'var(--color-monochrome)',
			},
			'&:active': {
				borderColor: 'var(--color-monochrome)',
			},
		}),
		valueContainer: (provided) => ({
			...provided,
			display: 'flex',
			textOverflow: 'ellipsis',
			maxWidth: '90%',
			whiteSpace: 'nowrap',
			overflowX: 'hidden',
			flexWrap: 'nowrap !important',
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			color: fi(state.isFocused, 'var(--color-monochrome)', 'var(--color-grey)'),
			'&:hover': {
				color: 'var(--color-monochrome)',
			},
			'&:active': {
				color: 'var(--color-monochrome)',
			},
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 3,
		}),
		menuList:
			(provided) => ({
				...provided,
				paddingBottom: 0,
				zIndex: 3,
			}),
	}
;

const SubjectFilter = () => {
	const positionToTop = useRecoilValue(scrollTopPosition);
	const [subject, setSubject] = useRecoilState(subjectSelector);
	const resetSelected = useResetRecoilState(selectedItems);
	const [unit, setUnit] = useRecoilState(UnitSelector);
	const session = useRecoilValue(sessionAtom);
	const enabledSubjectFilter = useRecoilValue(enableSubjectFilter);
	const mobileOrTable = useMediaQuery(device.tablet);
	const firstMounted = useRef(true);
	const enabledUnitFilter = useRecoilValue(enableUnitFilter);

	const [selectedUnits, setSelectedUnits] = useState<any[]>([]);
	const setActiveUnit = useSetRecoilState(UnitSelector);
	const unitURL = Browser.queryParam('unit');

	const horizontalNav = useRecoilValue(horizontalNavAtom);
	const {state: {secondaryMenuVisible}} = useMenu();

	const getUnitsByLabel = () => {
		if (!unitURL || !subject || unitURL === 'all') return [{label: 'All Selected Units', value: 'all'}];
		const unitArray = unitURL.split(',');

		const sbjUnits = subject.getUnits();
		let unitObjects = unitArray.map(u => {
			return sbjUnits.find(unitt => {
				let check = fi(subject.isTechnicals(), (unitt as CTUnit).alias, unitt.code);
				return check === u.toString();
			});
		});
		return Lists.default<Unit>(unitObjects).map(u => ({
			value: u.getId(),
			label: u.displayLabel(),
			object: u,
		}));
	};
	useEffect(() => {
		if (firstMounted.current && subject) {
			firstMounted.current = false;
			setSubject(subject.getId() as any);
			setActiveUnit(getUnitsByLabel());
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subject]);

	const userSubjects = useMemo(() => {
		const subjects: ISelectValue[] = [];

		if (!session) {
			return [];
		}

		session.getQualifications().forEach(q => {
			const qObj = getRecoil(references(q.qualification));
			const sObj = getRecoil(references(q.subject)) as Subject;
			if (qObj && sObj) {
				subjects.push({
					value: q.subject,
					label: `${qObj.displayLabel()}: ${sObj.displayLabel()}`,
					object: q,
				});
			}
		});
		Lists.sort(subjects, 'label');
		return subjects;
	}, [session]);

	const subjectUnits = useMemo(() => {
		if (!subject) {
			return [];
		}

		let units: ISelectValue[] = subject.userUnits().map(userUnit => ({
			value: userUnit.getId(),
			label: userUnit.displayLabel(),
			object: userUnit,
		}));

		if (units.length === 0) {
			return units;
		}
		units = Lists.sort(units, 'label');
		units.unshift({value: 'all', label: 'All Selected Units', object: null});
		return units;
	}, [subject]);

	useEffect(() => {
		if (enabledSubjectFilter) {
			if (!document.location.search) {
				if (subject && unit) {
					let codes = getUnitCodesForURL(unit);
					Browser.setQueryParam({subject: subject.getId(), unit: codes});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document.location.search, subject, unit, enabledSubjectFilter]);

	const updateSelectedUnits = () => {
		setUnit(selectedUnits);
	};

	useEffect(() => {
		updateSelectedUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUnits]);

	if (!subject || !session || !enabledSubjectFilter) {
		return null;
	}

	const onChangeSubject = (selection) => {
		if (selection.value === subject.getId() as any) {
			return;
		}
		setSubject(selection.value);
		resetSelected();
		setRecoil(snackbarStateAtom, {
			message: `${Messages.SubjectChanged}`,
			severity: AlertSeverity.Success,
		});
		setUnit([{label: 'All Selected Units', value: 'all'}]);
	};

	const onChangeUnit = (selection) => {
		//if all units selected and another unit is selected -> deselect all units
		let unitsSelected = selectedUnits.length ? selectedUnits : unit;
		if (unitsSelected.find(u => u.value === 'all') && selection.filter(s => s.value !== 'all').length) {
			setSelectedUnits(selection.filter(s => s.value !== 'all'));
		} else if (selection.find(s => s.value === 'all') || !selection.length || selection.length === subjectUnits.length - 1) {
			//if any units are selected and new selection is all units -> deselect other units
			setSelectedUnits([{label: 'All Selected Units', value: 'all'}]);
		} else {
			setSelectedUnits(selection);
		}
	};

	if (horizontalNav && !secondaryMenuVisible) return null;
	return (
		<SubjectElem data-testid="subject-filter">
			<StickyContainer className={positionToTop ? 'sticky-position' : ''}>
				{fi(mobileOrTable,
					<TitleElem className="text-semiBold text-16 pt-16">Subjects</TitleElem>,
					<TitleElem className="text-regular text-16 pt-16 pb-8">Subject</TitleElem>)}
				<SelectComponent label="" id="subjectFilterSelector"
								 data-testid="subject-selector"
								 values={userSubjects}
								 value={subject.getId()}
								 onChange={onChangeSubject}
								 component={{SingleValue: SingleSubjectValue, Option: CustomOption, Menu: CustomMenu}}
								 hideSelectedOptions={false}
								 styles={customStyles}
								 notNative={true}
				/>

				{fi(subject.isModular() || subject.isTechnicals(),
					<UnitWrapper>
						{fi(!mobileOrTable, <TitleElem className="text-regular text-16 pb-8">Unit</TitleElem>)}
						<UnitElem data-testid="unit-filter">
							<SelectComponent label=""
											 hideSelectedOptions={false}
											 id="unitFilter"
											 data-testid="unit-selector"
											 values={subjectUnits}
											 value={unit}
											 onChange={onChangeUnit}
											 styles={customStyles}
											 readonly={!enabledUnitFilter}
											 checkbox={true}
											 isClearable={false}
											 notNative={true}
							/>
						</UnitElem>
					</UnitWrapper>,
				)}

				{fi(mobileOrTable, <AddSubjectButton disabled={!!session.preview}
													 shouldNavigate={true}/>)}

				{fi(!mobileOrTable,
					<MySubjectsSection data-testid='my-subject-section'>
						<hr/>
						<UpdateSubjectsBtn>
							<TitleElem className="text-regular text-16 pb-8">My subjects</TitleElem>
							<AddSubjectButton disabled={false} shouldNavigate={true} iconMode={true}/>
						</UpdateSubjectsBtn>
					</MySubjectsSection>,
				)}
			</StickyContainer>
		</SubjectElem>
	);
};

export default SubjectFilter;
