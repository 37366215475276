import React from 'react';
import { Switch } from '@mui/material';
import styled from '@emotion/styled';

const ToggleStyled = styled(Switch)(() => (
	{
	padding: 8,

	'& .MuiSwitch-track': {
		opacity: '1 !important',
		borderRadius: 22 / 2,
		'&::before, &::after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
		},

	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
		color: 'var(--color-white)',

		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundImage: 'url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgBfY7NDYAgDIUtcQBHcARGcAxCOLACG+gE6gTqBbg5AiPoOlzA9kDiQWnS9Od9fSk0lZBS8pzz2dYALAEhAzUgpWS89ztTSg0/DhMBtGNIzyhsNAghevoB29U5t5RD0Fp3McYL+xuTA8BhrR3f7lAcUAxfAMUDbSk26WOrCSIAAAAASUVORK5CYII=\')',
	},

	'& .MuiSwitch-switchBase': {
		':hover': {
			backgroundColor: 'transparent !important',
		},

		'&.Mui-checked': {
			'& .MuiSwitch-thumb': {
				backgroundImage: 'url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB9SURBVHgBhc6xDYIAEAXQOxpbR3ABkxvBBufRwkipLRbCBMAETEBuBChgHP7BkZCQQOCXl3c/n2kn8lLpGSUfAIX1Tz4C3e+eBxLpbRMQvg78FgD2v76rbAKRXnyDEdIuDpP5keWhZ5xQG1FDRjKCoo3Dz7Kd5waYb1gDzwBPdkY6oDk+agAAAABJRU5ErkJggg==\')',
			},
		},
	},
}));

const Toggle = ({value, onChange, disabled=false}: {value: boolean, onChange(evt): void, disabled?: boolean}) => {
	return (
		<div data-testid="toggle-elem">
			<ToggleStyled
				checked={value}
				onChange={(evt) => onChange(evt)}
				disableRipple={true}
				disabled={disabled}
			/>
		</div>
	);
};

export default Toggle;
