import React from 'react';
import {Widgets} from '../../tw/models/Page';

export enum HomepageWidgets {
	KeyDates = 'key_dates',
	Events = 'upcoming_events',
	SubjectUpdates = 'subject_updates',
	OnlineCourse = 'online_courses',
	GlobalAnnouncements = 'global_announcements'
}

export type WidgetContextType = {
	widget: any;
	filteredWidgets: Widgets;
	page: any;
}

export const WidgetContext = React.createContext<WidgetContextType>(
	{widget: {}, page: {}, filteredWidgets: new Widgets()} as WidgetContextType,
);
