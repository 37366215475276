import { atom } from 'recoil'

export const selectedTabAtom = atom<string>({
  key: 'selectedTab',
  default: ''
})

export const paginationAtom = atom<any>({
  key: 'pagination',
  default: {
    currentPage: 0,
    itemsPerPage: 20
  }
})

export const actionModalAtom = atom<any>({
  key: 'actionModal',
  default: {
    isActive: false,
    action: null,
    selectedItem: null,
    selectionConfirmed: false
  }
})

export const selectedTabSubjectsAtom = atom<any>({
  key: 'selectedTabSubjects',
  default: []
})
