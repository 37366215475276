import React, {ForwardedRef} from 'react';
import {Lists} from '../../../utils/lists';
import Select from 'react-select';
import WindowedSelect from 'react-windowed-select';
import {ISelectValue} from './SelectComponent';

const CustomList = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
	const hasOptions = Lists.default<ISelectValue>(props.options).find(i => !!i.options);
	if (hasOptions) {
		return <Select ref={ref} {...props} id={`${props.id}-control`}/>;
	}
	return <WindowedSelect ref={ref} {...props} id={`${props.id}-control`}/>;
});

export default CustomList;