import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useRecoilState, useRecoilValue } from 'recoil';
import CheckboxComp from '@mui/material/Checkbox';
import { selectedItems } from '../../../helpers';
import FormControlLabel from '@mui/material/FormControlLabel';
import { sessionAtom } from '../../../../../state/session';
import { LibraryItem } from '../../../../../tw/models/__MediaLibrary';
import { IconButton, useMediaQuery } from '@mui/material';
import { device } from '../../../../../utils/constants';
import SortingButton from './SortingButton';
import DropdownSelection from './DropdownSelection';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { AppsRounded, SubjectRounded } from '@mui/icons-material';
import { fi } from '../../../../../utils/helpers';
import { useLocalStorage } from '../../../../../utils/hooks';
import { SortingAndGroupingTypes } from './utils';
import { searchResultsSelector } from '../../state';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-border-light);
    border-bottom: 1px solid var(--color-border-light);
    padding: 16px;

    svg {
        color: var(--color-lighter-monochrome);
    }
`;

const Splitter = styled.div`
    background: var(--color-border-light);
    width: 1px;
    height: 24px;
    margin: 0 8px;
`;

const HBox = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Button = styled(IconButton)`
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;

    svg {
        color: var(--color-lighter-monochrome);

        width: 85%;
    }

    &.selected {
        background-color: var(--color-blue);

        svg {
            color: var(--color-white)
        }
    }
`;

const Header = () => {
	const session = useRecoilValue(sessionAtom);
	const [displayMode, setDisplayMode] = useLocalStorage('display-mode', 'grid');
	const searchResults = useRecoilValue(searchResultsSelector);
	const [selectedIds, setSelectedIds] = useRecoilState(selectedItems);
	const mobileOrTablet = useMediaQuery(device.mobile);

	// Not all documents are selectable (links) so this filters out from results the one that are not selectable
	const availableForSelection = useMemo(() => {
		let list = searchResults.items.filter(item => item.downloadable());
		if (session?.isTrial() || session?.isRestrictedAccess()) {
			// keep only the items that are publicly available
			list = list.filter(i => (i as LibraryItem).isPubliclyAvailable());
		}
		return list;
	}, [searchResults, session]);

	const selectAllDisabled = useMemo(() => {
		return availableForSelection.length === 0;
	}, [availableForSelection]);

	const selectAllIndeterminate = useMemo(() => {
		return selectedIds.length > 0 && selectedIds.length < availableForSelection.length;
	}, [selectedIds, availableForSelection]);

	const selectAllChecked = useMemo(() => {
		return availableForSelection.length > 0 && selectedIds.length === availableForSelection.length;
	}, [selectedIds, availableForSelection]);


	const onSelectAll = (event: any) => {
		if (event.target.checked) {
			setSelectedIds(availableForSelection.map(i => i.getId()));
		} else {
			setSelectedIds([]);
		}
	};

	const switchDisplayMode = (mode: 'list' | 'grid') => {
		setDisplayMode(mode);
	};

	return (
		<Wrapper>
			<HBox>
				<FormControlLabel
					control={
						<CheckboxComp
							aria-label={'select-items'}
							id={'select-items'}
							title={'Select/Deselect all'}
							name={'select-items'}
							checked={selectAllChecked}
							value={selectAllChecked}
							onChange={onSelectAll}
							color={'primary'}
							size="medium"
							disableRipple
							indeterminate={selectAllIndeterminate}
							disabled={selectAllDisabled}
						/>
					}
					label={'Select all'}
				/>
			</HBox>
			<HBox>
				<SortingButton/>
				<DropdownSelection type={SortingAndGroupingTypes.Sorting}/>
				<Splitter/>
				<FilterAltRoundedIcon fontSize={'small'}/>
				<DropdownSelection type={SortingAndGroupingTypes.Grouping}/>
				{!mobileOrTablet && (
					<div>
						<Button size="small" color="primary" onClick={() => switchDisplayMode('list')}
								title={'List view'}
								className={fi(displayMode === 'list', 'selected')}>
							<SubjectRounded/>
						</Button>
						<Button size="medium" color="primary" onClick={() => switchDisplayMode('grid')}
								title={'Grid view'}
								className={fi(displayMode === 'grid', 'selected')}>
							<AppsRounded/>
						</Button>
					</div>
				)}
			</HBox>
		</Wrapper>
	);
};

export default Header;