import {useRecoilValue} from 'recoil';
import {sessionAtom} from '../../../../state/session';
import {components} from 'react-select';
import React from 'react';

const CustomMenu = (menuProps) => {
	const session = useRecoilValue(sessionAtom);
	if (!session) {
		return null;
	}
	return (
		<components.Menu {...menuProps}>
			{menuProps.children}
		</components.Menu>
	);
};

export default CustomMenu;