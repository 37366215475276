import React from 'react';
import styled from '@emotion/styled';
import Categories from './Categories';
import Form from './Form';

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: 'category' 'filters';
`;

const FilterForm = () => {
	return (
		<Wrapper data-testid="wrapper-finder">
			<Categories/>
			<Form/>
		</Wrapper>
	);
};

export default FilterForm;