import React, { forwardRef, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectedItems } from '../../../helpers';
import { getRecoil } from '../../../../../state/recoilNexus';
import { references } from '../../../../../state/state';
import { MediaItemFile } from '../../../../../tw/models/__MediaLibrary';
import { Objects } from '../../../../../utils/objects';
import { Numbers } from '../../../../../utils/numbers';
import styled from '@emotion/styled';
import { CustomContentProps, SnackbarContent } from 'notistack';
import ClearIcon from '@mui/icons-material/Clear';
import SaveAlt from '@mui/icons-material/SaveAlt';
import { Strings } from '../../../../../utils/strings';
import GA from '../../../../../tw/models/GA';
import Client from '../../../../../tw/client';
import { searchResultsSelector } from '../../state';
import { AlertSeverity, snackbarStateAtom } from '../../../../Snackbar/SnackbarComponent';
import { Browser } from '../../../../../utils/browser';

const Wrapper = styled(SnackbarContent)`
    display: flex;
    align-items: center;
    gap: 64px;

    background-color: var(--color-darker-blue);
    border-radius: 6px;

    padding: 16px;
    color: var(--color-white);
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    & > div {
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }
    }

    & > div:first-of-type {
        color: var(--color-blue-background-darker);
    }
`;

const Toaster = forwardRef<HTMLDivElement, CustomContentProps>(
	({...props}, ref) => {
		const selectedDocs = useRecoilValue(selectedItems);
		const searchResults = useRecoilValue(searchResultsSelector);
		const resetSelected = useResetRecoilState(selectedItems);
		const setSnackbarState = useSetRecoilState(snackbarStateAtom);

		const filesSize = useMemo(() => {
			let totalSize = 0;
			selectedDocs.forEach(doc => {
				const item = getRecoil(references(doc)) as MediaItemFile;
				if (item) {
					const fileInfo = Objects.default(item.fileInfo());
					totalSize += fileInfo.size;
				}
			});
			return Numbers.asFileSize(totalSize);
		}, [selectedDocs]);

		if (!selectedDocs.length) return null;

		const handleDownload = async () => {
			const subjectId = Strings.default(Browser.queryParam('subject'));
			const subject = getRecoil(references(subjectId));

			let fileName = 'TeachCambridge';
			const date = new Date();
			const formattedDate = Strings.default(date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate() + '_' + date.getHours() + date.getMinutes() + date.getSeconds());

			if (subject) {
				fileName = `${Objects.default(subject).displayLabel()}_${formattedDate}`;
			}
			GA.ItemActionEvent('Download selected items', {event_label: fileName});

			const items = searchResults.items.filter(doc => selectedDocs.includes(doc.getId()));

			Client.downloadZip(selectedDocs, fileName).then(() => {
				items.forEach(i => i.resetFlag());
				resetSelected();
				setSnackbarState({
					message: `${Strings.pluralize(selectedDocs.length, 'Item', 'Items')} successfully downloaded`,
					severity: AlertSeverity.Success,
				});
			});
		};


		return (
			<Wrapper ref={ref} data-testid="toaster-dwd">
				<div>{Strings.pluralize(selectedDocs.length, 'resource', 'resources')} selected</div>
				<ActionWrapper>
					<div onClick={() => resetSelected()}>
						<ClearIcon fontSize="small"/>
						<span>Clear selection</span>
					</div>
					<div onClick={handleDownload}>
						<SaveAlt fontSize="small"/>
						<span>Download selected ({filesSize})</span>
					</div>
				</ActionWrapper>
			</Wrapper>
		);
	},
);

export default Toaster;

