import React, {useMemo} from 'react';
import styled from '@emotion/styled';
import {ReactComponent as CentreIcon} from '../assets/images/centre-icon.svg';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {sessionAtom} from '../state/session';
import SocialMediaComponent from '../components/SocialMedia/SocialMediaComponent';
import {Objects} from '../utils/objects';
import {Organization} from '../tw/models/Session';
import {organizationChangeAtom, organizationChangePathAtom} from '../components/ModalDialogs/ChangeOrgModal';
import {Lists} from '../utils/lists';
import {device} from '../utils/constants';
import RelatedSites from '../components/commons/RelatedSites';
import UsefulLinks from '../components/commons/UsefulLinks';

const BlackHeaderElem = styled.div`
    grid-area: blackHeader;
    height: 44px;
    min-height: 44px;
    width: 100%;
    background-color: var(--color-black);
    display: flex;
    align-items: center;
    padding: 0 24px;
    color: var(--color-white);

    button {
        color: var(--color-white);
    }

    div:first-of-type {
        flex: 1;
    }

    svg {
        color: var(--color-white);

        &:not(:first-of-type) {
            margin-left: 24px;
        }
    }


    @media ${device.tablet} {
        padding: 0 16px;
        background-color: var(--color-black);
        border-bottom: solid 1px var(--color-border-light);
        .header-actions {
            display: none;
        }

    }
`;
const OrgSectionElem = styled('div')<{ clickable: boolean }>`
    ${props => props.clickable &&
            `cursor: pointer;
            &:hover{
                text-decoration: underline
            }
    `};
    @media ${device.tablet} {
        color: var(--color-white);
        svg > path:last-child {
            fill: var(--color-white);
        }
    }
`;
const BlackHeader = () => {
	const session = useRecoilValue(sessionAtom);
	const setShowOrgModal = useSetRecoilState(organizationChangeAtom);
	const setOrganizationPath = useSetRecoilState(organizationChangePathAtom);

	const orgName = useMemo(() => {
		if (!session) return '';
		return session.getOrgName();
	}, [session]);

	const changeOrg = () => {
		if (!session) return;
		if (Lists.default<Organization>(session.organizations).length === 1) return;
		setOrganizationPath('Org from header');
		setShowOrgModal(true);
	};
	const showRelatedSites = useMemo(() => {
		if (!session) return false;
		return session.hasFullAccess();
	}, [session]);

	return (
		<BlackHeaderElem data-testid="black-header">
			<OrgSectionElem data-testid="organization-change" className="flex-row-start text-14 text-semiBold"
							onClick={changeOrg}
							clickable={Lists.default<Organization>(Objects.default(session).organizations).length > 1}>
				<CentreIcon className="mr-8"/> {orgName}
			</OrgSectionElem>

			<div className="header-actions flex-row-space-between">
				<UsefulLinks/>
				{showRelatedSites && <RelatedSites/>}
				<SocialMediaComponent/>
			</div>
		</BlackHeaderElem>
	);
};

export default BlackHeader;