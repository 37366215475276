import {getRecoil} from '../../../../state/recoilNexus';
import {defaultSubjectAtom, sessionAtom} from '../../../../state/session';
import {Strings} from '../../../../utils/strings';
import {fi} from '../../../../utils/helpers';
import {components} from 'react-select';
import Divider from '@mui/material/Divider';
import React from 'react';
import styled from '@emotion/styled';


const SubjectStatus = styled.span<{default: boolean}>`
    color: var(--color-backdrop);
    font-size: 14px;
    font-weight: ${props => fi(props.default, 'bold', 'normal')};

    &:hover {
        cursor: ${props => fi(props.default, 'auto', 'pointer')};
    }

    .hidden {
        display: none;
    }

    & > span {
        color: var(--color-blue);
        font-weight: bold;
    }
`;

const CustomOption = (optionProps) => {
	const defaultSubject = getRecoil(defaultSubjectAtom);
	const session = getRecoil(sessionAtom);
	const [qualification, ...subject] = Strings.default(optionProps.children).split(':');

	if (!session) {
		return null;
	}

	const subjectStatus = () => {
		return (
			<SubjectStatus default={defaultSubject === optionProps.data.value}>
				{fi(defaultSubject === optionProps.data.value, ' - default', '')}
			</SubjectStatus>
		);
	};

	return (
		<>
			<components.Option {...optionProps} className="subject-option">
				<span>{qualification}: <strong>{subject.join(':')}</strong>{subjectStatus()}</span>
			</components.Option>
			<Divider className="option-divider"/>
		</>
	);
}

export default CustomOption