import React, { useMemo } from 'react';
import { ITreeItem } from './utils';
import styled from '@emotion/styled';
import { useMenu } from '../../state/menu';
import { fi } from '../../utils/helpers';
import { device } from '../../utils/constants';
import { useMediaQuery } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from '@mui/material/Popover';
import { Strings } from '../../utils/strings';
import { useRecoilValue } from 'recoil';
import { horizontalNavAtom } from '../../state/state';
import { enableSubjectFilter } from '../../state/product';

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--color-white);
    width: 80px;
    user-select: none;
	
	& > div:first-of-type {
		margin-top:24px;
	}

    & > div > div > div > div > p {
        font-family: var(--font-semi-bold);
    }

    @media ${device.mobile} {
        flex-direction: row;
        width: 100%;

        & > div:first-of-type {
            margin-top:0;
        }
    }
`;

const RootTreeItem = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    margin-bottom: 24px;
    color: var(--color-menu-grey);
    padding-left: -5px;
    border-left: 5px solid white;
    cursor: pointer;


    &.selected {
        color: var(--color-blue);
        border-left: 5px solid var(--color-blue);

        img {
            filter: invert(15%) sepia(41%) saturate(1368%) hue-rotate(178deg) brightness(86%) contrast(90%)
        }

        svg {
            path {
                fill: var(--color-blue) !important;
            }
        }
    }

    @media ${device.mobile} {
        &.selected {
            border-left: unset;
        }

        padding: 8px;
        margin-bottom: 0;
        border-left: 0;
    }
`;

const PIcon = styled.div`
    //margin-bottom: 6px;

    &.selected {
        color: var(--color-blue);

        svg {
            path {
                fill: var(--color-blue) !important;
            }
        }
    }
`;

const PLabel = styled.div`
    line-height: 16px;
    text-align: center;

    &.ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 56px;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    &.single-word-ellipsis {
        display: inline-block;
        overflow: hidden;
        width: 55px;
        text-overflow: ellipsis;
    }

    &.ellipsis-mobile {
        overflow: hidden;
        width: 55px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.selected {
        color: var(--color-blue);
    }
`;

const PagesPopover = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px;

    div {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-left: unset;
    }
`;
const RootTreeComponent = () => {
	const {setRootPage, state: {filteredTree, subTree, rootPage, toggleVisibleMenu}} = useMenu();
	const mobile = useMediaQuery(device.mobile);
	const popupState = usePopupState({variant: 'popover', popupId: 'more-pages'});
	const horizontalNav = useRecoilValue(horizontalNavAtom);
	const subjectFilterEnabled = useRecoilValue(enableSubjectFilter);

	const onClick = (item: ITreeItem) => {
		if (item.object.isLinkPage()) {
			window.open(item.object.link, '_blank');
		} else {
			setRootPage(item);
			if (rootPage && rootPage.id !== item.id) {
				toggleVisibleMenu(true);
			}
		}
		popupState.close();
	};

	const mainPages = useMemo(() => {
		if (mobile && filteredTree.length > 5) {
			return filteredTree.slice(0, 4);
		}
		return filteredTree;
	}, [mobile, filteredTree]);

	const moreSelected = useMemo(() => {
		let pageIds = filteredTree.map(t => t.id);
		return pageIds.indexOf(Strings.default(rootPage?.id)) >= 4;
	}, [rootPage]);

	const getClasses = (item: ITreeItem): string => {
		return item.object.displayLabel().split(' ').length > 1 ? 'ellipsis' : 'single-word-ellipsis'
	}

	return (
		<ListWrapper data-depth={0} data-parentid={null} data-testid="root-tree-component-wrapper">
			{mainPages.map((item, index) => (
				<RootTreeItem key={`${item.id}-rootPage`} className={item.id === rootPage?.id ? 'selected' : ''}
							  onClick={() => onClick(item)} data-testid="root-tree-item">
					<PIcon data-testid="page-icon">{item.object.getPageIcon()}</PIcon>
					<PLabel
						className={mobile ? 'ellipsis-mobile' : getClasses(item)}
						title={item.object.displayLabel()}
						data-testid="page-label">{item.object.displayLabel()}</PLabel>
				</RootTreeItem>
			))}
			{fi(mobile && filteredTree.length > 5,
				<RootTreeItem {...bindTrigger(popupState)} data-testid="more-pages">
					<PIcon data-testid="page-icon"
						   className={popupState.isOpen || moreSelected ? 'selected' : ''}><MoreVert/></PIcon>
					<PLabel data-testid="page-label"
							className={popupState.isOpen || moreSelected ? 'selected' : ''}>More</PLabel>
				</RootTreeItem>, null)}
			<Popover {...bindPopover(popupState)}
					 anchorOrigin={{vertical: 'top', horizontal: 'center'}}
					 transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
					 data-testid="more-pages-popover"
			>
				<PagesPopover>
					{filteredTree.slice(4, filteredTree.length).map((item, index) => (
						<RootTreeItem key={`${item.id}-rootPage`} className={item.id === rootPage?.id ? 'selected' : ''}
									  onClick={() => onClick(item)} data-testid="root-tree-item">
							<PIcon data-testid="page-icon">{item.object.getPageIcon()}</PIcon>
							<PLabel data-testid="page-label">{item.object.displayLabel()}</PLabel>
						</RootTreeItem>
					))}
				</PagesPopover>
			</Popover>
		</ListWrapper>
	);
};

export default RootTreeComponent;