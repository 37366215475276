import React, {useEffect, useMemo, useState} from 'react';
import Menu from './Menu';
import styled from '@emotion/styled';
import SubjectFilter from '../components/widgets/SubjectFilter/SubjectFilter';
import {device} from '../utils/constants';
import SharedItemModal from '../components/ModalDialogs/SharedItemModal';
import {useMenu} from '../state/menu';
import {useMediaQuery} from '@mui/material';
import HMenu from './HMenu';
import SubjectTitle from './SubjectTitle';
import {WidgetType} from '../tw/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {horizontalNavAtom} from '../state/state';
import {enableSubjectFilter} from '../state/product';

const PageLayoutElem = styled('div')<{showMenu: boolean, showLeftSubjFilter: boolean}>`
    display: grid;
    grid-area: main;
    grid-template-areas:
  'subject subject'
  'nav pageContent';
    grid-template-rows: auto 1fr;
    grid-template-columns: 300px 1fr;
    height: 100%;
    //overflow: hidden;
    background-color: var(--color-background);
    align-items: start;

    ${props => !props.showMenu && `grid-template-areas: 'subject' 'pageContent';
    		grid-template-columns: 1fr;`} ;

    @media ${device.tablet} {
        grid-template-areas:
  				'subject subject'
				'pageContent pageContent';
        grid-template-columns: 0 1fr;
    }

    &.horizontal {
        grid-template-rows:  100px 80px auto;
        grid-template-columns: 300px 1fr;
        grid-template-areas: 'subject title'
							 'subject nav'
							 'subject pageContent';

        #page-content, .details-page {
            background-color: var(--color-white);
            margin: 0 16px 16px;
            border: 1px solid var(--color-border-light);
            border-top: none;
            border-radius: 0 0 6px 6px;
        }

        //navigation exists && subj filter is hidden
        ${props => !props.showLeftSubjFilter && props.showMenu && `
        	grid-template-rows: 100px 80px auto;
        	grid-template-columns: 1fr;
    		grid-template-areas:
    						'title'
    						'nav'
							'pageContent';
        	padding-top: 16px;
        	#hmenu{
        		border-top: solid 1px var(--color-border-light);
        		border-radius: 6px 6px 0 0;
        	}
        `} ;

        //navigation doesn't exist & subj filter hidden
        ${props => !props.showMenu && !props.showLeftSubjFilter && `grid-template-rows: 100px auto;
        	 grid-template-columns: 1fr;
    		 grid-template-areas: 'title'
							    'pageContent';`} ;

        //subject filter is visible & navigation exists
        ${props => !props.showMenu && props.showLeftSubjFilter && `grid-template-rows: 100px auto;
        	 grid-template-columns: 300px 1fr;
    		 grid-template-areas:
    		 				 'subject title'
							 'subject pageContent';`} ;
    }
`;
const PageLayout = ({children}: any) => {
	const {toggleVisible, state: {subTree, secondaryMenuVisible, rootPage}} = useMenu();
	const mobile = useMediaQuery(device.mobile);
	const tablet = useMediaQuery(device.tablet);
	const setHorizontalNavAtom = useSetRecoilState(horizontalNavAtom);
	const subjectFilterEnabled = useRecoilValue(enableSubjectFilter);
	const [horizontalNav, setHorizontalNav] = useState<boolean>(false);

	useEffect(() => {
		toggleVisible(true);
		return () => {
			toggleVisible(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!rootPage || tablet) {
			setHorizontalNavAtom(false);
			return;
		}

		const hasWidget = rootPage.object.hasWidget(WidgetType.SubjectFilter);
		setHorizontalNavAtom(hasWidget);
		setHorizontalNav(hasWidget);
	}, [rootPage, tablet]);

	const showMenu = useMemo(() => {
		if (!subTree.length && !mobile) return false;
		return horizontalNav ? true : secondaryMenuVisible;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secondaryMenuVisible, subTree]);

	const showLeftSubjFilter = useMemo(() => {
		if (!subjectFilterEnabled) return false;
		return secondaryMenuVisible;
	}, [secondaryMenuVisible, subjectFilterEnabled]);
	return (
		<PageLayoutElem data-testid="page-layout" showMenu={showMenu} className={horizontalNav ? 'horizontal' : ''}
						showLeftSubjFilter={showLeftSubjFilter}>
			<SubjectFilter/>
			{horizontalNav && <SubjectTitle/>}
			{horizontalNav ? <HMenu/> : <Menu/>}
			<SharedItemModal/>
			{children}
		</PageLayoutElem>
	);
};

export default PageLayout;
