import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {showTourAtom} from '../../state/state';
import {sessionAtom} from '../../state/session';
import {trialModalAtom} from '../ModalDialogs/TrialIntroModal';
import {feedbackAtom} from '../../state/feedback';
import Backdrop from './Backdrop';
import Tour from './Tour';
import {useMenu} from '../../state/menu';
import {findTreeNode, ITreeItem, pageSelector} from '../TreeMenu/utils';


const UserTourComponent = () => {
	const [tourState, setTourState] = useRecoilState(showTourAtom);
	const trialModalState = useRecoilValue(trialModalAtom);
	const feedbackModalState = useRecoilValue(feedbackAtom);
	const {state: { filteredTree}} = useMenu();
	const session = useRecoilValue(sessionAtom);
	const allPages = useRecoilValue(pageSelector);

	const showTour = (): boolean => {
		let tourPages: ITreeItem[] = [];
		findTreeNode(filteredTree, (i) => {
			if (i.object.userTourPage()) {
				tourPages.push(i);
			}
			return false;
		});
		if (!tourPages.length) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (!session || !showTour()) {
			return;
		}

		if (session.isTrial() && !trialModalState && !feedbackModalState) {
			setTourState((val) => val + 1);
			return;
		}

		if (!session.isTrial() && !feedbackModalState) {
			setTourState((val) => val + 1);
			return;
		} else {
			setTourState(0);
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session, feedbackModalState, trialModalState]);

	if (tourState === 0 || !session || !session.getQualifications().length || !filteredTree.length || !showTour()) {
		document.getElementsByTagName('html')[0].classList.remove('tour');
		return null;
	}

	return (
		<>
			<Tour session={session} state={tourState} pageTree={filteredTree} allPages={allPages}/>
			<Backdrop/>
		</>
	);
};

export default UserTourComponent;