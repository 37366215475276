import React, {useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import {UUID} from '../../../tw/types';
import Client from '../../../tw/client';
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {selectedItems} from '../helpers';
import {fi, suspensePromise} from '../../../utils/helpers';
import {AlertSeverity, snackbarStateAtom} from '../../Snackbar/SnackbarComponent';
import {sessionAtom} from '../../../state/session';
import {LibraryItem} from '../../../tw/models/__MediaLibrary';
import {useParams} from 'react-router-dom';
import {device, ROUTE_NEW_UPDATED, ROUTE_RESOURCE_FINDER} from '../../../utils/constants';
import GA from '../../../tw/models/GA';
import styled from '@emotion/styled';
import {Objects} from '../../../utils/objects';
import {getRecoil} from '../../../state/recoilNexus';
import {cacheBuster} from '../../../state/state';
import {computeFilesSize} from './utils';

const Wrapper = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
    margin-right: 54px;
    margin-top: 8px;

    &.skinny-variant {
        margin-right: 25px;
    }

    &.relative-pos {
        position: relative !important;
        display: flex;
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 0;
        width: 100%;
        position: relative;

        button {
            padding: 0;
        }
    }
`;

export const DownloadActions = ({items, fileName, cb, allItems}: {
	items: LibraryItem[],
	fileName: string,
	cb?: (selectMode: boolean) => void
	allItems?: LibraryItem[],
}) => {
	useParams();
	const [selectMode, setSelectMode] = useState<boolean>(false);
	const selectedIds = useRecoilValue(selectedItems);
	const session = useRecoilValue(sessionAtom);
	const setSnackbarState = useSetRecoilState(snackbarStateAtom);
	const resetSelected = useResetRecoilState(selectedItems);
	const [promise, setPromise] = useState<any>(null);
	const forceResetState = useRecoilValue(cacheBuster('resetSelectCardState'));

	const reset = () => {
		setSelectMode(false);
		resetSelected();
		if (cb) {
			cb(false);
		}
	};

	// the list of downloadable items (only Documents, Images, Audio and ZIP)
	const dwdItems = useMemo(() => {
		let list = allItems?.length ? allItems : items;
		const user = getRecoil(sessionAtom);
		if (user && (user.isTrial() || user.isRestrictedAccess())) {
			return list.filter(i => i.downloadable() && i.isPubliclyAvailable());
		}
		return list.filter(item => item.downloadable());
	}, [allItems, items]);

	const dwdItemsIds = useMemo(() => {
		return dwdItems.map(i => i.getId());
	}, [dwdItems]);

	const cardIsSkinny = useMemo(() => {
		if (!items.length) return false;
		const card = document.querySelector(`[data-cardid="${Objects.default(items[0]).getId()}"]`);
		if (!card) return false;
		return card.classList.contains('skinny');
	}, [items]);

	const changePosition = useMemo(() => {
		const pathName = document.location.pathname;
		return pathName.includes(ROUTE_NEW_UPDATED) || pathName.includes(ROUTE_RESOURCE_FINDER);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document.location.pathname]);

	useEffect(() => {
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceResetState]);

	const fileSize = useMemo(() => {
		return computeFilesSize(dwdItemsIds, dwdItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIds, dwdItems]);

	if (promise) {
		promise?.read();
		setPromise(null);
		reset();
		setSnackbarState({
			message: 'Item/s successfully downloaded',
			severity: AlertSeverity.Success,
		});
	}

	if (items.length === 1 || !items.filter(item => item.downloadable()).length) {
		return null;
	}

	const onDownload = async (selectedIdss: UUID[]) => {
		GA.ItemActionEvent(fi(selectedIdss.length === dwdItemsIds.length, 'Download All', 'Download selected items'), {event_label: fileName});

		let toDwd = dwdItems.filter(i => selectedIdss.includes(i.getId()));
		if (session && (session.isTrial() || session.isRestrictedAccess())) {
			// keep only the items that are publicly available
			toDwd = toDwd.filter(i => i.isPubliclyAvailable());
		}
		const pr = Client.downloadZip(toDwd.map(i => i.getId()), fileName).then(() => {
			toDwd.forEach(i => {
				i.resetFlag();
				i.addToLocalStorage();
			});
		});
		setPromise(suspensePromise(pr));
	};

	const onSelectAction = () => {
		setSelectMode(true);
		if (cb) {
			cb(true);
		}
	};

	return (
		<Wrapper data-testid="download-buttons"
				 className={`${fi(cardIsSkinny, 'skinny-variant')} ${fi(changePosition, 'relative-pos')} download-buttons`}>
			{fi(selectMode,
				<>
					<Button size="small" data-testid="cancel-selected" onClick={reset}>Cancel</Button>
					{fi(selectedIds.length,
						<Button size="small" data-testid="dwd-selected"
								onClick={() => onDownload(selectedIds)}
								title={`Download ${selectedIds.length} files as ZIP (${fileSize})`}
						>
							Download selected items
							({selectedIds.length})
						</Button>)}
				</>,
				<>
					<Button size="small"
							data-testid="select-for-dwd"
							title="Select items to download"
							onClick={onSelectAction}
					>
						Select items to download
					</Button>
					<Button size="small"
							data-testid="select-all-for-dwd"
							title={`Download all files from this group as a ZIP file (${fileSize})`}
							onClick={() => onDownload(dwdItemsIds)}
					>
						Download all ({dwdItemsIds.length})
					</Button>
				</>,
			)}
		</Wrapper>
	);
};

export default DownloadActions;