import {Lists} from '../../utils/lists';
import {fi} from '../../utils/helpers';
import SubjectEdit from './SubjectEdit';
import SubjectPreview from './SubjectPreview';
import {Objects} from '../../utils/objects';
import React, {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {ISubject, selectedSubjectAtom} from './utils';
import {Divider} from '@mui/material';
import {productTreeSelector} from '../../state/product';
import {defaultSubjectAtom} from '../../state/session';
import {Strings} from '../../utils/strings';


const SubjectsComponent = ({userData, hideActions, keepOrder, redirectPageId}: {
	userData: any[],
	hideActions?: boolean,
	keepOrder?: boolean
	redirectPageId?: string
}) => {
	useRecoilValue(productTreeSelector);

	const selectedSubject = useRecoilValue(selectedSubjectAtom);
	const defaultSubject = useRecoilValue(defaultSubjectAtom);

	const orderedArray = useMemo(() => {
		let tmp = [...Lists.default<ISubject>(userData)];

		if (keepOrder) {
			return tmp;
		}

		return tmp.reverse();
	}, [userData]);

	return (
		<div className="mt-32" data-testid="preferences-wrapper">
			{fi(Lists.default(userData).length === 0 || (selectedSubject && selectedSubject.qualification === ''),
				<SubjectEdit/>)}
			{Lists.default<ISubject>(orderedArray).map((q, idx) => (
				<div key={q.subject}>
					{fi(Objects.default(selectedSubject).subject === q.subject,
						<SubjectEdit key={`${q.subject}-edit`}/>,
						<SubjectPreview qualification={q} key={`${q.subject}-preview`} hideActions={hideActions}
										defaultSubject={Strings.default(defaultSubject)} redirectPageId={redirectPageId}/>)}
					{idx < orderedArray.length - 1 && <Divider/>}
				</div>
			))}
		</div>
	);
};

export default SubjectsComponent;
