import {useSetRecoilState} from 'recoil';
import {enableSubjectFilter} from '../../../state/product';
import {useEffect} from 'react';

const FilterSubjectWidget = () => {
    const setEnableSubjectFilter = useSetRecoilState(enableSubjectFilter);

	useEffect(() => {
        setEnableSubjectFilter(true);
        return () => {
            setEnableSubjectFilter(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
export default FilterSubjectWidget;