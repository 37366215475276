import { atom, selector } from 'recoil';
import Client from '../tw/client';
import { EventsAir } from '../tw/models/EventsAir';
import { EventMaterial } from '../tw/models/EventMaterial';
import { Lists } from '../utils/lists';

export enum EventsType {
	Past = 'past_events',
	Active = 'active_events',
	All = 'all_events'
}

export const selectedEvent = atom<EventsAir | null>({
	key: 'selectedEventAtom',
	default: null,
});
export const eventsTypeAtom = atom<EventsType | null>({
	key: 'eventsTypeAtom',
	default: null,
});
export const eventsCodesAtom = atom<string[]>({
	key: 'eventsCodesAtom',
	default: [],
});

const loadEventsSelector = selector<EventsAir[]>({
	key: 'loadEventsSelector',
	get: async ({get}) => {
		if (!get(eventsCodesAtom).length) return [];
		return await Client.getEvents(get(eventsCodesAtom));
	},
});

export const eventsSelector = selector<EventsAir[] | null>({
	key: 'eventsSelector',
	get: async ({get}) => {
		let events = get(loadEventsSelector);
		const eventsType = get(eventsTypeAtom);
		if (eventsType === EventsType.All) {
			return events;
		}
		return events.filter((e) => {
			const eventStartDate = new Date(e.startDate).getTime();
			let now = new Date();
			now.setHours(0, 0, 0);
			const today = now.getTime();
			if (eventsType === EventsType.Active) {
				return eventStartDate > today;
			} else {
				return eventStartDate < today && Lists.default<EventMaterial>(e.eventMaterials).length;
			}
		});
	}
});