import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import React, {useEffect} from 'react';
import {selectedSubjectUpdate, subjectUpdateSelector} from '../../../state/subjectUpdate';
import {Browser} from '../../../utils/browser';
import {ROUTES} from '../../../utils/routes';
import DetailsPage from '../commons/DetailsPage';
import {selectedObject} from '../../../state/state';
import {setRecoil} from '../../../state/recoilNexus';
import Wysiwyg from '../wysiwyg/Wysiwyg';
import {enableSubjectFilter} from '../../../state/product';
import DetailsHeading from './components/DetailsHeading';
import DetailsFooter from './components/DetailsFooter';
import {useMenu} from '../../../state/menu';
import { WidgetType } from '../../../tw/types';

const SubjectUpdateDetails = () => {
	const [subjectUpdate, setSubjectUpdate] = useRecoilState(selectedSubjectUpdate);
	const subjectsList = useRecoilValue(subjectUpdateSelector);
	const setEnableSubjectFilter = useSetRecoilState(enableSubjectFilter);
	const {pageId, pageSlug, id} = Browser.getParams(ROUTES);
	const {setPage, state: {selectedPage}} = useMenu();

	useEffect(() => {
		if (selectedPage) {
			//if selected page has subject filter, display it on the details page
			if (selectedPage.object.config.find(c => c.type === WidgetType.FilterByQualification)){
				setEnableSubjectFilter(true);
			}
		}
		return () => {
			setEnableSubjectFilter(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPage]);

	useEffect(() => {
		subjectUpdate?.markPageAsSeen();
	}, [subjectUpdate]);

	useEffect(() => {
		if (!subjectsList.length) return;
		let selectedItem = subjectsList.find(s => s.getId() === id);
		if (selectedItem) {
			if (!subjectUpdate && id) {
				setSubjectUpdate(selectedItem);
				setRecoil(selectedObject, selectedItem);
			}
		} else {
			Browser.navigate(`/${pageId}/${pageSlug}${document.location.search}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, subjectsList]);

	if (!subjectUpdate) return null;

	const goBack = () => {
		if (selectedPage)
			setPage(selectedPage);
	};

	return (
		<DetailsPage heading={<DetailsHeading subjectUpdate={subjectUpdate}/>}
					 content={<Wysiwyg item={subjectUpdate} id={subjectUpdate.getId()} fieldId="text"/>}
					 footer={<DetailsFooter subjectUpdate={subjectUpdate} goBack={goBack}/>}/>

	);
};

export default SubjectUpdateDetails;