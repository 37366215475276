import {CMSObject, DisplayMode} from './__CMSObject';
import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {ContentTyped, MediaItemFile} from './__MediaLibrary';
import {getRecoil} from '../../state/recoilNexus';
import {ContentType} from './ContentType';
import {references} from '../../state/state';
import {CMSFile} from './File';
import {filteredDocumentsSelector} from '../../components/widgets/ResourceFinder/utils';
import {GroupingRule, SortingRule} from '../../components/widgets/ResourceFinder/components/Results/utils';

const SortingTags: string[] = ['#series_asc', '#series_desc', '#year_asc', '#year_desc', '#paper_asc', '#paper_desc', '#by_content'];
const GroupingTags: string[] = ['#group_by_type', '#group_by_year', '#group_by_series'];

export class ContentGroup extends CMSObject {
	public name: string;
	public description: string;
	public content_types: UUID[];

	constructor(obj: any = {}) {
		super(obj);
		this.name = Strings.default(obj.name);
		this.description = Strings.default(obj.description);
		this.content_types = Lists.default(obj.content_types);
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}

	public contentTypes(): ContentType[] {
		return this.content_types.map(id => getRecoil(references(id)) as any as ContentType).filter(a => a);
	}

	// how many documents are mapped against the content types within this content group
	public documentCount(): number {
		const docs = this.documents();
		return docs.length;
	}

	// retrieves total size of all documents mapped against the content types within this content group
	public documentSize(): number {
		const docs = this.documents();
		let size = 0;
		docs.forEach(doc => {
			const file = getRecoil(references(doc.file)) as any as CMSFile;
			if (file) {
				size += file.size;
			}
		});
		return size;
	}

	public defaultSortingRules(): SortingRule[] {
		const sortingRules = this.getTags()
			.filter(t => SortingTags.includes(t))
			.map(t => {
				if (t.slice(1) === 'by_content') {
					return {type: 'content_type', order: 'default'}
				}
				let [type, order] = t.slice(1).split('_');
				switch (type) {
					case 'series' :
						type = 'series_no';
						break;
					case 'paper':
						type = 'paper_number';
						break;
					case 'year':
						type = 'exam_year';
						break;
					default:
						break;
				}
				return {type, order} as SortingRule;
			}).map(sr => {
				if (sr.type === 'type') {
					const ct = this.contentTypes();
					sr.ruling = {};
					for (let i = 0; i < ct.length; i++) {
						sr.ruling[ct[i].getId()] = i;
					}
				}
				return sr;
			});

		return Lists.default(sortingRules);
	}

	public defaultGroupingRule(): GroupingRule {
		const groupingRule = this.getTags()
			.filter(t => GroupingTags.includes(t))
			.map(t => {
				const type = t.slice(10);
				switch (type) {
					case 'type':
						return GroupingRule.ContentType;
					case 'year':
						return GroupingRule.Year;
					case 'series':
						return GroupingRule.Series;
					default:
						return GroupingRule.None;
				}
			})[0];

		return groupingRule;
	}

	// retrieves all items as MediaItemFile objects mapped against the content types within this content group
	public documents(): MediaItemFile[] {
		const filteredItems = getRecoil(filteredDocumentsSelector);
		return Lists.default<any>(filteredItems).filter((item: CMSObject) => this.content_types.includes((item as any as ContentTyped).content_type)).filter(a => a) as any as MediaItemFile[];
	}
}