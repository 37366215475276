import React, {ReactElement, useEffect} from 'react';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import {useSetRecoilState} from 'recoil';
import {selectedObject} from '../../../state/state';
import LowerSection from '../../LowerSection/LowerSection';

const WrapperElem = styled.div`
    grid-area: pageContent;
    background-color: var(--color-background);
    padding: 32px;
`;

const HeadingElem = styled.div`
    margin-bottom: 24px;
`;
const ContentElem = styled.div`
    margin-bottom: 24px;
`;
const FooterElem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    margin-bottom: 24px;
`;

const DetailsPage = ({heading, content, footer}: {
	heading: ReactElement,
	content: ReactElement,
	footer: ReactElement
}) => {
	const setSelectedObject = useSetRecoilState(selectedObject);

	useEffect(() => {
		return () => {
			setSelectedObject(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<WrapperElem className='details-page'>
			<Breadcrumbs/>
			<HeadingElem>
				{heading}
			</HeadingElem>
			<ContentElem>
				{content}
			</ContentElem>
			<Divider/>
			<FooterElem>
				{footer}
			</FooterElem>
			<LowerSection/>
		</WrapperElem>
	);
};

export default DetailsPage;