import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {atom, selector, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {resourceFinderDataSelector} from '../../utils';
import {ISelectValue} from '../../../../FormComponents/Select/SelectComponent';
import {ContentCategory} from '../../../../../tw/models/ContentCategory';
import Button from '@mui/material/Button';
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import {UUID} from '../../../../../tw/types';
import {fi, resetAtomFamily} from '../../../../../utils/helpers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {WidgetContext} from '../../../widget';
import {ContentGroup} from '../../../../../tw/models/ContentGroup';
import styled from '@emotion/styled';
import {references} from '../../../../../state/state';
import {getRecoil, setRecoil} from '../../../../../state/recoilNexus';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {contentGroupsTagRules, resourceFinderFilterSelector} from '../../state';
import {Lists} from '../../../../../utils/lists';
import {useMenu} from '../../../../../state/menu';
import {contentCategoryGroupingRuleAtom, defaultGroupingAtom} from '../Results/utils';
import {Objects} from '../../../../../utils/objects';
import content from '../../../../../layout/Content';

const Wrapper = styled.div<any>`
    grid-area: category;
    display: flex;
    color: var(--color-lighter-monochrome);
    font-family: var(--font-semi-bold);
    max-width: 100%;
    position: relative;
    margin-bottom: 16px;

    & > * {
        display: flex;
        align-items: center;
    }
`;

const ListWrapper = styled('div')<{scroll?: boolean}>`
    & > div {
        padding: 0 24px;
        border-right: 1px solid var(--color-border-light);

        &:first-of-type {
            padding-left: 0
        }

        &:last-of-type {
            padding-right: 0;
            border-right: none;
        }

        & > button {
            padding: 0;
            color: var(--color-lighter-monochrome);
            font-family: var(--font-semi-bold);
            line-height: 20px;
            font-size: 16px;
            //max-width: 200px;

            :hover {
                background: none;
            }

            :active {
                box-shadow: none;
            }

            & > svg {
                margin-left: 4px;
                font-size: 16px;
            }

            & > span {

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
            }
        }

        .selected {
            color: var(--color-blue);
        }
    }
`;

const RightArrow = styled.div`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    display: flex;
    align-items: center;
`;

const LeftArrow = styled.div`
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    display: flex;
    align-items: center;
`;

const RightGradient = styled.div`
    position: absolute;
    right: 24px;
    top: 0;
    bottom: 0;
    width: 80px;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;

const LeftGradient = styled.div`
    z-index: 1;
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    width: 80px;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(0, 0, 0, 0) 100%);
`;

const ScrollWrapper = styled.div<{scroll?: boolean}>`
    margin-left: ${props => props.scroll && `30px;`}
    scrolbar-width: none;

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    & * {
        display: flex;
    }
`;

type CategoriesModel = {
	groupLabel: string;
	groupId: UUID;
	groupList: ISelectValue[];
}

export const selectedGroupAtom = atom<string>({
	key: 'selectedGroup',
	default: '',
});

export const selectedGroupOrCategoryAtom = atom<ContentCategory | ContentGroup | null>({
	key: 'selectedGroupOrCategoryAtom',
	default: null,
});

export const inCategoryLevelAtom = selector<boolean>({
	key: 'inCategoryLevelAtom',
	get: ({get}) => {
		const selectedGroupOrCategory = get(selectedGroupOrCategoryAtom);
		return (selectedGroupOrCategory instanceof ContentCategory);
	},
});

const ItemList = ({category, onClickCb}: {category: CategoriesModel, onClickCb?: (value: string) => void}) => {
	const popupState = usePopupState({variant: 'popover', popupId: category.groupId});
	const selectedGroup = useRecoilValue(selectedGroupAtom);

	const onClick = (value: string) => {
		popupState.close();
		if (onClickCb) {
			onClickCb(value);
		}
	};

	return (
		<div data-testid={`category-${category.groupId}`}>
			<Button {...bindTrigger((popupState))} disableRipple
					className={category.groupList.findIndex(g => g.value === selectedGroup) > -1 ? 'selected' : ''}>
				<span title={category.groupLabel}>{category.groupLabel}</span>
				{fi(popupState.isOpen, <ExpandLessIcon fontSize="small"/>, <ExpandMoreIcon fontSize="small"/>)}
			</Button>
			{popupState.isOpen &&
				<Menu {...bindMenu(popupState)}
					  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
					  transformOrigin={{vertical: 'top', horizontal: 'left'}}
				>
					{category.groupList.map((item, idx) => (
						<MenuItem key={item.value} value={item.value}
								  className={selectedGroup === item.value ? 'selected' : ''}
								  onClick={() => onClick(item.value)}>{item.label}</MenuItem>
					))}
				</Menu>
			}
		</div>
	);
};

const Categories = () => {
	const {state: {secondaryMenuVisible}} = useMenu();
	const context = useContext(WidgetContext);
	const {contentCategories, contentGroups} = context.widget;
	const resources = useRecoilValue(resourceFinderDataSelector);
	const {categories: categoriesList, groups: groupsList} = resources;

	const [filter, setFilter] = useRecoilState(resourceFinderFilterSelector);

	// current selection of content category/content group id
	const [selectedGroup, setSelectedGroup] = useRecoilState(selectedGroupAtom);

	const [selectedGroupOrCategory, setSelectedGroupOrCategory] = useRecoilState(selectedGroupOrCategoryAtom);

	const setContentCategoryGroupingRule = useSetRecoilState(contentCategoryGroupingRuleAtom);
	const resetContentCategoryGroupingRule = useResetRecoilState(contentCategoryGroupingRuleAtom);

	const setDefaultGrouping = useSetRecoilState(defaultGroupingAtom);
	const resetDefaultGrouping = useResetRecoilState(defaultGroupingAtom);

	const [contentGroupsTags, setContentGroupsTags] = useRecoilState(contentGroupsTagRules);

	const parentRef = useRef<any>();
	const groupRef = useRef<any>();
	const [maxWidth, setMaxWidth] = useState(0);
	const [needsScroll, setNeedsScroll] = useState(false);
	const [scroll, setScroll] = useState([false, false]);

	useEffect(() => {
		let selectedElem = document.querySelector('[data-testid="list-wrapper"] button.selected');
		let coords = selectedElem?.getBoundingClientRect();
		const parentCoords = parentRef.current?.getBoundingClientRect();
		if (selectedElem && coords && (coords.x > maxWidth || coords.x <= parentCoords.x)) {
			selectedElem.scrollIntoView();
			measureWidth();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedGroup]);

	const measureWidth = () => {
		const box = parentRef.current?.getBoundingClientRect();
		if (!box) return;
		const width = box.width - 80;
		if (width !== maxWidth) {
			setMaxWidth(width);
		}

		if (!groupRef.current) return;
		const menu = groupRef.current;

		const requireScroll = menu.clientWidth < menu.scrollWidth;
		setNeedsScroll(requireScroll);

		let needsScrollLeft = false;
		let needsScrollRight = false;

		if (menu.parentElement!.scrollLeft > 0) {
			needsScrollLeft = true;
		}
		if (requireScroll) {
			needsScrollRight = menu.scrollWidth !== width + menu.parentElement!.scrollLeft;
		}
		setScroll([needsScrollLeft, needsScrollRight]);
	};

	useEffect(() => {
		measureWidth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxWidth, groupsList, context, secondaryMenuVisible]);

	useEffect(() => {
		window.addEventListener('resize', measureWidth);
		return () => {
			window.removeEventListener('resize', measureWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onlyCategories = useMemo(() => {
		return contentCategories && !contentGroups;
	}, [contentCategories, contentGroups]);

	useEffect(() => {
		if (!groupRef.current) return;
		measureWidth();

		const ref = groupRef.current;
		ref.parentElement!.addEventListener('scroll', measureWidth);
		return () => {
			ref.parentElement!.removeEventListener('scroll', measureWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupRef, parentRef]);

	const scrollLeft = () => {
		groupRef.current!.parentElement!.scrollTo({left: groupRef.current!.parentElement!.scrollLeft - maxWidth / 2});
		measureWidth();
	};

	const scrollRight = () => {
		groupRef.current!.parentElement!.scrollTo({left: groupRef.current!.parentElement!.scrollLeft + maxWidth / 2});
		measureWidth();
	};

	const categories = useMemo(() => {
		let list: CategoriesModel[] = [];

		if (onlyCategories) {
			list = categoriesList;
		} else {
			categoriesList.forEach((c: ContentCategory, idx: number) => {
				let docs: number = 0;
				if (c.documentCount() === 0) {
					return;
				}

				list[idx] = {
					groupLabel: c.displayLabel(),
					groupId: c.getId(),
					groupList: [],
				};
				c.contentGroups().forEach(group => {
					if (group.documentCount() === 0) {
						return;
					}
					docs += group.documentCount();
					list[idx].groupList.push({
						label: `${group.displayLabel()} (${group.documentCount()})`,
						value: group.getId(),
						object: group,
					});
				});
				if (list[idx].groupList.length > 1) {
					list[idx].groupList.unshift({label: `All (${docs})`, value: `all-${c.getId()}`, object: {}});
				}
			});
		}
		return list;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoriesList, selectedGroup, onlyCategories]);

	useEffect(() => {
		let foundSelected = true;
		if (selectedGroup) {
			if (onlyCategories) {
				foundSelected = categories.findIndex(c => c.groupId === selectedGroup) > -1;
			} else if (contentCategories && contentGroups) {
				foundSelected = categories.findIndex(c => c.groupList.find(g => g.value === selectedGroup)) > -1;
			} else {
				foundSelected = groupsList.findIndex(g => g.getId() === selectedGroup) > -1;
			}
		}
		if (!foundSelected) {
			setFilter((val) => ({...val, contentGroupTypes: [], contentTypes: []}));
			setSelectedGroup('');
			return;
		}

	}, [categories, groupsList, onlyCategories]);

	const onSelectGroup = (value: string) => {
		const parts = value.split('all-');

		if (parts.length === 2) {	// all option from a specific category was selected
			const category = categories.find(c => c.groupId === parts[1]);
			const categoryObj = Objects.default(categoriesList.find(c => c.getId() === parts[1])) as ContentCategory;
			if (!window['groupingChanged']) {
				setDefaultGrouping(categoryObj.defaultGroupingRule());
			}
			setContentCategoryGroupingRule(categoryObj.defaultGroupingRule());
			setSelectedGroupOrCategory(categoryObj);
			if (category) {
				const contentGroups: ContentGroup[] = category.groupList.filter(i => !i.value.includes('all')).map(g => g.object);
				if (contentGroups.length > 0) {
					let contentTypeIds: UUID[] = [];
					let allRules: any[] = [];
					contentGroups.forEach(group => {
						const rules = group.defaultSortingRules();
						if (rules.length) {
							allRules.push( {
								id: group.getId(),
								rules,
							});
						}
						contentTypeIds = [...contentTypeIds, ...group.contentTypes().map(ct => ct.getId())];
					});
					setContentGroupsTags(allRules);
					setSelectedGroup(value);
					setFilter(val => ({...val, contentGroupTypes: contentTypeIds}));
					return;
				}
			}
		}

		if (value === 'all') { // all option was selected - all resources
			setContentGroupsTags([]);
			setSelectedGroup('');
			setFilter((val) => ({...val, contentGroupTypes: []}));
			setSelectedGroupOrCategory(null);
			resetDefaultGrouping();
			resetContentCategoryGroupingRule();
			return;
		}

		// if selected value is a category
		if (onlyCategories) {
			let categoryObj = Objects.default(categoriesList.find(c => c.getId() === value)) as ContentCategory;
			setSelectedGroup(value);
			setSelectedGroupOrCategory(categoryObj);
			if (!window['groupingChanged']) {
				setDefaultGrouping(categoryObj.defaultGroupingRule());
			}
			setContentCategoryGroupingRule(categoryObj.defaultGroupingRule());
			const contentGroupIds = categoriesList.find(c => c.getId() === value).contentGroups().map(g => g.getId());
			let contentTypeIds: UUID[] = [];
			contentGroupIds.forEach(id => {
				const contentGroup = getRecoil(references(id)) as ContentGroup;
				const rules = contentGroup ? contentGroup.defaultSortingRules() : [];
				if (rules.length) {
					setContentGroupsTags([ {
						id: contentGroup.getId(),
						rules,
					}]);
				}
				contentTypeIds = [...contentTypeIds, ...contentGroup.contentTypes().map(ct => ct.getId())];
			});
			setFilter((val) => ({
				...val,
				contentGroupTypes: contentTypeIds,
				contentTypes: Lists.default<ISelectValue>(val.contentTypes).filter(ct => contentTypeIds.includes(ct.value)),
			}));
			return;
		}

		// if selected value is a content group
		const contentGroup = getRecoil(references(value)) as ContentGroup;
		// check if the selected group is the only one in a content category
		let category = Objects.default(categories.find(c => c.groupList.find(g => g.value === contentGroup.getId())));
		if (category.groupList.length === 1) {
			let categoryObj = Objects.default(categoriesList.find(c => c.getId() === category.groupId)) as ContentCategory;
			setSelectedGroupOrCategory(categoryObj);
			if (!window['groupingChanged']) {
				setDefaultGrouping(categoryObj.defaultGroupingRule());
			}
			setContentCategoryGroupingRule(categoryObj.defaultGroupingRule());
		} else {
			setSelectedGroupOrCategory(contentGroup);
			resetDefaultGrouping();
		}

		const rules = contentGroup ? contentGroup.defaultSortingRules() : [];
		if (rules.length) {
			setContentGroupsTags([...contentGroupsTags, {
				id: contentGroup.getId(),
				rules,
			}]);
		}
		setSelectedGroup(contentGroup.getId());
		const contentTypeIds = contentGroup.contentTypes().map(ct => ct.getId());
		setFilter((val) => ({
			...val,
			contentGroupTypes: contentTypeIds,
			contentTypes: Lists.default<ISelectValue>(val.contentTypes).filter(ct => contentTypeIds.includes(ct.value)),
		}));
	};

	const renderCategories = useMemo(() => {
		if (!contentCategories && !contentGroups) {
			return null;
		}
		if (contentCategories && contentGroups) {
			// list categories as dropdown with content groups and All option
			return (
				<>
					<div><Button style={{width: 'max-content'}} onClick={() => onSelectGroup('all')} disableRipple
								 className={selectedGroup === '' ? 'selected' : ''}>All resources</Button></div>
					{categories.map(category => (
						<ItemList key={category.groupId} category={category}
								  onClickCb={(value) => onSelectGroup(value)}/>
					))}
				</>);
		} else {
			let list: any[];
			if (!contentCategories && contentGroups) {
				list = groupsList;
			} else {
				list = categories;
			}
			return (
				<>
					<div>
						<Button style={{width: 'max-content'}} onClick={() => onSelectGroup('all')} disableRipple
								 className={selectedGroup === '' ? 'selected' : ''}>All resources</Button>
					</div>
					{list.map((group, idx) => (
						<div key={idx} data-testid={group.getId()}>
							<Button className={`group ${group.getId() === selectedGroup ? 'selected' : ''}`}
									onClick={() => onSelectGroup(group.getId())} disableRipple
							>
								<span
									title={`${group.displayLabel()} (${group.documentCount()})`}>{`${group.displayLabel()} (${group.documentCount()})`}
								</span>
							</Button>
						</div>
					))
					}
				</>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentCategories, contentGroups, categories, selectedGroup, maxWidth, needsScroll, scroll]);

	if (!contentCategories && !contentGroups) return null;

	return (
		<Wrapper id="categoryId" ref={parentRef}>
			{(needsScroll && scroll[0]) && (
				<>
					<LeftArrow onClick={scrollLeft} data-testid="left-arrow">
						<KeyboardArrowLeftIcon/>
					</LeftArrow>
					<LeftGradient/>
				</>
			)}
			<ScrollWrapper
				style={{overflowX: 'auto', display: 'flow-root', width: maxWidth, scrollbarWidth: 'none'}}
				scroll={scroll[0]}
				id="parent"
				data-testid="parent">
				{maxWidth > 0 && <ListWrapper data-testid="list-wrapper" ref={groupRef}>
					{renderCategories}
				</ListWrapper>
				}
			</ScrollWrapper>
			{(needsScroll && scroll[1]) && (
				<>
					<RightGradient/>
					<RightArrow onClick={scrollRight} data-testid="right-arrow">
						<KeyboardArrowRightIcon/>
					</RightArrow>
				</>
			)}
		</Wrapper>
	);
};

export default Categories;