import styled from '@emotion/styled';
import React, {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {enableSubjectFilter, productTreeSelector, subjectSelector, UnitSelector} from '../state/product';
import {fi} from '../utils/helpers';

const Wrapper = styled.div<any>`
    margin: 16px 16px 0 16px;
    padding: 24px;
    border: 1px var(--color-border-light);
    border-style: solid solid none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: var(--color-white);
    position: sticky;
    align-self: start;
    //top: -1px;
    top: 69px;
    z-index: 2;

    &.subject-filter-disabled {
        top: calc(134px - 68px)
    }

    //&.is-pinned {
    //    padding-top: 148px;
    //    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .04);
    //}
`;
const HeaderElem = styled.h1`
    font-family: var(--font-bolder);
    font-size: 24px;
    margin-bottom: 8px;
`;
const SubheaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const QualificationElem = styled.div`
    font-family: var(--font-bolder);
    font-size: 14px;
    color: var(--color-grey-text);
    margin-right: 40px;
`;
const UnitsElem = styled.div`
    font-family: var(--font-bolder);
    font-size: 14px;
    color: var(--color-grey-text)
`;
const SubjectTitle = () => {
	const selectedSubject = useRecoilValue(subjectSelector);
	const selectedUnits = useRecoilValue(UnitSelector);
	const productTree = useRecoilValue(productTreeSelector);
	const subjectFilterEnabled = useRecoilValue(enableSubjectFilter);

	const labels = useMemo(() => {
		return selectedUnits.map(u => u.label);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUnits, selectedSubject]);

	const units = useMemo(() => {
		if (!selectedUnits || (!selectedSubject?.isModular() && !selectedSubject?.isTechnicals())) {
			return '';
		}
		if (labels.length === 1 && labels[0].toLowerCase() === 'all units') {
			return labels[0].split(' ')[0];
		}

		if (labels.length > 3) {
			return `${labels.slice(0, 3).join(', ')}  +${labels.length - 3} more`;
		}
		return labels.join(', ');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [labels]);

	const qualification = useMemo(() => {
		if (!productTree) return '';
		return productTree.find(q => q.getId() === selectedSubject?.qualificationMappingID)?.displayLabel();
	}, [productTree, selectedSubject]);

	// const el = document.querySelector("[data-testid='subject-title-wrapper']");
	// const observer = new IntersectionObserver(
	// 	([e]) =>{
	// 		e.target.classList.toggle('is-pinned', e.intersectionRatio < 1);
	// 	},
	// 	{threshold: [1]},
	// );
	//
	// if (el) observer.observe(el!);

	if (!selectedSubject) return null;

	return (
		<Wrapper data-testid="subject-title-wrapper"
				 className={!subjectFilterEnabled ? `subject-filter-disabled` : ''}>
			<HeaderElem data-testid="subject-title">{selectedSubject.displayLabel()}</HeaderElem>
			<SubheaderWrapper>
				<QualificationElem>{qualification}</QualificationElem>
				{fi(selectedSubject.isModular() || selectedSubject.isTechnicals(),
					<UnitsElem data-testid="units" title={labels.length > 3 ? labels : ''}>Units: {units}</UnitsElem>)}
			</SubheaderWrapper>
		</Wrapper>
	);
};

export default SubjectTitle;