import {ITreeItem} from './utils';
import React, {useEffect, useMemo, useRef} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {fi, handleAction} from '../../utils/helpers';
import {sessionAtom} from '../../state/session';
import {Messages} from '../../utils/messages';
import {useMenu} from '../../state/menu';
import {mobileMenuState} from '../../layout/Menu';
import {useMediaQuery} from '@mui/material';
import {device} from '../../utils/constants';
import {WidgetType} from '../../tw/types';

const Row = styled('div')<{depth: number}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    width: 100%;
    padding: 0 8px;
    cursor: pointer;
    user-select: none;
    color: var(--color-menu-grey);
    border-radius: 6px;

    &:hover {
        background-color: var(--color-hover);
    }

    &.disabled {
        opacity: 0.38
    }

    &.selected {
        color: var(--color-blue);
        font-family: var(--font-semi-bold);

        img {
            filter: invert(45%) sepia(41%) saturate(1377%) hue-rotate(183deg) brightness(86%) contrast(89%)
        }

        p {
            text-decoration: underline;
        }
    }

    &.parent.selected {
        background-color: var(--color-selected);
    }

    &.opened {
        background-color: var(--color-box-shadow);
    }
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    padding: 0 2px;
`;

const Label = styled.div`
    padding: 0 2px;
    height: 26px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        line-height: 32px;
    }
`;

const Icon = styled.div`
    width: 19px;
    min-width: 19px;
    height: 26px;
    padding: 0 2px;
    border: 0;

    svg {
        width: 16px;
        height: 26px;
        border: 0;
    }
`;

const PageIcon = styled.div`
    svg {
        width: 24px;
        height: 24px;
    }
`;

const TreeItemChild = styled('div')<{depth: number}>`
    ${props => props.depth > 1 && `
        border-left: solid 1px var(--color-border);
	`}
`;
type TreeItemProps = {
	item: ITreeItem
	depth: number;
}
const TreeItem = ({item, depth}: TreeItemProps) => {
	const depthWidth = 8;
	const {toggleMenu, setPage} = useMenu();
	const user = useRecoilValue(sessionAtom);
	const setMobileState = useSetRecoilState(mobileMenuState);
	const mobileOrTablet = useMediaQuery(device.tablet);
	const ref: any = useRef();

	const shouldBeDisabled = useMemo(() => {
		//disable resource finder page in preview mode
		if (!user) return false;
		return item.object.config.find(c => c.type === WidgetType.ResourceFinder) && user.preview;
	}, [item, user]);


	const toggleSelected = () => {
		if (shouldBeDisabled) return;
		toggleMenu(item);
		setPage(item);

		// check if the page is link type and open it in a new tab
		if (item.object.isLinkPage()) {
			window.open(item.object.link, '_blank');
			return;
		}

		if (mobileOrTablet) {
			setMobileState(false);
		}
	};

	const hasChildren = item.children.length > 0;

	useEffect(() => {
		if (item.selected && ref?.current) {
			ref.current.scrollIntoViewIfNeeded && ref.current.scrollIntoViewIfNeeded();
		}
	}, [ref, item]);

	return (
		<div data-testid="tree-item-wrapper">
			<Row data-testid="tree-item--row"
				 {...handleAction(toggleSelected)}
				 data-itemid={item.object.getId()}
				 className={`${fi(item.selected, 'selected', '')} 
				             ${fi(shouldBeDisabled, 'disabled', '')}
				             ${fi(depth === 0, 'parent', '')}
				             ${fi(item.opened && depth === 0, 'opened', '')}`}
				 depth={depth}
				 ref={ref}
			>
				<LabelWrapper>
					{depth === 0 &&
						<PageIcon data-testid="tree-item--page-icon">
							{item.object.getPageIcon()}
						</PageIcon>
					}
					<Label data-testid="tree-item--label"
						   title={`${fi(shouldBeDisabled, Messages.FeatureNotAvailablePreview, item.object.displayLabel())}`}
						   tabIndex={0}>
						<p>{item.object.displayLabel()}</p>
					</Label>
					<Icon data-testid="tree-item--arrow-icon"
						  title={fi(hasChildren, fi(item.opened, 'Close', 'Open'), '')}
						  tabIndex={fi(hasChildren, 0, undefined)} {...handleAction(() => toggleMenu(item))}>
						{fi(hasChildren, fi(item.opened, <ExpandMoreIcon data-testid="collapse-icon"/>,
							<KeyboardArrowRightIcon data-testid="expand-icon"/>), <>&nbsp;</>)}
					</Icon>
				</LabelWrapper>
			</Row>
			{(item.opened && item.children.length > 0) && (
				<TreeItemChild data-depth={depth + 1} data-testid="tree-item-child" data-parentid={item.object.getId()}
							   depth={depth + 1} style={{marginLeft: depth * depthWidth}}
				>
					{item.children.map((child, idx) => (
						<TreeItem key={`${child.id}-childPage`} item={child} depth={depth + 2}/>
					))}
				</TreeItemChild>
			)}
		</div>
	);
};
export default TreeItem;