import React, {useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import Group from '../../../../Card/Group';
import styled from '@emotion/styled';
import NoContent from '../../../../commons/NoContent';
import {fi} from '../../../../../utils/helpers';
import {Messages} from '../../../../../utils/messages';
import {DocumentsWrapper} from '../../../../../tw/models/DocumentsWrapper';
import {groupAndSortResults, groupingAtom} from './utils';
import {HBox} from '../../../commons/HBox';
import Pagination from './Pagination';
import {contentGroupsTagRules, searchResultsSelector} from '../../state';

const Wrapper = styled.div`
    padding-top: 24px;

    h3 {
        font-size: 24px;
        font-family: var(--font-semi-bold);
        padding-bottom: 16px;
    }
`;


const Grid = () => {
	const searchResults = useRecoilValue(searchResultsSelector);
	const grouping = useRecoilValue(groupingAtom);
	const [items, setItems] = useState<DocumentsWrapper | null>(null);
	const contentGroupTags = useRecoilValue(contentGroupsTagRules);

	const groups = useMemo(() => {
		return groupAndSortResults(searchResults.items, grouping, contentGroupTags);
	}, [grouping, searchResults, contentGroupTags]);

	if (searchResults.items.length === 0) {
		return (
			<HBox justifyContent="center" padding={24}>
				<NoContent label={fi(!searchResults.showNoResults, Messages.NoSearchResults)}/>
			</HBox>
		);
	}

	return (
		<Wrapper>
			{groups?.map((g, i) => (
				<div key={`groups-${g.title}`} className="mb-32 mt-8">
					<p className="text-semiBold text-18 mb-8">{g.title}</p>
					<Group items={fi(grouping === "none", items, g.items)} hideActions={true} selectState={true}/>
				</div>
			))}
			<Pagination setItems={(evt) => setItems(evt)} allItems={groups?.length === 1 ? groups[0].items : searchResults.items}/>
		</Wrapper>);
};

export default Grid;
