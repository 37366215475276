export enum HomepageMessages {
    NoFavourites = "You haven't got any favourites yet for",
    NoKeyDocuments = "There are no key documents for",
    NoRecentlyUpdated = "There are no new or updated resources for",
    OnlineCourseWidgetLabel = "Online courses",
    SubjectUpdatesWidgetLabel = "Subject updates",
    AnnouncementsWidgetLabel = "Announcements",
    NoOnlineCourses = "There are no online courses for",
    NoSubjectUpdates = "There are no subject updates for",
    NoGlobalAnnouncements = "There are no global announcements. ",
    NewAndUpdatedPageTitle = "New or updated resources",
    FavouritesPageTitle = "My favourites",
    KeyDocumentsPageTitle = "Key documents",
    EventsWidgetLabel = "Upcoming events",
    NoEvents = "There are no planned events for"
}