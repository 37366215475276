import {KeyDate} from '../tw/models/KeyDate';
import {atom, selector} from 'recoil';
import Client from '../tw/client';
import {Sort, Types} from '../tw/types';
import {TimeTable} from '../tw/models/TimeTable';
import {Lists} from '../utils/lists';
import {subjectSelector} from './product';
import {Strings} from '../utils/strings';
import {Dates} from '../utils/dates';

export type ExamDateType = KeyDate & TimeTable;

export type ExamTypeFilter = {
	dateRange?: string[],
	type?: string,
	series?: string,
	sort: Sort,
}

export const examDateFilter = atom<ExamTypeFilter>({
	key: 'examDateFilter',
	default: {
		dateRange: [Strings.default(Dates.getMidnight(true)), Strings.default(new Date(new Date().setFullYear((new Date().getFullYear() + 1))))],
		sort: 'asc',
	},
});

export const examDateSelector = selector<ExamDateType[]>({
		key: 'examDateSelector',
		get: async ({get}) => {
			const filter = get(examDateFilter);
			const list = get(examDateLoader);
			const subject = get(subjectSelector);
			if (!subject) {
				return list;
			}

			const bySubject = list.filter((l: ExamDateType) => l.matchesSubject(subject));

			return Lists.sort(bySubject.filter(date => {
				if (!filter.type) return true;
				return filter.type === date.type;
			}).filter(date => {
				if (!filter.series) return true;
				return filter.series === date.getSeriesYear();
			}).filter(date => {
				if (!filter.dateRange) return true;
				const [start, end] = filter.dateRange;
				const from = new Date(start);
				const to = new Date(end);
				return date.date >= from && date.date <= to;
			}), 'date', filter.sort === 'desc');
		},
	},
);

export const examDateLoader = selector<ExamDateType[]>({
	key: 'examDateLoader',
	get: async () => {
		const res = await Client.query<ExamDateType>({
			types: [Types.KEY_DATE, Types.TIMETABLE],
		});
		return res.results;
	},
});