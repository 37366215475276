import React, {Suspense} from 'react';
import BlackHeader from './BlackHeader';
import styled from '@emotion/styled';
import Footer from './Footer';
import Preview from '../components/Preview/Preview';
import {scrollTopPosition} from '../state/state';
import {useSetRecoilState} from 'recoil';
import OverlayComponents from './OverlayComponents';
import MainContent from './MainContent';
import FilterMenu from '../components/TreeMenu/FilterMenu';
import Header from './Header';

const AppLayoutElem = styled.div`
    display: grid;
    grid-template-areas:
    'blackHeader'
	'header'
	'mainContent'
    'footer';
    height: auto;
    min-height: 100vh;
    //overflow-y: auto;
    position: relative;
    grid-template-rows: 44px 70px 1fr 48px;

    &.no-scroll {
        overflow: hidden;
    }

    &.block-scroll {
        position: fixed;
    }
`;
const AppLayout = ({children}: any) => {
	const setTopPosition = useSetRecoilState(scrollTopPosition);

	window.addEventListener('scroll', () => {
		if (document.documentElement.scrollTop > 44) {
			setTopPosition(true);
		} else {
			setTopPosition(false);
		}
	});

	return (
		<AppLayoutElem data-testid="app-layout" id="app-layout">
			<Preview/>
			<BlackHeader/>
			<Header/>
			<Suspense fallback={null}><FilterMenu/></Suspense>
			<MainContent>
				{children}
			</MainContent>
			<Footer/>
			<OverlayComponents/>
		</AppLayoutElem>
	);
};

export default AppLayout;