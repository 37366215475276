import React, { useEffect, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ConfirmationModalProps, modalAtom } from './ModalDialog';
import { sessionAtom, sessionSelector } from '../../state/session';
import Client from '../../tw/client';
import { setRecoil } from '../../state/recoilNexus';
import GA from '../../tw/models/GA';
import { Browser } from '../../utils/browser';
import OrganizationModalContent from './components/OrganizationModalContent';
import { Strings } from '../../utils/strings';
import { fi } from '../../utils/helpers';


export const organizationChangeAtom = atom<boolean>({
	key: 'organizationChangeAtom',
	default: false,
});

export const organizationChangePathAtom = atom<string>({
	key: 'organizationChangePathAtom',
	default: '',
});

export const selectedOrgAtom = atom<string>({
	key: 'selectedOrgAtom',
	default: '',
});

const ChangeOrgModal = () => {
	const setValue = useSetRecoilState(modalAtom);
	const userSession = useRecoilValue(sessionAtom);
	const [selectedOrg, setSelectedOrg] = useRecoilState(selectedOrgAtom);
	const [showOrgModal, setShowOrgModal] = useRecoilState(organizationChangeAtom);
	const organizationPath = useRecoilValue(organizationChangePathAtom);

	useEffect(() => {
		if (!userSession) return;
		setSelectedOrg(`${userSession.lastSelectedOrg}~${userSession.selectedBusinessStream}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession]);

	const hideModal = () => {
		setShowOrgModal(false);
		setValue(null);
	};
	const cancelModal = () => {
		if (!userSession) return;
		if (userSession.isFirstTimeUser() && !userSession.lastSelectedOrg) {
			return;
		}
		setSelectedOrg('');
		hideModal();
	};

	const disableConfirmAction = useMemo(() => {
		return !selectedOrg || selectedOrg === '~';
	}, [selectedOrg]);

	useEffect(() => {
		if (!userSession || !showOrgModal) return;
		const modalObject: ConfirmationModalProps = {
			title: `Select your centre`,
			message: <OrganizationModalContent user={userSession}/>,
			onConfirm: async () => {
				if (!selectedOrg) return;
				await Client.switchOrganization(selectedOrg).then((res) => {
					let userClone = userSession.clone({
						lastSelectedOrg: res.lastSelectedOrg,
						selectedBusinessStream: res.selectedBusinessStream,
						preferences: res.preferences,
						domain: res.domain,
					});
					setRecoil(sessionSelector, userClone);
					if (res.domain === window.location.origin || res.domain.includes(window.location.origin)) {
						Browser.navigate('/');
					} else {
						const switchToken = Strings.default(res['switch_token']);
						const token = fi(switchToken, `?token=${switchToken}`, '');
						window.location.href = `${res.domain}${token}`;
					}
					hideModal();
				}).finally(() => {
					if (organizationPath) {
						GA.ChangeOrganizationEvent(organizationPath);
					}
				});
			},
			onCancel: () => cancelModal(),
			confirmText: 'Confirm',
			hasCancel: !userSession.isFirstTimeUser(),
			disableAction: disableConfirmAction,
		};
		setValue(modalObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showOrgModal, selectedOrg]);

	if (!userSession) return null;

	return (
		<></>
	);
};

export default ChangeOrgModal;