import { atom, selector, selectorFamily } from 'recoil';
import { documentSelector } from '../../../state/documents';
import { DocumentsWrapper } from '../../../tw/models/DocumentsWrapper';
import { Link } from '../../../tw/models/Link';
import Client from '../../../tw/client';
import { Types } from '../../../tw/types';
import { sessionAtom } from '../../../state/session';
import { CMSObject } from '../../../tw/models/__CMSObject';
import { ContentGroup } from '../../../tw/models/ContentGroup';
import { ContentCategory } from '../../../tw/models/ContentCategory';
import { resourceFinderFilterSelector } from './state';

// This selector filters down documents for the selected subject and omits online courses and unsearchable items,
// and we'll filter hit results against this precached list of documents
export const filteredDocumentsSelector = selector<DocumentsWrapper>({
	key: 'filteredDocumentsSelector',
	get: async ({get}) => {
		const documents = get(documentSelector);
		return documents.filter((item) => {
			return item.isSearchable() || (item instanceof Link && !item.isOnlineCourse);
		});
	},
});

export const favoriteDocumentsSelector = selector<DocumentsWrapper>({
	key: 'favoriteDocumentsSelector',
	get: async ({get}) => {
		const documents = get(filteredDocumentsSelector);
		return documents.filter((item) => {
			return item.isFavorite();
		});
	},
});

export const hasFavoritesSelector = selector<boolean>({
	key: 'hasFavoritesSelector',
	get: ({get}) => {
		let docs = get(favoriteDocumentsSelector);
		const filter = get(resourceFinderFilterSelector);

		if (filter.contentGroupTypes.length) {
			docs = docs.byContentType(...filter.contentGroupTypes);
		}
		if (filter.contentTypes.length > 0) {
			docs = docs.byContentType(...filter.contentTypes.map(c => c.value));
		}
		if (filter.series.length) {
			docs = docs.bySeries(...filter.series.map(s => s.value));
		}
		if (filter.years.length) {
			docs = docs.byYears(...filter.years.map(y => y.value));
		}
		return docs.length > 0;
	},
});

export const contentLoader = selectorFamily<CMSObject[], Types>({
	key: 'contentLoader',
	get: (type: Types) => async ({get}) => {
		const userSession = get(sessionAtom);
		if (!userSession) {
			return [];
		}
		let result: CMSObject[] = [];
		try {
			const resp = await Client.query<CMSObject>({types: [type]});
			if (resp && resp.results) {
				result = resp.results;
			}
		} catch (e) {
		}

		return result;
	},
});


// Make sure all relevant data for resource finder is loaded before we can use it
export const resourceFinderDataSelector = selector<any>({
	key: 'resourceFinderDataSelector',
	get: async ({get}) => {
		const docs = get(filteredDocumentsSelector);
		const groups = await get(contentLoader(Types.CONTENT_GROUP)) as ContentGroup[];
		const categories = await get(contentLoader(Types.CONTENT_CATEGORY)) as ContentCategory[];

		const catCount: number[] = categories.map(c => c.documentCount());
		const groupCount: number[] = groups.map(g => g.documentCount());

		return {
			docs,
			groups: groups.filter((g: ContentGroup, idx: number) => groupCount[idx] > 0),
			categories: categories.filter((c: ContentCategory, idx: number) => catCount[idx] > 0),
		};
	},
});

export const invalidateFilterAtom = atom<number>({
	key: 'invalidateFilterAtom',
	default: 0,
});