import styled from '@emotion/styled'
import TablePagination from '@mui/material/TablePagination'
import React from 'react'
import { paginationAtom } from '../state'
import { useRecoilState } from 'recoil'
import { useMediaQuery } from '@mui/material'

const Wrapper = styled.div`
  height: 58px;
  padding-right: 16px;
`

const Table = styled.table`
  width: 100%;
  margin-top: 8px;

  td.MuiTablePagination-root {
    border-bottom: none;
  }

  .MuiTablePagination-displayedRows {
    flex: 1;
    text-align: right;
  }

  .MuiTablePagination-spacer {
    display: none;
  }

  @media (max-width: 560px) {
    .MuiTablePagination-displayedRows {
      display: none;
    }
  }
`

const TableFooter = ({ itemsCount }) => {
  const [pagination, setPagination] = useRecoilState(paginationAtom)
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination({ ...pagination, currentPage: newPage })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination({
      ...pagination,
      currentPage: 0,
      itemsPerPage: parseInt(event.target.value, 10)
    })
  }

  return (
    <Wrapper>
      <Table>
        <tbody>
          <tr>
            <TablePagination
              showFirstButton={true}
              showLastButton={true}
              rowsPerPageOptions={[20, 40, 60, 80, 100]}
              count={itemsCount}
              page={pagination.currentPage}
              rowsPerPage={pagination.itemsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
              labelRowsPerPage={isMobile ? 'Rows' : 'Rows per page'}
            />
          </tr>
        </tbody>
      </Table>
    </Wrapper>
  )
}

export default TableFooter
