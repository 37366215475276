import styled from '@emotion/styled';
import React, {useState} from 'react';
import {
	AccountBoxOutlined,
	ArrowForward,
	ContactPhoneOutlined,
	LinkOutlined,
	SchoolOutlined,
} from '@mui/icons-material';
import {fi} from '../utils/helpers';
import {device, ROUTE_SIGNUP_TRIAL_ACCESS, TRIAL_ACCESS_FLAG} from '../utils/constants';
import LandingPageImage from '../assets/images/landing.png';
import MobileLandingImage from '../assets/images/landing2.png';
import {ReactComponent as OCRLogo} from '../assets/images/OCR-Logo.svg';
import {ReactComponent as CambridgeLogo} from '../assets/images/cambridge-logo.svg';
import {useRecoilValue} from 'recoil';
import {featureFlagsSelector} from '../state/state';
import Footer from '../layout/Footer';
import {useMediaQuery} from '@mui/material';

const LandingPageWrapperElem = styled.div`
    background: var(--color-white) 0 0 no-repeat padding-box;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
    user-select: none;

    @media ${device.mobile} {
        background: var(--color-background) 0 0 no-repeat padding-box;
    }
`;
const LandingElem = styled.div`
    display: table;
    height: 100%;
    width: 100%;
    padding: 24px 0;
    overflow-y: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-items: center;

    @media ${device.mobile} {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
    }
`;
const TableCellElem = styled.div`
    display: table-cell;
    width: 50%;
    vertical-align: middle;
	
    @media ${device.mobile} {
        width: 100%;
        padding: 16px;

        &:nth-of-type(2) {
            padding: 0;
        }
    }
`;
const LeftWrapperElem = styled.div`
    border: 1px solid var(--color-grey);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 24px;
    width: 50%;
    padding: 16px;

    #cambridge {
        flex: 1;
    }

    #ocr {
        width: 110px;
    }

    @media ${device.tablet} {
        width: 70%;
    }
	
    @media ${device.mobile} {
        width: 100%;
        padding: 16px;
        margin-right: 0;
        background-color: var(--color-white);
    }
`;
const BoxWrapperElem = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
`;
const BoxElem = styled('div')<{ withButton: boolean }>`
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    box-shadow: 0 4px 6px var(--color-box-shadow);
    padding: 16px;
    border-radius: 6px;

    ${props => props.withButton &&
            `cursor: pointer;
            display: flex;
            flex-direction: row;
            div:first-of-type{
                flex:1;
            };
            div:last-of-type{
                display:flex;
                align-items:center;
                
            };
            &:hover{
                border: 1px solid var(--color-blue);
            }
    `}
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }

    p {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    .link-copied {
        margin-left: 16px;
    }

    .examsOfficer-link {
        display: inline-flex;
        flex-wrap: wrap;
    }

    @media ${device.tablet} {
        p {
            flex-wrap: wrap;
        }
    }
`;
const RightWrapperElem = styled.div`
    width: 70%;
    margin-left: 24px;

    p:nth-of-type(2) {
        color: var(--color-monochrome);
    }

    img {
        width: 100%;
    }

    @media ${device.mobile} {
        width: 100%;
        margin-left: 0;

        p {
            text-align: center;
            padding: 12px 16px;
        }

        p:first-of-type {
            font-size: 24px !important;
            line-height: 28px !important;
        }
    }
`;

const LinkElem = styled.span`
    color: var(--color-blue);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 0;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }
`;
const LandingPage = () => {
	const featureFlags = useRecoilValue(featureFlagsSelector);
	const [copied, setCopied] = useState<boolean>(false);
	const mobile = useMediaQuery(device.mobile);

	const redirectToLogin = () => {
		const url = localStorage.getItem('login_redirect');
		if (url) {
			window.open(url, '_self');
			localStorage.removeItem('login_redirect');
		}
	};

	const copyToClipboard = () => {
		navigator.clipboard.writeText('https://www.ocr.org.uk/administration/support-and-tools/my-cambridge/');
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	const getTrialAccessLink = () => {
		return window.location.origin + ROUTE_SIGNUP_TRIAL_ACCESS;
	};
	const trialAccess = () => {
		let url = getTrialAccessLink();
		window.open(url, '_self');
	};

	return <LandingPageWrapperElem>
		<LandingElem>
			<TableCellElem>
				<LeftWrapperElem data-testid="left-wrapped-elem">
					<div className="flex-row-center">
						<div id="cambridge"><CambridgeLogo/></div>
						<div id="ocr"><OCRLogo/></div>
					</div>
					<BoxWrapperElem>
						<p className="text-20 text-latoRegular mb-16"> Let's start: </p>
						<BoxElem onClick={redirectToLogin} withButton={true}>
							<div>
								<p><AccountBoxOutlined color="primary"/></p>
								<p className="text-16 text-latoBold">Already have an account?</p>
                                <p><LinkElem onClick={redirectToLogin} data-testid="redirect-to-login-link">Sign in to get started</LinkElem></p>
							</div>
							<div>
								<ArrowForward color="secondary"/>
							</div>
						</BoxElem>
						<BoxElem withButton={false}>
							<p><ContactPhoneOutlined color="primary"/></p>
							<p className="text-16 text-latoBold"> Don't have access yet?</p>
                            <p className="examsOfficer-link">Send this<LinkElem onClick={copyToClipboard} data-testid="no-access-link">
								<LinkOutlined/>&nbsp;link&nbsp;</LinkElem>to your Exams Officer for help
								<span className="text-16 text-semiBold link-copied">
                                    {fi(copied, 'Link copied!', '')}
                                </span>
							</p>
						</BoxElem>
						{fi(featureFlags.includes(TRIAL_ACCESS_FLAG),
							<BoxElem onClick={trialAccess}
									 data-testid="trial-access-btn"
									 withButton={true}>
								<div>
									<p><SchoolOutlined color="primary"/></p>
									<p className="text-16 text-latoBold">Thinking about teaching an OCR
										qualification?</p>
									<p id="copy-link"><LinkElem onClick={trialAccess} data-testid="trial-access-link">Sign
										up for trial access</LinkElem></p>
								</div>
								<div><ArrowForward color="secondary"/></div>
							</BoxElem>,
						)}
					</BoxWrapperElem>
				</LeftWrapperElem>
			</TableCellElem>
			<TableCellElem>
				<RightWrapperElem data-testid="right-wrapped-elem">
					{fi(mobile, <img src={MobileLandingImage} alt="Welcome to Teach Cambridge"/>)}
					<p className="text-32 text-latoBold mb-24"> Welcome to Teach Cambridge</p>
					<p className="text-16 text-regular">
                        A personalised and secure teacher website 
                        providing access to information, support and resources 
                        for the subjects you teach.
					</p>
					{fi(!mobile, <div className="pt-48">
						<img src={LandingPageImage} alt="Welcome to Teach Cambridge"/>
					</div>)}
				</RightWrapperElem>
			</TableCellElem>
		</LandingElem>
		<Footer/>
	</LandingPageWrapperElem>;
};

export default LandingPage;