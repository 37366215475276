import {Radio} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const IconElem = styled.span`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border: solid 1px;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
  &root.Mui-focusVisible{
    outline: 2px auto rgab(19, 124, 189, .6);
    outline-offset: 2px;
  }
  input:hover ~ & {
    background-color: var(--color-grey);
  }
  input:disabled ~ & {
    box-shadow: none;
    background-color: var(--color-monochrome);
  }
  
`
const CheckedIconElem = styled(IconElem)`
  border: unset;
  background-color: var(--color-blue);
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, 0));

  &:before {
    display: block;
    width: 20px;
    height: 20px;
    background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
    content: "";
  }

  input:hover ~ & {
    background-color: var(--color-blue);
  }
`

const RadioButton = (props) => {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CheckedIconElem/>}
            icon={<IconElem/>}
            {...props}
        />
    )
}

export default RadioButton;